import { schema } from 'normalizr';

export const media = new schema.Entity('media', {}, { idAttribute: 'UUID' });

export const suburb = new schema.Entity('suburbs', {}, { idAttribute: 'ID' });

export const notification = new schema.Entity(
	'notifications',
	{
		media: media,
	},
	{ idAttribute: 'UUID' }
);

export const escort = new schema.Entity(
	'escorts',
	{
		galleries: [
			{
				media: [media],
				thumbnail: media
			}
		],
		banner: media,
		avatar: media
	},
	{ idAttribute: 'handle' }
);

export const user = new schema.Entity(
	'users',
	{
		avatar: media,
		escort: escort,
	},
	{ idAttribute: 'UUID' }
);

export const listing = new schema.Entity(
	'listings',
	{
		user: user,
		media: [media]
	},
	{ idAttribute: 'UUID' }
);

export const authUser = new schema.Entity(
	'users',
	{
		avatar: media,
		escort: escort,
		listings: [listing]
	},
	{ idAttribute: 'UUID' }
);

export const authListing = new schema.Entity(
	'listings',
	{
		user: authUser,
		media: [media]
	},
	{ idAttribute: 'UUID' }
);
