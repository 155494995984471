import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { ClubAPIAuthResponse } from './api-response.service';
import {
	LogService,
	ErrorService,
	CustomHttpService
} from 'src/app/shared/services';

export interface ForgotPasswordContext {
	email?: string;
}
@Injectable({
	providedIn: 'root'
})
export class UserForgotPasswordService {
	// API environment variables
	private ENV = `${environment.apiUrl}/${environment.apiVersion}`;
	// API Request information
	private API_END_POINT = 'forgot-password'; // * Modify to change API path

	private URL = `${this.ENV}/${this.API_END_POINT}`;
	private SERVICE_NAME = `API (${this.API_END_POINT})`;

	constructor(
		private http: CustomHttpService,
		private logService: LogService,
		private errorService: ErrorService
	) {}

	private log(message: string) {
		this.logService.add(`${this.SERVICE_NAME}: ${message}`, 'success');
	}

	/**
	 * Request user password reset
	 *
	 * @param userForgotPasswordContext Reset Email Context
	 */
	requestReset(userForgotPasswordContext: ForgotPasswordContext): Observable<any> {
		return this.http
			.post<HttpResponse<ClubAPIAuthResponse>>(
				this.URL,
				userForgotPasswordContext
			)
			.pipe(
				tap((_) => this.log(`Request Password Reset Successfully.`)),
				catchError((res: HttpErrorResponse) =>
					this.errorService.handleAPIHttpError(
						res,
						this.SERVICE_NAME,
						'requestReset'
					)
				)
			);
	}
}
