import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromUsers from './users.reducer';
import * as fromMedia from './media.reducer';
import * as fromListings from './listings.reducer';
import * as fromEscorts from './escorts.reducer';
import * as fromSuburbs from './suburbs.reducer';
import * as fromEscortTags from './escort-tags.reducer';
import * as fromReports from './reports.reducer';

export interface FeaturesState {
	users: fromUsers.UserState;
	media: fromMedia.MediaState;
	listings: fromListings.ListingState;
	escorts: fromEscorts.EscortState;
	suburbs: fromSuburbs.SuburbState;
	escortTags: fromEscortTags.EscortTagsState;
	reports: fromReports.ReportsState;
}

export const reducers: ActionReducerMap<FeaturesState> = {
	users: fromUsers.reducer,
	media: fromMedia.reducer,
	listings: fromListings.reducer,
	escorts: fromEscorts.reducer,
	suburbs: fromSuburbs.reducer,
	escortTags: fromEscortTags.reducer,
	reports: fromReports.reducer,
};

export const getFeaturesState =
	createFeatureSelector<FeaturesState>('features');
