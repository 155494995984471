import * as fromListings from '../actions/listings.action';
import * as fromSocket from '../../../ui/store/actions/socket.action';

import { LoadingState, ErrorState, CallState, getError } from './call-state';

import { Listing } from '../../models';

export interface ListingState {
	entities: { [UUID: string]: Listing };
	callState: CallState;
}

export const initialState: ListingState = {
	entities: {},
	callState: LoadingState.INIT
};

export function reducer(
	state = initialState,
	action: fromListings.ListingsAction | fromSocket.SocketAction
): ListingState {
	switch (action.type) {
		case fromListings.CLUB_API_POST_SEARCH_LISTINGS:
		case fromListings.CLUB_API_GET_LISTING:
		case fromListings.CLUB_API_GET_LATEST_LISTINGS: {
			return {
				...state,
				callState: LoadingState.LOADING
			};
		}

		case fromListings.CLUB_API_POST_SEARCH_LISTINGS_SUCCESS:
		case fromListings.CLUB_API_GET_LISTING_SUCCESS:
		case fromListings.CLUB_API_GET_LATEST_LISTINGS_SUCCESS: {
			const listings = action.payload.entities.listings;
			const entities = {
				...state.entities,
				...listings
			};

			return {
				...state,
				callState: LoadingState.LOADED,
				entities
			};
		}

		case fromListings.CLUB_API_POST_SEARCH_LISTINGS_FAIL:
		case fromListings.CLUB_API_GET_LISTING_FAIL:
		case fromListings.CLUB_API_GET_LATEST_LISTINGS_FAIL: {
			return {
				...state,
				callState: { errorMessage: action.errorMessage }
			};
		}

		case fromSocket.WEBSOCKET_SERVICE_GET_NEW_LISTING: {
			const listing = action.payload.entities.listings;
			const entities = {
				...state.entities,
				...listing
			};

			return {
				...state,
				entities
			};
		}

		default:
			return state;
	}
}

export const getListingsLoading 	= (state: ListingState) => state.callState === LoadingState.LOADING;
export const getListingsLoaded 		= (state: ListingState) => state.callState === LoadingState.LOADED;
export const getListingsError 		= (state: ListingState) => getError(state.callState);
export const getListingsEntities 	= (state: ListingState) => state.entities;
