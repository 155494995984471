import { ClubAPIResponseService } from './api-response.service';
import { UsersService } from './users.service';
import { ListingsService } from './listings.service';
import { EscortsService } from './escorts.service';
import { SuburbsService } from './suburbs.service';
import { EscortTagsService } from './escort-tags.service';
import { AuthUsersService } from './auth/users.service';
import { AuthEscortHandleValidationService } from './auth/escort-handle-validation.service';
import { AuthEscortService } from './auth/escort.service';
import { AuthListingService } from './auth/listing.service';
import { AuthNotificationService } from './auth/notification.service';
import { ReportService } from './report.service';

export const services: any[] = [
	ClubAPIResponseService,
	UsersService,
	ListingsService,
	EscortsService,
	SuburbsService,
	EscortTagsService,
	AuthUsersService,
	AuthEscortHandleValidationService,
	AuthEscortService,
	AuthListingService,
	AuthNotificationService,
	ReportService
];

export * from './api-response.service';
export * from './users.service';
export * from './listings.service';
export * from './escorts.service';
export * from './suburbs.service';
export * from './escort-tags.service';
export * from './auth/users.service';
export * from './auth/escort-handle-validation.service';
export * from './auth/escort.service';
export * from './auth/listing.service';
export * from './auth/notification.service';
export * from './report.service';