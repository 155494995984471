import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { XSRFInterceptor } from './xsrf.interceptor';
import { RefererInterceptor } from './referer.interceptor';
import { WithCredentialsInterceptor } from './with-credentials.interceptor';
import { AcceptLanguageInterceptor } from './accept-language.interceptor';
import { SocketInterceptor } from './socket.interceptor';

export const InterceptorProviders = [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: WithCredentialsInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: XSRFInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: RefererInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: AcceptLanguageInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: SocketInterceptor,
		multi: true
	}
];
