import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
	catchError,
	map,
	switchMap,
	tap,
	withLatestFrom
} from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import * as fromUI from 'src/library/ui/store';
import * as coreNotificationsActions from '../actions/notifications.action';

import { AuthNotificationService } from 'src/library/features/services';

import { normalize } from 'normalizr';
import * as fromSchema from 'src/library/features/models/schema.model';

@Injectable()
export class NotificationsEffects {
	constructor(
		private actions$: Actions,
		private authNotificationService: AuthNotificationService,
		private UIStore: Store<fromUI.UIState>
	) {}

	// Get Notifications
	fetchNotifications$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				coreNotificationsActions.HEADER_NOTIFICATION_COMPONENT_FETCH_NOTIFICATIONS
			),
			withLatestFrom(
				this.UIStore.select(fromUI.getUserNotificationState),
				(
					action: coreNotificationsActions.HeaderNotificationComponentFetchNotifications,
					ui
				) => {
					return ui.notificationPage;
				}
			),
			map((notificationPageNumber: number) => {
				return new coreNotificationsActions.ClubApiGetNotifications(
					notificationPageNumber
				);
			})
		);
	});

	// Get notification via API
	getNotifications$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreNotificationsActions.CLUB_API_GET_NOTIFICATIONS),
			switchMap(
				(action: coreNotificationsActions.ClubApiGetNotifications) => {
					return this.authNotificationService
						.getNotifications(action.payload)
						.pipe(
							map((data) => {
								const notifications = data.data;
								const normalizedData = normalize(
									notifications,
									[fromSchema.notification]
								);
								return new coreNotificationsActions.ClubApiGetNotificationsSuccess(
									normalizedData
								);
							}),
							catchError((error) => {
								return of(
									new coreNotificationsActions.ClubApiGetNotificationsFail(
										error
									)
								);
							})
						);
				}
			)
		);
	});

	// Mark All Notifications as acknowledged
	markAllNotificationsAsAcknowledged$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				coreNotificationsActions.HEADER_NOTIFICATION_COMPONENT_MARK_ALL_NOTIFICATIONS_AS_ACKNOWLEDGED
			),
			map(() => {
				return new coreNotificationsActions.ClubApiAcknowledgedNotifications();
			})
		);
	});

	// Mark All Notifications as acknowledged via API
	putAllNotificationsAsAcknowledged$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				coreNotificationsActions.CLUB_API_ACKNOWLEDGED_NOTIFICATIONS
			),
			switchMap(() => {
				return this.authNotificationService
					.acknowledgedNotifications()
					.pipe(
						map(() => {
							return new coreNotificationsActions.ClubApiAcknowledgedNotificationsSuccess();
						}),
						catchError((error) => {
							return of(
								new coreNotificationsActions.ClubApiAcknowledgedNotificationsFail(
									error
								)
							);
						})
					);
			})
		);
	});

	// Mark All Notifications as read
	markAllNotificationsAsRead$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				coreNotificationsActions.HEADER_NOTIFICATION_COMPONENT_MARK_ALL_NOTIFICATIONS_AS_READ
			),
			map(() => {
				return new coreNotificationsActions.ClubApiReadNotifications();
			})
		);
	});

	// Mark All Notifications as read via API
	putAllNotificationsAsRead$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreNotificationsActions.CLUB_API_READ_NOTIFICATIONS),
			switchMap(() => {
				return this.authNotificationService.readNotifications().pipe(
					map(() => {
						return new coreNotificationsActions.ClubApiReadNotificationsSuccess();
					}),
					catchError((error) => {
						return of(
							new coreNotificationsActions.ClubApiReadNotificationsFail(
								error
							)
						);
					})
				);
			})
		);
	});

	// Mark Notification as read by UUID
	markNotificationsAsReadByUUID$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				coreNotificationsActions.HEADER_NOTIFICATION_COMPONENT_MARK_NOTIFICATION_AS_READ
			),
			map(
				(
					action: coreNotificationsActions.HeaderNotificationComponentMarkNotificationAsRead
				) => {
					return new coreNotificationsActions.ClubApiReadNotification(
						action.payload
					);
				}
			)
		);
	});

	// Mark Notifications as read by UUID via API
	putNotificationsAsReadByUUID$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreNotificationsActions.CLUB_API_READ_NOTIFICATION),
			switchMap(
				(
					action: coreNotificationsActions.HeaderNotificationComponentMarkNotificationAsRead
				) => {
					return this.authNotificationService
						.readNotification(action.payload)
						.pipe(
							map(() => {
								return new coreNotificationsActions.ClubApiReadNotificationSuccess();
							}),
							catchError((error) => {
								return of(
									new coreNotificationsActions.ClubApiReadNotificationFail(
										error
									)
								);
							})
						);
				}
			)
		);
	});

	// Delete Notification by UUID
	removeNotificationByUUID$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				coreNotificationsActions.HEADER_NOTIFICATION_COMPONENT_DELETE_NOTIFICATION
			),
			map(
				(
					action: coreNotificationsActions.HeaderNotificationComponentDeleteNotification
				) => {
					return new coreNotificationsActions.ClubApiDeleteNotification(
						action.payload
					);
				}
			)
		);
	});

	// Delete Notification from API by UUID
	deleteNotificationByUUID$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreNotificationsActions.CLUB_API_DELETE_NOTIFICATION),
			switchMap(
				(
					action: coreNotificationsActions.ClubApiDeleteNotification
				) => {
					return this.authNotificationService
						.deleteNotification(action.payload)
						.pipe(
							map(() => {
								return new coreNotificationsActions.ClubApiDeleteNotificationSuccess();
							}),
							catchError((error) => {
								return of(
									new coreNotificationsActions.ClubApiDeleteNotificationFail(
										error
									)
								);
							})
						);
				}
			)
		);
	});

	// Get Notification UnAcknowledged count
	fetchNotificationUnAcknowledged$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				coreNotificationsActions.HEADER_NOTIFICATION_COMPONENT_FETCH_NOTIFICATION_COUNT
			),
			map(() => {
				return new coreNotificationsActions.ClubApiGetNotificationCount();
			})
		);
	});

	// Get Notification UnAcknowledged count via API
	getNotificationUnAcknowledged$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreNotificationsActions.CLUB_API_GET_NOTIFICATION_COUNT),
			switchMap(() => {
				return this.authNotificationService
					.getNotificationsCounts()
					.pipe(
						map((data) => {
							const unacknowledged = data.acknowledged;
							return new coreNotificationsActions.ClubApiGetNotificationCountSuccess(
								unacknowledged
							);
						}),
						catchError((error) => {
							return of(
								new coreNotificationsActions.ClubApiGetNotificationCountFail(
									error
								)
							);
						})
					);
			})
		);
	});
}
