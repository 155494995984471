import { Action } from '@ngrx/store';
import { LanguageTypeValue } from 'src/library/features/models';

export const HEADER_COMPONENT_SET_LANGUAGE                      = '[Header Component] Set Language';
export const VISITOR_AGREEMENT_PAGE_SET_LANGUAGE                = '[Visitor Agreement Page] Set Language';
export const ACCEPT_LANGUAGE_INTERCEPTOR_SET_LANGUAGE           = '[Accept Language Interceptor] Set Language';
export const COOKIE_STORAGE_SET_LANGUAGE_SUCCESS                = '[Cookie Storage] Set Language Success';
export const COOKIE_STORAGE_SET_LANGUAGE_SUCCESS_RELOAD_PAGE    = '[Cookie Storage] Set Language Success, Reload Page';


export class AcceptLanguageInterceptorSetLanguage implements Action {
    readonly type = ACCEPT_LANGUAGE_INTERCEPTOR_SET_LANGUAGE;
    constructor(public payload: LanguageTypeValue) { }
}

export class HeaderComponentSetLanguage implements Action {
    readonly type = HEADER_COMPONENT_SET_LANGUAGE;
    constructor(public payload: LanguageTypeValue) { }
}

export class VisitorAgreementPageSetLangauge implements Action {
    readonly type = VISITOR_AGREEMENT_PAGE_SET_LANGUAGE;
    constructor(public payload: LanguageTypeValue) { }
}

export class CookieStorageSetLanguageSuccess implements Action {
    readonly type = COOKIE_STORAGE_SET_LANGUAGE_SUCCESS;
    constructor(public payload: LanguageTypeValue) { }
}

export class CookieStorageSetLanguageSuccessReloadPage implements Action {
    readonly type = COOKIE_STORAGE_SET_LANGUAGE_SUCCESS_RELOAD_PAGE;
    constructor(public payload: LanguageTypeValue) { }
}


// action types
export type GlobalAction =
    | AcceptLanguageInterceptorSetLanguage
    | HeaderComponentSetLanguage
    | VisitorAgreementPageSetLangauge
    | CookieStorageSetLanguageSuccess
    | CookieStorageSetLanguageSuccessReloadPage
    ;
