import { Injectable, Injector } from '@angular/core';
import {
	HttpClient,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpErrorResponse,
	HttpXsrfTokenExtractor
} from '@angular/common/http';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap, finalize } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { CredentialsService } from 'src/app/core/services/credentials.service';

@Injectable()
export class XSRFInterceptor implements HttpInterceptor {
	private refreshXSRFTokenInProgress = false;
	private refreshXSRFTokenSubject: BehaviorSubject<any> =
		new BehaviorSubject<any>(null);
	// API URL variables
	private API_URL = `${environment.apiUrl}/${environment.apiVersion}`;

	constructor(
		private tokenService: HttpXsrfTokenExtractor,
		private httpClient: HttpClient,
		private router: Router,
		private credentialsService: CredentialsService
	) {}

	/**
	 * Adds an XSRF Token to the request
	 *
	 * @param request The outgoing request object to handle.
	 * @param next The next interceptor in the chain, or the backend if no interceptors remain in the chain.
	 */
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		request = this.addXSRFToken(request);

		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				// Laravel uses 419 for expired tokens
				if (error && error.status === 419) {
					if (this.refreshXSRFTokenInProgress) {
						// If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
						// which means the new token is ready and we can retry the request again
						return this.refreshXSRFTokenSubject.pipe(
							filter((result) => result !== null),
							take(1),
							switchMap(() =>
								next.handle(this.addXSRFToken(request))
							)
						);
					} else {
						this.refreshXSRFTokenInProgress = true;

						// Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
						this.refreshXSRFTokenSubject.next(null);

						return this.getXSRFCookie().pipe(
							switchMap((success: boolean) => {
								this.refreshXSRFTokenSubject.next(success);
								return next.handle(this.addXSRFToken(request));
							}),
							// When the call to refreshToken completes we reset the refreshTokenInProgress to false
							// for the next time the token needs to be refreshed
							finalize(
								() => (this.refreshXSRFTokenInProgress = false)
							)
						);
					}
				} else if (error && error.status === 401) {
					this.refreshXSRFTokenInProgress = true;

					// Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
					this.refreshXSRFTokenSubject.next(null);
					// UPDATE LATER ONCE SESSION IS FIXED
					this.credentialsService.unsetAuthorisedCookie();

					// this.router.navigate(['/login']);
					return this.getXSRFCookie().pipe(
						switchMap((success: boolean) => {
							this.refreshXSRFTokenSubject.next(success);
							this.router.navigate(['/login']);
							return next.handle(this.addXSRFToken(request));
						}),
						// When the call to refreshToken completes we reset the refreshTokenInProgress to false
						// for the next time the token needs to be refreshed
						finalize(
							() => (this.refreshXSRFTokenInProgress = false)
						)
					);
				} else {
					return throwError(error);
				}
			})
		);
	}

	/**
	 * Gets the the cookie from backend containing XSRF details.
	 *
	 * @private
	 * @returns {Observable<any>}
	 * @memberof XSRFInterceptor
	 */
	private getXSRFCookie(): Observable<any> {
		return this.httpClient.get<any>(`${this.API_URL}/sanctum/csrf-cookie`, {
			withCredentials: true
		});
	}

	/**
	 * Add a XSRF token to header
	 *
	 * @param request The outgoing request object that requires an XSRF token
	 */
	private addXSRFToken(request: HttpRequest<any>): HttpRequest<any> {
		const headerName = 'X-XSRF-TOKEN';
		const token = this.tokenService.getToken();

		// If we do not have a token yet then we should not set the header.
		// Here we could first retrieve the token from where we store it.
		if (!token) {
			return request;
		}

		// If you are calling an outside domain then do not add the token.
		if (!request.url.startsWith(`${this.API_URL}`)) {
			return request;
		}

		// Add credentials
		request = request.clone({
			withCredentials: true
		});

		// Be careful not to overwrite an existing header of the same name.
		if (token !== null && !request.headers.has(headerName)) {
			return request.clone({
				headers: request.headers.set(headerName, token)
			});
		} else {
			return request;
		}
	}
}
