import { LoadingState, ErrorState, CallState, getError } from './call-state';

import * as fromSocket from '../actions/socket.action';

export interface SocketState {
	connectionState: CallState;
}

export const initialState: SocketState = {
	connectionState: LoadingState.INIT
};

export function reducer(
	state = initialState,
	action: fromSocket.SocketAction
): SocketState {
	switch (action.type) {
		case fromSocket.WEBSOCKET_SERVICE_WEBSOCKET_CONNECT_RECONNECTING:
		case fromSocket.WEBSOCKET_SERVICE_WEBSOCKET_CONNECT: {
			return {
				...state,
				connectionState: LoadingState.LOADING
			};
		}

		case fromSocket.WEBSOCKET_SERVICE_WEBSOCKET_CONNECTION_EXIST:
		case fromSocket.WEBSOCKET_SERVICE_WEBSOCKET_CONNECT_SUCCESS: {
			return {
				...state,
				connectionState: LoadingState.LOADED
			};
		}

		case fromSocket.WEBSOCKET_SERVICE_WEBSOCKET_CONNECT_FAIL: {
			return {
				...state,
				connectionState: { errorMessage: action.errorMessage }
			};
		}

		default:
			return state;
	}
}

export const getSocketConnectionLoading 		= (state: SocketState) => state.connectionState === LoadingState.LOADING;
export const getSocketConnectionLoaded 			= (state: SocketState) => state.connectionState === LoadingState.LOADED;
export const getSocketConnectionError 			= (state: SocketState) => getError(state.connectionState);
