import * as fromCoreNotifications from '../actions/notifications.action';
import * as fromSocket from 'src/library/ui/store/actions/socket.action';

import { Notification } from 'src/library/features/models';
import {
	CallState,
	getError,
	LoadingState
} from 'src/library/features/store/reducers/call-state';

export interface NotificationState {
	unacknowledged: number;
	countState: CallState;
	entities: { [uuid: string]: Notification };
	callState: CallState;
	acknowledgedState: CallState;
	readState: CallState;
}

export const initialState: NotificationState = {
	unacknowledged: 0,
	countState: LoadingState.INIT,
	entities: {},
	callState: LoadingState.INIT,
	acknowledgedState: LoadingState.INIT,
	readState: LoadingState.INIT
};

export function reducer(
	state = initialState,
	action: fromCoreNotifications.NotificationsAction | fromSocket.SocketAction
): NotificationState {
	switch (action.type) {
		case fromCoreNotifications.CLUB_API_GET_NOTIFICATION_COUNT: {
			return {
				...state,
				countState: LoadingState.LOADING
			};
		}
		case fromCoreNotifications.CLUB_API_GET_NOTIFICATION_COUNT_SUCCESS: {
			const unacknowledged = action.payload;

			return {
				...state,
				unacknowledged,
				countState: LoadingState.LOADED
			};
		}
		case fromCoreNotifications.CLUB_API_GET_NOTIFICATION_COUNT_FAIL: {
			return {
				...state,
				countState: { errorMessage: action.errorMessage }
			};
		}

		case fromCoreNotifications.CLUB_API_ACKNOWLEDGED_NOTIFICATIONS: {
			// Update Store unacknowledged values, to improve perceived performance
			return {
				...state,
				unacknowledged: 0,
				acknowledgedState: LoadingState.LOADING
			};
		}

		case fromCoreNotifications.CLUB_API_ACKNOWLEDGED_NOTIFICATIONS_SUCCESS: {
			return {
				...state,
				acknowledgedState: LoadingState.LOADED
			};
		}

		case fromCoreNotifications.CLUB_API_ACKNOWLEDGED_NOTIFICATIONS_FAIL: {
			return {
				...state,
				acknowledgedState: { errorMessage: action.errorMessage }
			};
		}

		case fromCoreNotifications.CLUB_API_GET_NOTIFICATIONS: {
			return {
				...state,
				callState: LoadingState.LOADING
			};
		}

		case fromCoreNotifications.CLUB_API_GET_NOTIFICATIONS_SUCCESS: {
			const notifications = action.payload.entities.notifications;
			const entities = {
				...state.entities,
				...notifications
			};

			return {
				...state,
				entities,
				callState: LoadingState.LOADED
			};
		}

		case fromCoreNotifications.CLUB_API_GET_NOTIFICATIONS_FAIL: {
			return {
				...state,
				callState: { errorMessage: action.errorMessage }
			};
		}

		case fromCoreNotifications.CLUB_API_READ_NOTIFICATIONS: {
			// Update Store readAt values, to improve perceived performance
			let readEntities = { ...state.entities }; // Create a shallow copy
			for (let key in readEntities) {
				let readEntity = { ...readEntities[key] }; // Create a shallow copy
				readEntity.isRead = true;
				readEntities[key] = readEntity;
			}

			return {
				...state,
				entities: readEntities,
				readState: LoadingState.LOADING
			};
		}

		case fromCoreNotifications.CLUB_API_READ_NOTIFICATIONS_SUCCESS: {
			return {
				...state,
				readState: LoadingState.LOADED
			};
		}

		case fromCoreNotifications.CLUB_API_READ_NOTIFICATIONS_FAIL: {
			return {
				...state,
				readState: { errorMessage: action.errorMessage }
			};
		}

		case fromCoreNotifications.CLUB_API_READ_NOTIFICATION: {
			// Update Store readAt values, to improve perceived performance
			let readEntity = { ...state.entities[action.payload] }; // Create a shallow copy
			readEntity.isRead = true;
			console.log(readEntity);
			const entities = {
				...state.entities,
				[readEntity.UUID]: readEntity
			};

			return {
				...state,
				entities
			};
		}

		case fromCoreNotifications.CLUB_API_DELETE_NOTIFICATION: {
			const { [action.payload]: value, ...newEntities } = state.entities;
			return {
				...state,
				entities: newEntities
			};
		}

		case fromSocket.WEBSOCKET_SERVICE_GET_NEW_NOTIFICATION: {
			const notification = action.payload.entities.notifications;
			const newCount = parseInt(state.unacknowledged.toString()) + 1;
			const entities = {
				...state.entities,
				...notification,
			};

			return {
				...state,
				entities,
				unacknowledged: newCount
			};
		}

		default:
			return state;
	}
}

export const getNotificationsEntities 		= (state: NotificationState) => state.entities;
export const getNotificationUnacknowledged 	= (state: NotificationState) => state.unacknowledged;
export const getNotificationCountLoading 	= (state: NotificationState) => state.countState === LoadingState.LOADING;
export const getNotificationCountLoaded 	= (state: NotificationState) => state.countState === LoadingState.LOADED;
export const getNotificationCountError 		= (state: NotificationState) => getError(state.countState);
export const getNotificationsLoading 		= (state: NotificationState) => state.callState === LoadingState.LOADING;
export const getNotificationsLoaded 		= (state: NotificationState) => state.callState === LoadingState.LOADED;
export const getNotificationsError 			= (state: NotificationState) => getError(state.callState);

