import { Action } from '@ngrx/store';
import { Error } from 'src/app/shared/services';
import { Escort } from 'src/library/features/models';
import { EscortHandleValidationContext, EscortVerificationContext } from 'src/library/features/services/';


export const USER_ESCORT_REGISTRATION_PAGE_VALIDATE_HANDLE                      = '[User Escort Registration Page] Validate Handle'
export const CLUB_API_POST_HANDLE_VALIDATION                                    = '[Club API] Post Handle Validation';
export const CLUB_API_POST_HANDLE_VALIDATION_FAIL                               = '[Club API] Post Handle Validation Fail';
export const CLUB_API_POST_HANDLE_VALIDATION_SUCCESS                            = '[Club API] Post Handle Validation Success';

export const USER_ESCORT_REGISTRATION_PAGE_CREATE_ESCORT                        = '[User Escort Registration Page] Create Escort'
export const CLUB_API_POST_CREATE_ESCORT                                        = '[Club API] Post Create Escort';
export const CLUB_API_POST_CREATE_ESCORT_FAIL                                   = '[Club API] Post Create Escort Fail';
export const CLUB_API_POST_CREATE_ESCORT_SUCCESS                                = '[Club API] Post Create Escort Success';

export const USER_PROFILE_EDIT_PAGE_UPDATE_ESCORT                               = '[User Profile Edit Page] Update Escort'
export const CLUB_API_PUT_UPDATE_ESCORT                                         = '[Club API] Put Update Escort';
export const CLUB_API_PUT_UPDATE_ESCORT_FAIL                                    = '[Club API] Put Update Escort Fail';
export const CLUB_API_PUT_UPDATE_ESCORT_SUCCESS                                 = '[Club API] Put Update Escort Success';

export const USER_ESCORT_REGISTRATION_PAGE_VERIFY_ESCORT                        = '[User Escort Registration Page] Verify Escort'
export const USER_ESCORT_PROFILE_PAGE_VERIFY_ESCORT                             = '[User Escort Profile Page] Verify Escort'
export const CLUB_API_POST_ESCORT_VERIFICATION                                  = '[Club API] Post Escort Verification';
export const CLUB_API_POST_ESCORT_VERIFICATION_FAIL                             = '[Club API] Post Escort Verification Fail';
export const CLUB_API_POST_ESCORT_VERIFICATION_SUCCESS                          = '[Club API] Post Escort Verification Success';
export const USER_ESCORT_REGISTRATION_PAGE_VERIFY_ESCORT_SUCCESS_REDIRECT       = '[User Escort Registration Page] Verify Escort Success Redirect'

export class UserEscortRegistrationPageValidateHandle implements Action {
    readonly type = USER_ESCORT_REGISTRATION_PAGE_VALIDATE_HANDLE;
    constructor(public payload?: EscortHandleValidationContext ) { }
}
export class ClubApiPostHandleValidation implements Action {
    readonly type = CLUB_API_POST_HANDLE_VALIDATION;
    constructor(public payload?: EscortHandleValidationContext) { }}

export class ClubApiPostHandleValidationFail implements Action {
    readonly type = CLUB_API_POST_HANDLE_VALIDATION_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiPostHandleValidationSuccess implements Action {
    readonly type = CLUB_API_POST_HANDLE_VALIDATION_SUCCESS;
    constructor() {}
}

export class UserEscortRegistrationPageCreateEscort implements Action {
    readonly type = USER_ESCORT_REGISTRATION_PAGE_CREATE_ESCORT;
    constructor(public payload?: Escort ) { }
}
export class ClubApiPostCreateEscort implements Action {
    readonly type = CLUB_API_POST_CREATE_ESCORT;
    constructor(public payload?: Escort) { }}

export class ClubApiPostCreateEscortFail implements Action {
    readonly type = CLUB_API_POST_CREATE_ESCORT_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiPostCreateEscortSuccess implements Action {
    readonly type = CLUB_API_POST_CREATE_ESCORT_SUCCESS;
    constructor(public payload?: any) {}
}

export class UserProfileEditPageUpdateEscort implements Action {
    readonly type = USER_PROFILE_EDIT_PAGE_UPDATE_ESCORT;
    constructor(public payload?: Escort ) { }
}
export class ClubApiPutUpdateEscort implements Action {
    readonly type = CLUB_API_PUT_UPDATE_ESCORT;
    constructor(public payload?: Escort) { }}

export class ClubApiPutUpdateEscortFail implements Action {
    readonly type = CLUB_API_PUT_UPDATE_ESCORT_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiPutUpdateEscortSuccess implements Action {
    readonly type = CLUB_API_PUT_UPDATE_ESCORT_SUCCESS;
    constructor(public payload?: any) {}
}

export class UserEscortRegistrationPageVerifyEscort implements Action {
    readonly type = USER_ESCORT_REGISTRATION_PAGE_VERIFY_ESCORT;
    constructor(public payload?: EscortVerificationContext ) { }
}

export class UserEscortProfilePageVerifyEscort implements Action {
    readonly type = USER_ESCORT_PROFILE_PAGE_VERIFY_ESCORT;
    constructor(public payload?: EscortVerificationContext ) { }
}
export class ClubApiPostEscortVerification implements Action {
    readonly type = CLUB_API_POST_ESCORT_VERIFICATION;
    constructor(public payload?: EscortVerificationContext) { }}

export class ClubApiPostEscortVerificationFail implements Action {
    readonly type = CLUB_API_POST_ESCORT_VERIFICATION_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiPostEscortVerificationSuccess implements Action {
    readonly type = CLUB_API_POST_ESCORT_VERIFICATION_SUCCESS;
    constructor() {}
}

export class UserEscortRegistrationPageVerifyEscortSuccessRedirect implements Action {
    readonly type = USER_ESCORT_REGISTRATION_PAGE_VERIFY_ESCORT_SUCCESS_REDIRECT;
    constructor() { }
}


// action types
export type EscortsAction =
    | UserEscortRegistrationPageValidateHandle
    | ClubApiPostHandleValidation
    | ClubApiPostHandleValidationFail
    | ClubApiPostHandleValidationSuccess
    | UserEscortRegistrationPageCreateEscort
    | ClubApiPostCreateEscort
    | ClubApiPostCreateEscortFail
    | ClubApiPostCreateEscortSuccess
    | UserProfileEditPageUpdateEscort
    | ClubApiPutUpdateEscort
    | ClubApiPutUpdateEscortFail
    | ClubApiPutUpdateEscortSuccess
    | UserEscortRegistrationPageVerifyEscort
    | UserEscortProfilePageVerifyEscort
    | ClubApiPostEscortVerification
    | ClubApiPostEscortVerificationFail
    | ClubApiPostEscortVerificationSuccess
    | UserEscortRegistrationPageVerifyEscortSuccessRedirect
    ;
