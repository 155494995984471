import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
	HttpErrorResponse,
	HttpParams,
	HttpResponse
} from '@angular/common/http';

import { catchError, map, tap } from 'rxjs/operators';
import {
	LogService,
	ErrorService,
	CustomHttpService
} from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { ClubAPIResponse, ClubAPIResponseService, PaginatedResponse } from '..';
import { Notification } from '../../models';

@Injectable({
	providedIn: 'root'
})
export class AuthNotificationService {
	// API environment variables
	private ENV = `${environment.apiUrl}/${environment.apiVersion}`;
	// API Request information
	private API_END_POINT = 'user/notifications'; // * Modify to change API path

	private URL = `${this.ENV}/${this.API_END_POINT}`;
	private SERVICE_NAME = `API Auth (${this.API_END_POINT})`;

	constructor(
		private http: CustomHttpService,
		private logService: LogService,
		private errorService: ErrorService
	) {}

	private log(message: string) {
		this.logService.add(`${this.SERVICE_NAME}: ${message}`, 'success');
	}

	/**
	 * Get User Notifications
	 * Use Pagination
	 * @param page - page number for pagination
	 * @returns Observable<PaginatedResponse<Notification[]>>
	 */
	getNotifications(
		page: number
	): Observable<PaginatedResponse<Notification[]>> {
		const url = `${this.URL}`;
		const params = new HttpParams().set('page', page.toString());
		return this.http.get(url, params).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<Notification[]>(
					res,
					'Notification',
					true,
					true
				)
			),
			tap((_) =>
				this.log(`Get User Notifications Page ${page} success.`)
			),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getNotifications'
				)
			)
		);
	}

	/**
	 * Get Notification by uuid
	 * @param uuid - uuid
	 */
	getNotification(uuid: string): Observable<Notification> {
		const url = `${this.URL}/${uuid}`;
		return this.http.get(url).pipe(
			// tap((res) => console.log(res)),
			map((res) =>
				ClubAPIResponseService.validateResponse<Notification>(
					res,
					'Notification',
					false
				)
			),
			tap((_) => this.log(`getNotification ${uuid} success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getNotification'
				)
			)
		);
	}

	/**
	 * Mark Notifications as acknowledged
	 */
	acknowledgedNotifications(): Observable<any> {
		const url = `${this.URL}/acknowledge`;
		const req = {}; // Don't need to send body request
		return this.http.put<any>(url, req).pipe(
			map((_) => of(null)),
			tap((_) =>
				this.log(`Mark Notifications as acknowledged Successfully.`)
			),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'acknowledgedNotifications'
				)
			)
		);
	}

	/**
	 * Mark Notifications as read
	 */
	readNotifications(): Observable<any> {
		const url = `${this.URL}/read`;
		const req = {}; // Don't need to send body request
		return this.http.put<any>(url, req).pipe(
			map((_) => of(null)),
			tap((_) => this.log(`Mark Notifications as read Successfully.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'readNotifications'
				)
			)
		);
	}

	/**
	 * Mark Notification as read by uuid
	 * @param uuid - uuid
	 */
	readNotification(uuid: string): Observable<any> {
		const url = `${this.URL}/${uuid}/read`;
		const req = {}; // Don't need to send body request
		return this.http.put<any>(url, req).pipe(
			map((_) => of(null)),
			tap((_) =>
				this.log(`Mark Notification ${uuid} as read Successfully.`)
			),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'readNotification'
				)
			)
		);
	}

	/**
	 * Delete Notification by uuid
	 * @param uuid - uuid
	 */
	deleteNotification(uuid: string): Observable<any> {
		const url = `${this.URL}/${uuid}`;
		return this.http.delete(url).pipe(
			tap((_) => this.log(`deleteNotification ${uuid} success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'deleteNotification'
				)
			)
		);
	}

	/**
	 * Get User Notification UnAcknowledged Count
	 * @returns Observable<UserNotificationCountResponse>
	 */
	getNotificationsCounts(): Observable<UserNotificationCountResponse> {
		const url = `${this.URL}/counts`;
		return this.http.get(url).pipe(
			map((res) => {
				return this.convertNotificationCountsResponse(res) as any;
			}),
			tap((_) => this.log(`Get User Notifications counts success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getNotificationsCounts'
				)
			)
		);
	}

	private convertNotificationCountsResponse(
		res: HttpResponse<ClubAPIResponse<any>>
	): UserNotificationCountResponse {
		return res.body.data;
	}

	/**
	 *  Converts Notification data sent via socket to DTO model
	 *
	 * @param res ClubNotification Response sent by socket
	 * @returns Notification
	 */
	convertSocketNotification(res: any): Notification {
		const e = ClubAPIResponseService.ValidateSocketResponse<Notification>(
			res,
			'Notification',
			false
		);
		return e;
	}
}

export interface UserNotificationCountResponse {
	acknowledged?: number;
}
