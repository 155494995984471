import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router
} from '@angular/router';
import { Observable, of } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromCore from '../../core/store';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

@Injectable()
export class NoAuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private store: Store<fromCore.CoreState>
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		return this.isAuthenticated$().pipe(
			switchMap((isAuthenticated) => {
				if (isAuthenticated) {
					this.redirectToUrl(route);
					return of(false);
				} else {
					this.store.dispatch(
						new fromCore.AuthGuardCheckUserLoginStatus()
					);
					return this.isLoggedIn$().pipe(
						switchMap((isLoggedIn) => {
							if (isLoggedIn) {
								this.redirectToUrl(route);
								return of(false);
							} else {
								return of(true);
							}
						})
					);
				}
			})
		);
	}

	private isAuthenticated$(): Observable<boolean> {
		return this.store.select(fromCore.isAuthenticated).pipe(take(1));
	}

	private isLoggedIn$(): Observable<boolean> {
		return this.store.select(fromCore.getCoreState).pipe(
			skipWhile((core) => core.auth.authGuardCheck),
			map((core) => core.auth.authState === 'LOADED'),
			take(1)
		);
	}

	private redirectToUrl(state: ActivatedRouteSnapshot): void {
		if (state.queryParams && state.queryParams.redirect) {
			this.router.navigate([`/${state.queryParams.redirect}`], {
				replaceUrl: true
			});
		} else {
			this.router.navigate(['/home'], {
				replaceUrl: true
			});
		}
	}
}
