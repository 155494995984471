import * as fromCoreAuth from '../actions/auth.action';
import * as fromCoreListings from '../actions/listings.action';

import {
	CallState,
	getError,
	LoadingState
} from 'src/library/features/store/reducers/call-state';
import { User } from 'src/library/features/models';

export interface AuthState {
	loginState: CallState;
	logoutState: CallState;
	authState: CallState;
	registerState: CallState;
	requestVerifyUserEmailState: CallState;
	usernameValidationState: CallState;
	verifyUserEmailState: CallState;
	forgotPasswordState: CallState;
	resetPasswordState: CallState;
	updateState: CallState;
	authGuardCheck: boolean;
	userUUID: string | null;
	entities: { [uuid: string]: User };
}

export const initialState: AuthState = {
	loginState: LoadingState.INIT,
	logoutState: LoadingState.INIT,
	authState: LoadingState.INIT,
	registerState: LoadingState.INIT,
	requestVerifyUserEmailState: LoadingState.INIT,
	usernameValidationState: LoadingState.INIT,
	verifyUserEmailState: LoadingState.INIT,
	forgotPasswordState: LoadingState.INIT,
	resetPasswordState: LoadingState.INIT,
	updateState: LoadingState.INIT,
	authGuardCheck: false,
	userUUID: null,
	entities: {}
};

export function reducer(
	state = initialState,
	action: fromCoreAuth.AuthAction | fromCoreListings.ListingsAction
): AuthState {
	switch (action.type) {
		case fromCoreAuth.CLUB_API_POST_LOGIN_USER: {
			return {
				...state,
				loginState: LoadingState.LOADING
			};
		}

		case fromCoreAuth.AUTH_STORE_LOGIN_STATE_UPDATE:
		case fromCoreAuth.CLUB_API_POST_LOGIN_USER_SUCCESS: {
			return {
				...state,
				loginState: LoadingState.LOADED,
				logoutState: LoadingState.INIT
			};
		}

		case fromCoreAuth.CLUB_API_POST_LOGIN_USER_FAIL: {
			return {
				...state,
				loginState: { errorMessage: action.errorMessage }
			};
		}

		case fromCoreAuth.COOKIE_STORAGE_ATTEMPT_GET_AUTH_DETAILS:
		case fromCoreAuth.CLUB_API_GET_AUTH_USER_DETAILS: {
			return {
				...state,
				authState: LoadingState.LOADING
			};
		}

		case fromCoreAuth.CLUB_API_GET_AUTH_USER_DETAILS_SUCCESS: {
			const users = action.payload.entities.users;
			const userUUID = action.payload.result;
			const entities = {
				...state.entities,
				...users
			};

			return {
				...state,
				authState: LoadingState.LOADED,
				authGuardCheck: false,
				entities,
				userUUID
			};
		}

		case fromCoreAuth.CLUB_API_GET_AUTH_USER_DETAILS_FAIL: {
			return {
				...state,
				authState: { errorMessage: action.errorMessage },
				authGuardCheck: false
			};
		}

		case fromCoreAuth.CLUB_API_POST_LOGOUT_USER: {
			return {
				...state,
				logoutState: LoadingState.LOADING
			};
		}

		case fromCoreAuth.AUTH_STORE_LOGOUT_STATE_UPDATE:
		case fromCoreAuth.CLUB_API_POST_LOGOUT_USER_SUCCESS: {
			return {
				...state,
				loginState: LoadingState.INIT,
				logoutState: LoadingState.LOADED,
				authState: LoadingState.INIT,
				userUUID: null
			};
		}

		case fromCoreAuth.CLUB_API_POST_LOGOUT_USER_FAIL: {
			return {
				...state,
				logoutState: { errorMessage: action.errorMessage }
			};
		}

		case fromCoreAuth.AUTH_GUARD_CHECK_LOGIN_STATUS: {
			return {
				...state,
				authGuardCheck: true
			};
		}

		case fromCoreAuth.COOKIE_STORAGE_CHECK_LOGIN_FALSE: {
			return {
				...state,
				authGuardCheck: false
			};
		}

		case fromCoreAuth.CLUB_API_POST_REGISTER_USER: {
			return {
				...state,
				registerState: LoadingState.LOADING
			};
		}

		case fromCoreAuth.CLUB_API_POST_REGISTER_USER_SUCCESS: {
			return {
				...state,
				registerState: LoadingState.LOADED,
				loginState: LoadingState.LOADED,
				logoutState: LoadingState.INIT
			};
		}

		case fromCoreAuth.CLUB_API_POST_REGISTER_USER_FAIL: {
			return {
				...state,
				registerState: { errorMessage: action.errorMessage }
			};
		}

		case fromCoreAuth.CLUB_API_POST_RESET_PASSWORD_REQUEST: {
			return {
				...state,
				forgotPasswordState: LoadingState.LOADING
			};
		}

		case fromCoreAuth.CLUB_API_POST_RESET_PASSWORD_REQUEST_SUCCESS: {
			return {
				...state,
				forgotPasswordState: LoadingState.LOADED
			};
		}

		case fromCoreAuth.CLUB_API_POST_RESET_PASSWORD_REQUEST_FAIL: {
			return {
				...state,
				forgotPasswordState: { errorMessage: action.errorMessage }
			};
		}

		case fromCoreAuth.CLUB_API_POST_VERIFY_USER_EMAIL_REQUEST: {
			return {
				...state,
				requestVerifyUserEmailState: LoadingState.LOADING
			};
		}

		case fromCoreAuth.CLUB_API_POST_VERIFY_USER_EMAIL_REQUEST_SUCCESS: {
			return {
				...state,
				requestVerifyUserEmailState: LoadingState.LOADED
			};
		}

		case fromCoreAuth.CLUB_API_POST_VERIFY_USER_EMAIL_REQUEST_FAIL: {
			return {
				...state,
				requestVerifyUserEmailState: {
					errorMessage: action.errorMessage
				}
			};
		}

		case fromCoreAuth.CLUB_API_POST_USERNAME_VALIDATION: {
			return {
				...state,
				usernameValidationState: LoadingState.LOADING
			};
		}

		case fromCoreAuth.CLUB_API_POST_USERNAME_VALIDATION_SUCCESS: {
			return {
				...state,
				usernameValidationState: LoadingState.LOADED
			};
		}

		case fromCoreAuth.CLUB_API_POST_USERNAME_VALIDATION_FAIL: {
			return {
				...state,
				usernameValidationState: { errorMessage: action.errorMessage }
			};
		}

		case fromCoreAuth.CLUB_API_POST_VERIFY_USER_EMAIL: {
			return {
				...state,
				verifyUserEmailState: LoadingState.LOADING
			};
		}

		case fromCoreAuth.CLUB_API_POST_VERIFY_USER_EMAIL_SUCCESS: {
			return {
				...state,
				verifyUserEmailState: LoadingState.LOADED
			};
		}

		case fromCoreAuth.CLUB_API_POST_VERIFY_USER_EMAIL_FAIL: {
			return {
				...state,
				verifyUserEmailState: { errorMessage: action.errorMessage }
			};
		}

		case fromCoreAuth.CLUB_API_POST_RESET_PASSWORD: {
			return {
				...state,
				resetPasswordState: LoadingState.LOADING
			};
		}

		case fromCoreAuth.CLUB_API_POST_RESET_PASSWORD_SUCCESS: {
			return {
				...state,
				resetPasswordState: LoadingState.LOADED
			};
		}

		case fromCoreAuth.CLUB_API_POST_RESET_PASSWORD_FAIL: {
			return {
				...state,
				resetPasswordState: { errorMessage: action.errorMessage }
			};
		}

		case fromCoreAuth.CLUB_API_PUT_UPDATE_USER: {
			return {
				...state,
				updateState: LoadingState.LOADING
			};
		}

		case fromCoreAuth.CLUB_API_PUT_UPDATE_USER_SUCCESS: {
			const users = action.payload.entities.users;
			const entities = {
				...state.entities,
				...users
			};

			return {
				...state,
				updateState: LoadingState.LOADED,
				entities
			};
		}

		case fromCoreAuth.CLUB_API_PUT_UPDATE_USER_FAIL: {
			return {
				...state,
				updateState: { errorMessage: action.errorMessage }
			};
		}

		default:
			return state;
	}
}

export const getLoginLoading						= (state: AuthState) => state.loginState === LoadingState.LOADING;
export const getLoginLoaded							= (state: AuthState) => state.loginState === LoadingState.LOADED;
export const getLoginError							= (state: AuthState) => getError(state.loginState);
export const getAuthLoading							= (state: AuthState) => state.authState === LoadingState.LOADING;
export const getAuthLoaded							= (state: AuthState) => state.authState === LoadingState.LOADED;
export const getAuthError							= (state: AuthState) => getError(state.authState);
export const getLogoutLoading						= (state: AuthState) => state.logoutState === LoadingState.LOADING;
export const getLogoutLoaded						= (state: AuthState) => state.logoutState === LoadingState.LOADED;
export const getLogoutError							= (state: AuthState) => getError(state.logoutState);
export const getRegisterLoading						= (state: AuthState) => state.registerState === LoadingState.LOADING;
export const getRegisterLoaded						= (state: AuthState) => state.registerState === LoadingState.LOADED;
export const getRegisterError						= (state: AuthState) => getError(state.registerState);
export const getRequestVerifyUserEmailLoading		= (state: AuthState) => state.requestVerifyUserEmailState === LoadingState.LOADING;
export const getRequestVerifyUserEmailLoaded		= (state: AuthState) => state.requestVerifyUserEmailState === LoadingState.LOADED;
export const getRequestVerifyUserEmailError			= (state: AuthState) => getError(state.requestVerifyUserEmailState);
export const getUsernameValidationLoading			= (state: AuthState) => state.usernameValidationState === LoadingState.LOADING;
export const getUsernameValidationLoaded			= (state: AuthState) => state.usernameValidationState === LoadingState.LOADED;
export const getUsernameValidationError				= (state: AuthState) => getError(state.usernameValidationState);
export const getVerifyUserEmailLoading				= (state: AuthState) => state.verifyUserEmailState === LoadingState.LOADING;
export const getVerifyUserEmailLoaded				= (state: AuthState) => state.verifyUserEmailState === LoadingState.LOADED;
export const getVerifyUserEmailError				= (state: AuthState) => getError(state.verifyUserEmailState);
export const getForgotPasswordLoading				= (state: AuthState) => state.forgotPasswordState === LoadingState.LOADING;
export const getForgotPasswordLoaded				= (state: AuthState) => state.forgotPasswordState === LoadingState.LOADED;
export const getForgotPasswordError					= (state: AuthState) => getError(state.forgotPasswordState);
export const getResetPasswordLoading				= (state: AuthState) => state.resetPasswordState === LoadingState.LOADING;
export const getResetPasswordLoaded					= (state: AuthState) => state.resetPasswordState === LoadingState.LOADED;
export const getResetPasswordError					= (state: AuthState) => getError(state.resetPasswordState);
export const getUpdateUserLoading					= (state: AuthState) => state.updateState === LoadingState.LOADING;
export const getUpdateUserLoaded					= (state: AuthState) => state.updateState === LoadingState.LOADED;
export const getUpdateUserError						= (state: AuthState) => getError(state.resetPasswordState);
export const getAuthUser							= (state: AuthState) => state.userUUID;
export const getAuthUserEntities					= (state: AuthState) => state.entities;
