import { Inject, Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import { CredentialsService } from 'src/app/core/services/credentials.service';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromCore from '../../core/store';
import { map, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class RefererInterceptor implements HttpInterceptor {
	// API URL variables
	private API_URL = `${environment.apiUrl}/${environment.apiVersion}`;
	private API_REFERER_NAME = window.location.hostname;

	constructor(
		private store: Store<fromCore.CoreState>,
		private credentialsService: CredentialsService
	) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		// Check is already logging the user in or out, if so then skip request
		if (!this.credentialsService.referrerInterceptorLoggingInRequestFlag) {
			// Check if user is already logged in
			this.isLoggedin$().pipe(
				map((res) => {
					if (res) {
						return next.handle(this.addReferer(request));
					}
				})
			);
		}
		return next.handle(request);
	}

	private isLoggedin$(): Observable<boolean> {
		return this.store.select(fromCore.isAuthenticated).pipe(take(1));
	}

	/**
	 * Add a referer to header
	 *
	 * @param request The outgoing request object that requires an XSRF token
	 */
	private addReferer(request: HttpRequest<any>): HttpRequest<any> {
		const headerName = 'referer';
		const value = this.API_REFERER_NAME;

		// If we do not have a token yet then we should not set the header.
		// Here we could first retrieve the token from where we store it.
		if (!value) {
			return request;
		}

		// If you are calling an outside domain then do not add the token.
		if (!request.url.startsWith(`${this.API_URL}`)) {
			return request;
		}

		// Add credentials
		request = request.clone({
			withCredentials: true
		});

		// Be careful not to overwrite an existing header of the same name.
		if (value !== null && !request.headers.has(headerName)) {
			return request.clone({
				headers: request.headers.set(headerName, value)
			});
		} else {
			return request;
		}
	}
}
