import {
	createSelector,
	createSelectorFactory,
	defaultMemoize
} from '@ngrx/store';

import * as fromRoot from '../../../../app/store';
import { Media, User } from '../../models';
import * as fromFeature from '../reducers';
import * as fromUsers from '../reducers/users.reducer';

import { getMediaEntities } from './media.selectors';

function checkEqual(a, b) {
	return (
		a && b && a.length === b.length && a.every((val, idx) => val === b[idx])
	);
}

export const getUserState = createSelector(
	fromFeature.getFeaturesState,
	(state: fromFeature.FeaturesState) => state.users
);

export const getUsersEntities = createSelector(
	getUserState,
	fromUsers.getUsersEntities
);

export const getUsersLoaded = createSelector(
	getUserState,
	fromUsers.getUsersLoaded
);

export const getUsersLoading = createSelector(
	getUserState,
	fromUsers.getUsersLoading
);

export const getUsersError = createSelector(
	getUserState,
	fromUsers.getUsersError
);

export const getUsersList = createSelector(getUsersEntities, (entities) =>
	Object.keys(entities).map((key) => entities[key])
);

export const getUser = (userUUID: string) => createSelector(
	getUsersEntities,
	(users): User => users[userUUID]
);

export const getMediaOfUser = () =>
	// Memoize the media to only trigger on change
	createSelectorFactory((projector) =>
		defaultMemoize(projector, undefined, checkEqual)
	)(
		getUser,
		getMediaEntities,
		(user: User, media: Media): Media =>
			user ? media[user.avatar as string] : null
	);

// Get Denormalized user - use this.store.select(getUserView, id)
export const getUserView = createSelector(
	getUser,
	getMediaOfUser(),
	(user, avatar): User =>
		user && {
			...user,
			avatar
		}
);

export const getUserByRouteID = createSelector(
	getUsersEntities,
	fromRoot.getRouterState,
	(users, router): User => router.state && users[router.state.params.userId]
);

// Get Denormalized user by Route ID
export const getUserByRouteIDView = createSelector(
	getUserByRouteID,
	getMediaEntities,
	(user: User, media: Media): User =>
		user && {
			...user,
			avatar: media[user.avatar as string]
		}
);

export const getUserByID = (UUID: string) =>
	createSelector(
		getUsersList,
		(users): User => users.find((m) => m.UUID === UUID)
	);
