import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import { catchError, map, tap } from 'rxjs/operators';
import {
	LogService,
	ErrorService,
	CustomHttpService
} from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { ClubAPIResponse, ClubAPIResponseService } from './';
import { Report } from '../models';

@Injectable({
	providedIn: 'root'
})
export class ReportService {
	// API environment variables
	private ENV = `${environment.apiUrl}/${environment.apiVersion}`;
	// API Request information
	private API_END_POINT = 'report'; // * Modify to change API path

	private URL = `${this.ENV}/${this.API_END_POINT}`;
	private SERVICE_NAME = `API Auth (${this.API_END_POINT})`;

	constructor(
		private http: CustomHttpService,
		private logService: LogService,
		private errorService: ErrorService
	) {}

	private log(message: string) {
		this.logService.add(`${this.SERVICE_NAME}: ${message}`, 'success');
	}

	/**
	 * Post report
	 */
	postReport(report: Report): Observable<any> {
		const req = ClubAPIResponseService.ModelToAPIConverter(
			report,
			'Report'
		);
		return this.http.post<ClubAPIResponse<any>>(this.URL, req).pipe(
			tap((_) => this.log(`Reported ${report.modelType} ${report.UUID} Successfully.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'postReport'
				)
			)
		);
	}
}
