import { Action } from '@ngrx/store';
import { Error } from 'src/app/shared/services';

// load users
export const FETCH_USERS                                        = '[Users] Fetch Users';
export const FETCH_USERS_FAIL                                   = '[Users] Fetch Users Fail';
export const FETCH_USERS_SUCCESS                                = '[Users] Fetch Users Success';
export const PROFILE_PAGE_GUARD_FETCH_USER_FROM_ROUTE           = '[Profile Page Guard] Fetch User From Route ID';
export const CLUB_API_GET_USER_FAIL                             = '[Club API] Get User Fail';
export const CLUB_API_GET_USER_SUCCESS                          = '[Club API] Get User Success';
export const USER_PROFILE_EDIT_PAGE_FETCH_USER_FROM_ROUTE       = '[User Profile Edit Page] Fetch User From Route ID';


export class FetchUsers implements Action {
    readonly type = FETCH_USERS;
}

export class FetchUsersFail implements Action {
    readonly type = FETCH_USERS_FAIL;
    constructor(public errorMessage: any) { }
}

export class FetchUsersSuccess implements Action {
    readonly type = FETCH_USERS_SUCCESS;
    constructor(public payload: any) { }
}

export class ProfilePageGuardFetchUserFromRoute implements Action {
    readonly type = PROFILE_PAGE_GUARD_FETCH_USER_FROM_ROUTE;
}

export class ClubApiGetUserFail implements Action {
    readonly type = CLUB_API_GET_USER_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiGetUserSuccess implements Action {
    readonly type = CLUB_API_GET_USER_SUCCESS;
    constructor(public payload: any) { }
}

export class UserProfileEditPageFetchUserFromRoute implements Action {
    readonly type = USER_PROFILE_EDIT_PAGE_FETCH_USER_FROM_ROUTE;
}

// action types
export type UsersAction =
    | FetchUsers
    | FetchUsersFail
    | FetchUsersSuccess
    | ProfilePageGuardFetchUserFromRoute
    | ClubApiGetUserFail
    | ClubApiGetUserSuccess
    | UserProfileEditPageFetchUserFromRoute;
