import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LogService {
	messages: string[] = [];

	/**
	 * Add To logging
	 * @param message log Message
	 * @param title title of the log message
	 * @param type styling for the log message
	 */
	add(
		message: string,
		type: 'success' | 'error' | 'default' = 'default',
		title?: string
	) {
		// TODO: send to remote logging service
		if (title) {
			this.messages.push(`${title} ${message}`);
		} else {
			this.messages.push(message);
		}

		const typeClassStyle: { [key: string]: string } = {
			success: 'color: #28ba62',
			error: 'color: #c13333',
			default: ''
		};

		// Console log the message
		if (title) {
			console.log(`%c${title}\r\n${message}`, typeClassStyle[type]);
		} else {
			console.log(`%c${message}`, typeClassStyle[type]);
		}
	}

	clear() {
		this.messages = [];
	}
}
