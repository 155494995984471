import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { ClubAPIAuthResponse } from './api-response.service';
import {
	LogService,
	ErrorService,
	CustomHttpService
} from 'src/app/shared/services';

@Injectable({
	providedIn: 'root'
})
export class UserVerificationRequestService {
	// API environment variables
	private ENV = `${environment.apiUrl}/${environment.apiVersion}`;
	// API Request information
	private API_END_POINT = 'email/verification-notification'; // * Modify to change API path

	private URL = `${this.ENV}/${this.API_END_POINT}`;
	private SERVICE_NAME = `API (${this.API_END_POINT})`;

	constructor(
		private http: CustomHttpService,
		private logService: LogService,
		private errorService: ErrorService
	) {}

	private log(message: string) {
		this.logService.add(`${this.SERVICE_NAME}: ${message}`, 'success');
	}

	/**
	 * Request User email verification
	 */
	requestVerification(): Observable<any> {
		return this.http
			.post<HttpResponse<ClubAPIAuthResponse>>(this.URL, {})
			.pipe(
				tap((_) =>
					this.log(`Request Email Verification Successfully.`)
				),
				catchError((res: HttpErrorResponse) =>
					this.errorService.handleAPIHttpError(
						res,
						this.SERVICE_NAME,
						'requestVerification'
					)
				)
			);
	}
}
