import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { of } from 'rxjs';
import {
	catchError,
	map,
	switchMap,
	tap,
	withLatestFrom
} from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { Store } from '@ngrx/store';
import * as coreListingsActions from '../actions/listings.action';
import * as fromCore from '../reducers';
import * as fromRoot from '../../../store';

import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { AuthListingService } from 'src/library/features/services';

import { normalize } from 'normalizr';
import * as fromSchema from 'src/library/features/models/schema.model';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ListingsEffects {
	constructor(
		private authListingService: AuthListingService,
		private actions$: Actions,
		private store: Store<fromRoot.State>,
		private snackbarService: SnackbarService,
		private translocoService: TranslocoService,
		private router: Router
	) {}

	// Get Listings
	fetchListings$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				coreListingsActions.USER_LISTING_PAGE_FETCH_LISTINGS,
				coreListingsActions.USER_DASHBOARD_PAGE_LISTING_COMPONENT_FETCH_LISTINGS
			),
			map(() => {
				return new coreListingsActions.ClubApiGetUserListings();
			})
		);
	});

	// Create listing via API
	getListings$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreListingsActions.CLUB_API_GET_USER_LISTINGS),
			switchMap(() => {
				return this.authListingService.getListings().pipe(
					map((listings) => {
						const normalizedData = normalize(listings, [
							fromSchema.authListing
						]);
						return new coreListingsActions.ClubApiGetUserListingsSuccess(
							normalizedData
						);
					}),
					catchError((error) => {
						return of(
							new coreListingsActions.ClubApiGetUserListingsFail(
								error
							)
						);
					})
				);
			})
		);
	});

	// Get Listing by Route UUID
	fetchListingByRouteID$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreListingsActions.USER_LISTING_PROFILE_PAGE_FETCH_LISTING),
			withLatestFrom(
				this.store.select(fromRoot.getRouterState),
				(action, router) => {
					return router.state.params.uuid;
				}
			),
			map((UUID) => {
				return new coreListingsActions.ClubApiGetUserListing(UUID);
			})
		);
	});

	// Get Listing from API
	getListingByUUID$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreListingsActions.CLUB_API_GET_USER_LISTING),
			switchMap((action: coreListingsActions.ClubApiGetUserListing) => {
				return this.authListingService.getListing(action.payload).pipe(
					map((listing) => {
						const normalizedData = normalize(
							listing,
							fromSchema.authListing
						);
						return new coreListingsActions.ClubApiGetUserListingSuccess(
							normalizedData
						);
					}),
					catchError((error) => {
						return of(
							new coreListingsActions.ClubApiGetUserListingFail(
								error
							)
						);
					})
				);
			})
		);
	});

	// Get Listing Stats by Route UUID
	fetchListingStatsByRouteID$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				coreListingsActions.USER_LISTING_PROFILE_PAGE_FETCH_LISTING_STATS
			),
			withLatestFrom(
				this.store.select(fromRoot.getRouterState),
				(
					action: coreListingsActions.UserListingProfilePageFetchListingStats,
					router
				) => {
					return {
						req: action.payload,
						UUID: router.state.params.uuid
					};
				}
			),
			map((newResponse) => {
				return new coreListingsActions.ClubApiGetUserListingStats(
					newResponse
				);
			})
		);
	});

	// Get Listing Stats from API
	getListingStatsByUUID$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreListingsActions.CLUB_API_GET_USER_LISTING_STATS),
			switchMap(
				(action: coreListingsActions.ClubApiGetUserListingStats) => {
					return this.authListingService
						.getListingStats(
							action.payload.UUID,
							action.payload.req
						)
						.pipe(
							map((stats) => {
								return new coreListingsActions.ClubApiGetUserListingStatsSuccess(
									{
										req: action.payload.req,
										res: stats
									}
								);
							}),
							catchError((error) => {
								return of(
									new coreListingsActions.ClubApiGetUserListingStatsFail(
										error
									)
								);
							})
						);
				}
			)
		);
	});

	// Delete Listing by Route UUID
	deleteListingByRouteID$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				coreListingsActions.USER_LISTING_PROFILE_PAGE_DIALOG_DELETE_LISTING
			),
			withLatestFrom(
				this.store.select(fromRoot.getRouterState),
				(action, router) => {
					return router.state.params.uuid;
				}
			),
			map((UUID) => {
				return new coreListingsActions.ClubApiDeleteUserListing(UUID);
			})
		);
	});

	// Delete Listing from API
	deleteListingByUUID$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreListingsActions.CLUB_API_DELETE_USER_LISTING),
			switchMap(
				(action: coreListingsActions.ClubApiDeleteUserListing) => {
					return this.authListingService
						.deleteListing(action.payload)
						.pipe(
							map(() => {
								return new coreListingsActions.ClubApiDeleteUserListingSuccess();
							}),
							catchError((error) => {
								return of(
									new coreListingsActions.ClubApiDeleteUserListingFail(
										error
									)
								);
							})
						);
				}
			)
		);
	});

	deleteListingSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(
					coreListingsActions.CLUB_API_DELETE_USER_LISTING_SUCCESS
				),
				tap(() => {
					this.snackbarService.addMessage(
						this.translocoService.translate(
							'snackbar.listingDeleteSuccess'
						),
						'action',
						'success'
					);
					this.router.navigate([`/user/dashboard`]);
				})
			),
		{ dispatch: false }
	);

	// Create Listing
	createListing$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreListingsActions.CREATE_LISTING_PAGE_CREATE_LISTING),
			map(
				(
					action: coreListingsActions.CreateListingPageCreateListing
				) => {
					return new coreListingsActions.ClubApiPostCreateListing(
						action.payload
					);
				}
			)
		);
	});

	// Create listing via API
	postCreateListing$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreListingsActions.CLUB_API_POST_CREATE_LISTING),
			switchMap(
				(action: coreListingsActions.ClubApiPostCreateListing) => {
					return this.authListingService
						.createListing(action.payload)
						.pipe(
							map((listing) => {
								const normalizedData = normalize(
									listing,
									fromSchema.authListing
								);
								return new coreListingsActions.ClubApiPostCreateListingSuccess(
									normalizedData
								);
							}),
							catchError((error) => {
								return of(
									new coreListingsActions.ClubApiPostCreateListingFail(
										error
									)
								);
							})
						);
				}
			)
		);
	});

	postCreateListingSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(
					coreListingsActions.CLUB_API_POST_CREATE_LISTING_SUCCESS
				),
				tap(() => {
					this.snackbarService.addMessage(
						this.translocoService.translate(
							'snackbar.listingPostSuccess'
						),
						'action',
						'success'
					);
					this.router.navigate([`/user/dashboard`]);
				})
			),
		{ dispatch: false }
	);

	/* 
	// Update Listing
	updateListing$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				coreListingsActions.USER_LISTING_PROFILE_EDIT_PAGE_UPDATE_LISTING
			),
			map(
				(
					action: coreListingsActions.UserListingProfileEditPageUpdateListing
				) => {
					return new coreListingsActions.ClubApiPutUpdateListing(
						action.payload
					);
				}
			)
		);
	});

	// Update listing via API
	putUpdateListing$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreListingsActions.CLUB_API_PUT_UPDATE_LISTING),
			switchMap((action: coreListingsActions.ClubApiPutUpdateListing) => {
				return this.authListingService.updateListing(action.payload).pipe(
					map((listing) => {
						const normalizedData = normalize(
							listing,
							fromSchema.listing
						);
						return new coreListingsActions.ClubApiPutUpdateListingSuccess(
							normalizedData
						);
					}),
					catchError((error) => {
						return of(
							new coreListingsActions.ClubApiPutUpdateListingFail(
								error
							)
						);
					})
				);
			})
		);
	});
 */
}
