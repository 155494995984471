import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { of } from 'rxjs';
import {
	catchError,
	map,
	switchMap,
	tap,
	withLatestFrom
} from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { Store } from '@ngrx/store';
import * as coreEscortsActions from '../actions/escorts.action';
import * as fromCore from '../reducers';
import * as fromRoot from '../../../store';

import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import {
	AuthEscortHandleValidationService,
	AuthEscortService
} from 'src/library/features/services';

import { normalize } from 'normalizr';
import * as fromSchema from 'src/library/features/models/schema.model';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class EscortsEffects {
	constructor(
		private authEscortHandleValidationService: AuthEscortHandleValidationService,
		private authEscortService: AuthEscortService,
		private actions$: Actions,
		private store: Store<fromRoot.State>,
		private snackbarService: SnackbarService,
		private translocoService: TranslocoService,
		private router: Router
	) {}

	// Check valid handle
	checkValidHandle$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				coreEscortsActions.USER_ESCORT_REGISTRATION_PAGE_VALIDATE_HANDLE
			),
			map(
				(
					action: coreEscortsActions.UserEscortRegistrationPageValidateHandle
				) => {
					return new coreEscortsActions.ClubApiPostHandleValidation(
						action.payload
					);
				}
			)
		);
	});

	// Check valid handle Request via API
	postCheckValidHandle$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreEscortsActions.CLUB_API_POST_HANDLE_VALIDATION),
			switchMap(
				(action: coreEscortsActions.ClubApiPostHandleValidation) => {
					return this.authEscortHandleValidationService
						.checkValidEscortHandle(action.payload)
						.pipe(
							map(() => {
								return new coreEscortsActions.ClubApiPostHandleValidationSuccess();
							}),
							catchError((error) => {
								return of(
									new coreEscortsActions.ClubApiPostHandleValidationFail(
										error
									)
								);
							})
						);
				}
			)
		);
	});

	// Create Escort
	createEscort$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				coreEscortsActions.USER_ESCORT_REGISTRATION_PAGE_CREATE_ESCORT
			),
			map(
				(
					action: coreEscortsActions.UserEscortRegistrationPageCreateEscort
				) => {
					return new coreEscortsActions.ClubApiPostCreateEscort(
						action.payload
					);
				}
			)
		);
	});

	// Create escort via API
	postCreateEscort$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreEscortsActions.CLUB_API_POST_CREATE_ESCORT),
			switchMap((action: coreEscortsActions.ClubApiPostCreateEscort) => {
				return this.authEscortService.createEscort(action.payload).pipe(
					map((escort) => {
						const normalizedData = normalize(
							escort,
							fromSchema.escort
						);
						return new coreEscortsActions.ClubApiPostCreateEscortSuccess(
							normalizedData
						);
					}),
					catchError((error) => {
						return of(
							new coreEscortsActions.ClubApiPostCreateEscortFail(
								error
							)
						);
					})
				);
			})
		);
	});

	// Update Escort
	updateEscort$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreEscortsActions.USER_PROFILE_EDIT_PAGE_UPDATE_ESCORT),
			map(
				(
					action: coreEscortsActions.UserProfileEditPageUpdateEscort
				) => {
					return new coreEscortsActions.ClubApiPutUpdateEscort(
						action.payload
					);
				}
			)
		);
	});

	// Update escort via API
	putUpdateEscort$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreEscortsActions.CLUB_API_PUT_UPDATE_ESCORT),
			switchMap((action: coreEscortsActions.ClubApiPutUpdateEscort) => {
				return this.authEscortService.updateEscort(action.payload).pipe(
					map((escort) => {
						const normalizedData = normalize(
							escort,
							fromSchema.escort
						);
						return new coreEscortsActions.ClubApiPutUpdateEscortSuccess(
							normalizedData
						);
					}),
					catchError((error) => {
						return of(
							new coreEscortsActions.ClubApiPutUpdateEscortFail(
								error
							)
						);
					})
				);
			})
		);
	});

	// Update escort via API success
	postUpdateEscortSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(coreEscortsActions.CLUB_API_PUT_UPDATE_ESCORT_SUCCESS),
				map(() => {
					this.snackbarService.addMessage(
						this.translocoService.translate(
							'snackbar.escortProfileUpdateSuccess'
						),
						'action',
						'success'
					);
					this.router.navigate([`/user/dashboard`]);
				})
			),
		{ dispatch: false }
	);

	// Verify Escort
	verifyEscortRequest$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				coreEscortsActions.USER_ESCORT_PROFILE_PAGE_VERIFY_ESCORT,
				coreEscortsActions.USER_ESCORT_REGISTRATION_PAGE_VERIFY_ESCORT
			),
			map(
				(
					action:
						| coreEscortsActions.UserEscortRegistrationPageVerifyEscort
						| coreEscortsActions.UserEscortProfilePageVerifyEscort
				) => {
					return new coreEscortsActions.ClubApiPostEscortVerification(
						action.payload
					);
				}
			)
		);
	});

	// Verify escort via API
	postVerifyEscortRequest$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreEscortsActions.CLUB_API_POST_ESCORT_VERIFICATION),
			switchMap(
				(action: coreEscortsActions.ClubApiPostEscortVerification) => {
					return this.authEscortService
						.verifyEscort(action.payload)
						.pipe(
							map(() => {
								return new coreEscortsActions.ClubApiPostEscortVerificationSuccess();
							}),
							catchError((error) => {
								return of(
									new coreEscortsActions.ClubApiPostEscortVerificationFail(
										error
									)
								);
							})
						);
				}
			)
		);
	});

	// Verify escort via API success redirect to next page
	postVerifyEscortRequestSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(
					coreEscortsActions.CLUB_API_POST_ESCORT_VERIFICATION_SUCCESS
				),
				withLatestFrom(
					this.store.select(fromRoot.getRouterState),
					(
						action: coreEscortsActions.ClubApiPostEscortVerificationSuccess,
						router
					) => {
						return router;
					}
				),
				map((route) => {
					this.snackbarService.addMessage(
						this.translocoService.translate(
							'snackbar.escortVerificationSubmitSuccess'
						),
						'action',
						'success'
					);
					if (route.state.url === '/user/escort-registration') {
						this.router.navigate([`/user/dashboard`]);
					} else {
						this.router.navigate([`/user/dashboard`]);
					}
				})
			),
		{ dispatch: false }
	);
}
