import * as fromReports from '../actions/reports.action';

import { LoadingState, ErrorState, CallState, getError } from './call-state';
;

export interface ReportsState {
	callState: CallState;
}

export const initialState: ReportsState = {
	callState: LoadingState.INIT
};

export function reducer(
	state = initialState,
	action: fromReports.ReportsAction
): ReportsState {
	switch (action.type) {
		case fromReports.LISTING_REPORT_DIALOG_INITALISE: {
			return {
				...initialState
			}
		}

		case fromReports.CLUB_API_POST_REPORT: {
			return {
				...state,
				callState: LoadingState.LOADING
			};
		}

		case fromReports.CLUB_API_POST_REPORT_SUCCESS: {
			return {
				...state,
				callState: LoadingState.LOADED,
			};
		}

		case fromReports.CLUB_API_POST_REPORT_FAIL: {
			return {
				...state,
				callState: { errorMessage: action.errorMessage }
			};
		}

		default:
			return state;
	}
}

export const getReportLoading 		= (state: ReportsState) => state.callState === LoadingState.LOADING;
export const getReportLoaded 		= (state: ReportsState) => state.callState === LoadingState.LOADED;
export const getReportError 		= (state: ReportsState) => getError(state.callState);
