import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
	catchError,
	concatMap,
	concatMapTo,
	map,
	mergeMap,
	switchMap,
	withLatestFrom
} from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import { Store } from '@ngrx/store';

import * as listingActions from '../actions/listings.action';
import * as homeActions from 'src/library/ui/store/actions/home.action';
import * as listingSearchActions from 'src/library/ui/store/actions/listing-search.action';
import * as fromServices from '../../services';
import * as fromRoot from 'src/app/store';
import * as fromUI from 'src/library/ui/store';

import { normalize } from 'normalizr';
import * as fromSchema from '../../models/schema.model';
import { PaginatedResponse } from '../../services';
import { Listing } from '../../models';

@Injectable()
export class ListingsEffects {
	constructor(
		private actions$: Actions,
		private listingsService: fromServices.ListingsService,
		private UIStore: Store<fromUI.UIState>,
		private store: Store<fromRoot.State>,
		private router: Router
	) {}

	// Fetch latest listings from Home Page
	homePageFetchLatestListings$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(listingActions.HOME_PAGE_FETCH_LATEST_LISTINGS),
			withLatestFrom(
				this.UIStore.select(fromUI.getHomeState),
				(action: listingActions.HomePageFetchLatestListings, ui) => {
					return ui.listingPage;
				}
			),
			map(
				(listingPageNumber: number) =>
					new listingActions.ClubApiGetLatestListings(
						listingPageNumber
					)
			)
		);
	});

	// Get Latest Listings from API
	getLatestListings$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(listingActions.CLUB_API_GET_LATEST_LISTINGS),
			switchMap((action: listingActions.ClubApiGetLatestListings) => {
				return this.listingsService
					.getLatestListings(action.payload)
					.pipe(
						map(
							(
								paginatedListing: PaginatedResponse<Listing[]>
							) => {
								// console.log(paginatedListing.data);
								const listings = paginatedListing.data;
								const normalizedData = normalize(listings, [
									fromSchema.listing
								]);
								return new listingActions.ClubApiGetLatestListingsSuccess(
									normalizedData
								);
							}
						),
						catchError((error) =>
							of(
								new listingActions.ClubApiGetLatestListingsFail(
									error
								)
							)
						)
					);
			})
		);
	});

	// Stops incrementing page fetching if there are no more results
	getListingsSuccessUpdatePageNumber$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(listingActions.CLUB_API_GET_LATEST_LISTINGS_SUCCESS),
			map((actions: listingActions.ClubApiGetLatestListingsSuccess) => {
				if (actions.payload?.result?.length !== 0) {
					return new homeActions.HomePageIncrementListingPage();
				} else {
					return new homeActions.HomePageIncrementListingPageFail();
				}
			})
		);
	});

	// Get Listing by Route UUID
	fetchListingByRouteID$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(listingActions.LISTING_PROFILE_PAGE_FETCH_LISTING),
			withLatestFrom(
				this.store.select(fromRoot.getRouterState),
				(action, router) => {
					return router.state.params.uuid;
				}
			),
			map((UUID) => {
				return new listingActions.ClubApiGetListing(UUID);
			})
		);
	});

	// Get Listing from API
	getListingByUUID$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(listingActions.CLUB_API_GET_LISTING),
			switchMap((action: listingActions.ClubApiGetListing) => {
				return this.listingsService.getListing(action.payload).pipe(
					map((listing) => {
						const normalizedData = normalize(
							listing,
							fromSchema.listing
						);
						return new listingActions.ClubApiGetListingSuccess(
							normalizedData
						);
					}),
					catchError((error) => {
						return of(
							new listingActions.ClubApiGetListingFail(error)
						);
					})
				);
			})
		);
	});

	// Fetch search listings from Search Listing Page
	listingSearchPageFetchListings$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(listingActions.LISTING_SEARCH_PAGE_FETCH_LISTINGS),
			withLatestFrom(
				this.store.select(fromRoot.getRouterState),
				this.UIStore.select(fromUI.getListingSearchState),
				(
					action: listingActions.ListingSearchPageFetchListings,
					router,
					ui
				) => {
					return {
						params: router.state.queryParams,
						route: router.state.url,
						page: ui.listingPage,
						storedURI: ui.URI
					};
				}
			),
			map(
				(payload: {
					params: Params;
					route: string;
					page: number;
					storedURI: string;
				}) => {
					const req = this.listingsService.convertURIToRequest(
						payload.params
					);
					// Check to see if current search route is the same as the stored route
					// If it is then fetch next page, else start new search query by resetting pageNumber
					const routeURI = payload.route.replace(
						'/listing/search?',
						''
					);
					if (routeURI !== payload.storedURI) {
						return new listingSearchActions.ListingSearchPageSearchParamsChanged(
							{
								req,
								page: 1
							}
						);
					} else {
						return new listingSearchActions.ListingSearchPageSearchParamsUnchanged(
							{
								req,
								page: payload.page
							}
						);
					}
				}
			)
		);
	});

	// If the listing search params have changed to reset page number
	listingSearchParamsChangedResetPageState$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				listingSearchActions.LISTING_SEARCH_PAGE_SEARCH_PARAMS_CHANGED
			),
			map(
				() =>
					new listingSearchActions.ListingSearchPageResetListingPage()
			)
		);
	});

	// Then post API request with new pagination
	listingSearchPagePostSearchWithPagination$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				listingSearchActions.LISTING_SEARCH_PAGE_SEARCH_PARAMS_CHANGED,
				listingSearchActions.LISTING_SEARCH_PAGE_SEARCH_PARAMS_UNCHANGED
			),
			map(
				(action: any) =>
					new listingActions.ClubApiPostSearchListings(action.payload)
			)
		);
	});

	// Post Search Listings from API
	postSearchListings$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(listingActions.CLUB_API_POST_SEARCH_LISTINGS),
			switchMap((action: listingActions.ClubApiPostSearchListings) => {
				return this.listingsService
					.postSearchListings(action.payload.req, action.payload.page)
					.pipe(
						map(
							(
								paginatedListing: PaginatedResponse<Listing[]>
							) => {
								return new listingActions.ClubApiPostSearchListingsResponse(
									paginatedListing
								);
							}
						),
						catchError((error) =>
							of(
								new listingActions.ClubApiPostSearchListingsFail(
									error
								)
							)
						)
					);
			})
		);
	});

	// Convert API response to store in listing
	postSearchListingSuccessTotalResponse$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(listingActions.CLUB_API_POST_SEARCH_LISTINGS_RESPONSE),
			map((action: listingActions.ClubApiPostSearchListingsResponse) => {
				const totalListings = action.payload.meta?.total;

				return new listingSearchActions.ListingSearchPageGetTotalListings(
					totalListings
				);
			})
		);
	});

	// Convert API response to store in listing
	postSearchListingSuccessListingResponse$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(listingActions.CLUB_API_POST_SEARCH_LISTINGS_RESPONSE),
			map((action: listingActions.ClubApiPostSearchListingsResponse) => {
				const listings = action.payload.data;
				const normalizedData = normalize(listings, [
					fromSchema.listing
				]);
				return new listingActions.ClubApiPostSearchListingsSuccess(
					normalizedData
				);
			})
		);
	});

	postSearchListingsSuccessUpdatePageNumber$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(listingActions.CLUB_API_POST_SEARCH_LISTINGS_SUCCESS),
			map(
				() =>
					new listingSearchActions.ListingSearchPageIncrementListingPage()
			)
		);
	});
}
