import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
	catchError,
	filter,
	map,
	switchMap,
	tap,
	withLatestFrom
} from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import { Store } from '@ngrx/store';

import * as escortTagsAction from '../actions/escort-tags.action';
import * as fromServices from '../../services';
import * as fromRoot from 'src/app/store';

import { normalize } from 'normalizr';
import * as fromSchema from '../../models/schema.model';

@Injectable()
export class EscortTagsEffects {
	constructor(
		private actions$: Actions,
		private escortTagsService: fromServices.EscortTagsService,
		private store: Store<fromRoot.State>
	) {}

	// Fetch Ethnicities
	fetchEthnicities$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				escortTagsAction.ESCORT_ETHNICITY_SELECTOR_COMPONENT_GET_ETHNICITIES
			),
			map(
				(
					action: escortTagsAction.EscortEthnicitySelectorComponentGetEthnicities
				) => {
					return new escortTagsAction.ClubApiGetEscortTagEthnicities();
				}
			)
		);
	});

	// get Ethnicity Tags from API
	getEthnicityTags$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(escortTagsAction.CLUB_API_GET_ESCORT_TAG_ETHNICITIES),
			switchMap(
				(action: escortTagsAction.ClubApiGetEscortTagEthnicities) => {
					return this.escortTagsService.getEthnicities().pipe(
						map((tags) => {
							return new escortTagsAction.ClubApiGetEscortTagEthnicitiesSuccess(
								tags
							);
						}),
						catchError((error) => {
							return of(
								new escortTagsAction.ClubApiGetEscortTagEthnicitiesFail(
									error
								)
							);
						})
					);
				}
			)
		);
	});

	// Fetch Hair Colours
	fetchHairColours$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				escortTagsAction.ESCORT_HAIR_COLOUR_SELECTOR_COMPONENT_GET_HAIR_COLOURS
			),
			map(
				(
					action: escortTagsAction.EscortHairColourSelectorComponentGetHairColours
				) => {
					return new escortTagsAction.ClubApiGetEscortTagHairColours();
				}
			)
		);
	});

	// get HairColour Tags from API
	getHairColourTags$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(escortTagsAction.CLUB_API_GET_ESCORT_TAG_HAIR_COLOURS),
			switchMap(
				(action: escortTagsAction.ClubApiGetEscortTagHairColours) => {
					return this.escortTagsService.getHairColours().pipe(
						map((tags) => {
							return new escortTagsAction.ClubApiGetEscortTagHairColoursSuccess(
								tags
							);
						}),
						catchError((error) => {
							return of(
								new escortTagsAction.ClubApiGetEscortTagHairColoursFail(
									error
								)
							);
						})
					);
				}
			)
		);
	});

	// Fetch Sexual Preferences
	fetchEyeColours$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				escortTagsAction.ESCORT_EYE_COLOUR_SELECTOR_COMPONENT_GET_EYE_COLOURS
			),
			map(
				(
					action: escortTagsAction.EscortEyeColourSelectorComponentGetEyeColours
				) => {
					return new escortTagsAction.ClubApiGetEscortTagEyeColours();
				}
			)
		);
	});

	// get EyeColour Tags from API
	getEyeColourTags$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(escortTagsAction.CLUB_API_GET_ESCORT_TAG_EYE_COLOURS),
			switchMap(
				(action: escortTagsAction.ClubApiGetEscortTagEyeColours) => {
					return this.escortTagsService.getEyeColours().pipe(
						map((tags) => {
							return new escortTagsAction.ClubApiGetEscortTagEyeColoursSuccess(
								tags
							);
						}),
						catchError((error) => {
							return of(
								new escortTagsAction.ClubApiGetEscortTagEyeColoursFail(
									error
								)
							);
						})
					);
				}
			)
		);
	});

	// Fetch Genders
	fetchGenders$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				escortTagsAction.ESCORT_GENDER_SELECTOR_COMPONENT_GET_GENDERS
			),
			map(
				(
					action: escortTagsAction.EscortGenderSelectorComponentGetGenders
				) => {
					return new escortTagsAction.ClubApiGetEscortTagGenders();
				}
			)
		);
	});

	// get Gender Tags from API
	getGenderTags$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(escortTagsAction.CLUB_API_GET_ESCORT_TAG_GENDERS),
			switchMap((action: escortTagsAction.ClubApiGetEscortTagGenders) => {
				return this.escortTagsService.getGenders().pipe(
					map((tags) => {
						return new escortTagsAction.ClubApiGetEscortTagGendersSuccess(
							tags
						);
					}),
					catchError((error) => {
						return of(
							new escortTagsAction.ClubApiGetEscortTagGendersFail(
								error
							)
						);
					})
				);
			})
		);
	});

	// Fetch Sexual Preferences
	fetchSexualPreferences$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				escortTagsAction.ESCORT_SEXUAL_PREFERENCE_SELECTOR_COMPONENT_GET_SEXUAL_PREFERENCES
			),
			map(
				(
					action: escortTagsAction.EscortSexualPreferenceSelectorComponentGetSexualPreferences
				) => {
					return new escortTagsAction.ClubApiGetEscortTagSexualPreferences();
				}
			)
		);
	});

	// get SexualPreference Tags from API
	getSexualPreferenceTags$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(escortTagsAction.CLUB_API_GET_ESCORT_TAG_SEXUAL_PREFERENCES),
			switchMap(
				(
					action: escortTagsAction.ClubApiGetEscortTagSexualPreferences
				) => {
					return this.escortTagsService.getSexualPreferences().pipe(
						map((tags) => {
							return new escortTagsAction.ClubApiGetEscortTagSexualPreferencesSuccess(
								tags
							);
						}),
						catchError((error) => {
							return of(
								new escortTagsAction.ClubApiGetEscortTagSexualPreferencesFail(
									error
								)
							);
						})
					);
				}
			)
		);
	});

	// Fetch Body Types
	fetchBodyTypes$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				escortTagsAction.ESCORT_BODY_TYPE_SELECTOR_COMPONENT_GET_BODY_TYPES
			),
			map(
				(
					action: escortTagsAction.EscortBodyTypeSelectorComponentGetBodyTypes
				) => {
					return new escortTagsAction.ClubApiGetEscortTagBodyTypes();
				}
			)
		);
	});

	// get BodyType Tags from API
	getBodyTypeTags$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(escortTagsAction.CLUB_API_GET_ESCORT_TAG_BODY_TYPES),
			switchMap(
				(action: escortTagsAction.ClubApiGetEscortTagBodyTypes) => {
					return this.escortTagsService.getBodyTypes().pipe(
						map((tags) => {
							return new escortTagsAction.ClubApiGetEscortTagBodyTypesSuccess(
								tags
							);
						}),
						catchError((error) => {
							return of(
								new escortTagsAction.ClubApiGetEscortTagBodyTypesFail(
									error
								)
							);
						})
					);
				}
			)
		);
	});

	// Fetch Spoken Language
	fetchSpokenLanguages$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				escortTagsAction.ESCORT_SPOKEN_LANGUAGE_SELECTOR_COMPONENT_GET_SPOKEN_LANGUAGES
			),
			map(
				(
					action: escortTagsAction.EscortSpokenLanguageSelectorComponentGetSpokenLanguages
				) => {
					return new escortTagsAction.ClubApiGetEscortTagSpokenLanguages();
				}
			)
		);
	});

	// get Spoken Language Tags from API
	getSpokenLanguageTags$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(escortTagsAction.CLUB_API_GET_ESCORT_TAG_SPOKEN_LANGUAGES),
			switchMap(
				(
					action: escortTagsAction.ClubApiGetEscortTagSpokenLanguages
				) => {
					return this.escortTagsService.getSpokenLanguages().pipe(
						map((tags) => {
							return new escortTagsAction.ClubApiGetEscortTagSpokenLanguagesSuccess(
								tags
							);
						}),
						catchError((error) => {
							return of(
								new escortTagsAction.ClubApiGetEscortTagSpokenLanguagesFail(
									error
								)
							);
						})
					);
				}
			)
		);
	});

	// Fetch Plays
	fetchPlays$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(escortTagsAction.ESCORT_PLAY_SELECTOR_COMPONENT_GET_PLAYS),
			map(
				(
					action: escortTagsAction.EscortPlaySelectorComponentGetPlays
				) => {
					return new escortTagsAction.ClubApiGetEscortTagPlays();
				}
			)
		);
	});

	// get Plays Tags from API
	getPlayTags$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(escortTagsAction.CLUB_API_GET_ESCORT_TAG_PLAYS),
			switchMap((action: escortTagsAction.ClubApiGetEscortTagPlays) => {
				return this.escortTagsService.getPlays().pipe(
					map((tags) => {
						return new escortTagsAction.ClubApiGetEscortTagPlaysSuccess(
							tags
						);
					}),
					catchError((error) => {
						return of(
							new escortTagsAction.ClubApiGetEscortTagPlaysFail(
								error
							)
						);
					})
				);
			})
		);
	});
}
