import { createSelector } from '@ngrx/store';

import * as fromRoot from '../../../../app/store';
import * as fromCore from '../reducers';
import * as fromCoreEscorts from '../reducers/escorts.reducer';

import { Escort } from 'src/library/features/models';
import { getMediaEntities } from './media.selectors';

export const getEscortState = createSelector(
	fromCore.getCoreState,
	(state: fromCore.CoreState) => state.escorts
);

export const getEscortsEntities = createSelector(
	getEscortState,
	fromCoreEscorts.getEscortsEntities
);

// Validate Handle API call
export const getHandleValidationLoading = createSelector(
	getEscortState,
	fromCoreEscorts.getHandleValidationLoading
);

export const getHandleValidationLoaded = createSelector(
	getEscortState,
	fromCoreEscorts.getHandleValidationLoaded
);

export const getHandleValidationError = createSelector(
	getEscortState,
	fromCoreEscorts.getHandleValidationError
);

// Create Escort API call
export const getCreateEscortLoading = createSelector(
	getEscortState,
	fromCoreEscorts.getCreateEscortLoading
);

export const getCreateEscortLoaded = createSelector(
	getEscortState,
	fromCoreEscorts.getCreateEscortLoaded
);

export const getCreateEscortError = createSelector(
	getEscortState,
	fromCoreEscorts.getCreateEscortError
);

// Update Escort API call
export const getUpdateEscortLoading = createSelector(
	getEscortState,
	fromCoreEscorts.getUpdateEscortLoading
);

export const getUpdateEscortLoaded = createSelector(
	getEscortState,
	fromCoreEscorts.getUpdateEscortLoaded
);

export const getUpdateEscortError = createSelector(
	getEscortState,
	fromCoreEscorts.getUpdateEscortError
);

// Escort Verification API call
export const getEscortVerificationLoading = createSelector(
	getEscortState,
	fromCoreEscorts.getEscortVerificationLoading
);

export const getEscortVerificationLoaded = createSelector(
	getEscortState,
	fromCoreEscorts.getEscortVerificationLoaded
);

export const getEscortVerificationError = createSelector(
	getEscortState,
	fromCoreEscorts.getEscortVerificationError
);

// Get Escort List

export const getEscortsList = createSelector(getEscortsEntities, (entities) =>
	Object.keys(entities).map((key) => entities[key])
);

export const getEscortByRouteHandle = createSelector(
	getEscortsEntities,
	fromRoot.getRouterState,
	(escorts, router): Escort =>
		router.state && escorts[router.state.params.handle]
);

// Get Denormalized escort by Route Handle
export const getEscortByRouteHandleView = createSelector(
	getEscortByRouteHandle,
	getMediaEntities,
	getEscortsEntities,
	(escort: Escort, medias): Escort => {
		return (
			escort && {
				...escort,
				avatar: medias[escort.avatar as string],
				banner: medias[escort.banner as string],
				galleries: escort.galleries.map((gallery) => {
					return (
						gallery && {
							...gallery,
							media: (gallery.media as string[]).map(
								(galleryMedia) => medias[galleryMedia]
							),
							thumbnail: medias[gallery.thumbnail as string]
						}
					);
				})
			}
		);
	}
);
