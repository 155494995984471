import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as reportActions from '../actions/reports.action';
import * as fromServices from '../../services';

@Injectable()
export class ReportsEffects {
	constructor(
		private actions$: Actions,
		private reportService: fromServices.ReportService
	) {}

	reportListingDialogReport$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(reportActions.LISTING_REPORT_DIALOG_REPORT_LISTING),
			map(
				(action: reportActions.ListingReportDialogReportListing) =>
					new reportActions.ClubApiPostReport(action.payload)
			)
		);
	});

	// Post Report
	postReportListing$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(reportActions.CLUB_API_POST_REPORT),
			switchMap((action: reportActions.ClubApiPostReport) => {
				return this.reportService.postReport(action.payload).pipe(
					map(() => {
						return new reportActions.ClubApiPostReportSuccess();
					}),
					catchError((error) => {
						return of(
							new reportActions.ClubApiPostReportFail(error)
						);
					})
				);
			})
		);
	});
}
