import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface ClubAPIAuthResponse {
	two_factor?: string;
	error?: string;
}

@Injectable({
	providedIn: 'root'
})
export class ClubAPIAuthResponseService {
	static validateResponse<T>(response: HttpResponse<T>): any {
		// Check if any response is returned
		if (response) {
			// If other response codes

			// Otherwise if response is successful, to return response body
			if (response.status === 200 || response.status === 204) {
				return response.body;
			}
		} else {
			throw this.newError('N/A', 400, 'No Response Error');
		}
	}

	private static newError(url: string, status = 400, statusText: string) {
		const errorResponse = new HttpErrorResponse({
			url,
			status,
			statusText
		});
		return errorResponse;
	}
}
