import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromUI from 'src/library/ui/store';
import * as globalActions from 'src/library/ui/store/actions/global.action';
import { LanguageTypeValue } from 'src/library/features/models';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { CookieWrapperService } from 'src/app/core/services';

@Injectable()
export class AcceptLanguageInterceptor implements HttpInterceptor {
	constructor(
		private store: Store<fromUI.UIState>,
		private cookieStorage: CookieWrapperService,
	) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		return this.language$().pipe(
			switchMap((language) => {
				request = request.clone({
					setHeaders: {
						'Accept-Language': language
					}
				});
				return next.handle(request);
			})
		);
	}

	language$(): Observable<LanguageTypeValue> {
		// Check if there is language set in UI store
		return this.store.select(fromUI.getGlobalLanguageType).pipe(
			map((language) => {
				// If there is no stored language setting, attempt fetch from cookie
				if (language === null) {
					const cookieLanguage = this.cookieStorage.getGlobalLanguage();

					// If cookie exists, to set UI store language as cookie, else set as en
					const newLanguage: LanguageTypeValue = cookieLanguage
						? cookieLanguage
						: 'en';
					this.store.dispatch(
						new globalActions.AcceptLanguageInterceptorSetLanguage(
							newLanguage
						)
					);
					return newLanguage;
				} else {
					return language;
				}
			}),
			skipWhile((language) => !language), // Wait till language is fetched from store
			take(1)
		);
	}
}
