import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarComponent } from './snackbar.component';
import { SnackbarService } from './snackbar.service';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [SnackbarComponent],
	imports: [CommonModule, MatButtonModule],
	exports: [SnackbarComponent],
	providers: [SnackbarService]
})
export class SnackbarModule {}
