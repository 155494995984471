import { Action } from '@ngrx/store';
import { Error } from 'src/app/shared/services';
import { Listing, ListingStat } from 'src/library/features/models';
import { UserListingStatsRequest } from 'src/library/features/services/auth/listing.service';


export const USER_LISTING_PAGE_FETCH_LISTINGS                               = '[User Escort Listing Page] Fetch Listings'
export const USER_DASHBOARD_PAGE_LISTING_COMPONENT_FETCH_LISTINGS           = '[User Dashboard Page Listing Component] Fetch Listings'
export const CLUB_API_GET_USER_LISTINGS                                     = '[Club API] Get User Listings';
export const CLUB_API_GET_USER_LISTINGS_FAIL                                = '[Club API] Get User Listings Fail';
export const CLUB_API_GET_USER_LISTINGS_SUCCESS                             = '[Club API] Get User Listings Success';

export const USER_LISTING_PROFILE_PAGE_FETCH_LISTING                        = '[User Escort Listing Profile Page] Fetch Listing'
export const CLUB_API_GET_USER_LISTING                                      = '[Club API] Get User Listing'
export const CLUB_API_GET_USER_LISTING_FAIL                                 = '[Club API] Get User Listing Fail'
export const CLUB_API_GET_USER_LISTING_SUCCESS                              = '[Club API] Get User Listing Success'

export const USER_LISTING_PROFILE_PAGE_DIALOG_DELETE_LISTING                = '[User Escort Listing Profile Page Dialog] Delete Listing'
export const CLUB_API_DELETE_USER_LISTING                                   = '[Club API] Delete User Listing'
export const CLUB_API_DELETE_USER_LISTING_FAIL                              = '[Club API] Delete User Listing Fail'
export const CLUB_API_DELETE_USER_LISTING_SUCCESS                           = '[Club API] Delete User Listing Success'

export const USER_LISTING_PROFILE_PAGE_FETCH_LISTING_STATS                  = '[User Escort Listing Profile Page] Fetch Listing Stats'
export const CLUB_API_GET_USER_LISTING_STATS                                = '[Club API] Get User Listing Stats'
export const CLUB_API_GET_USER_LISTING_STATS_FAIL                           = '[Club API] Get User Listing Stats Fail'
export const CLUB_API_GET_USER_LISTING_STATS_SUCCESS                        = '[Club API] Get User Listing Stats Success'

export const CREATE_LISTING_PAGE_UNSET                                      = '[User Escort Create Listing Page] Unset'
export const CREATE_LISTING_PAGE_CREATE_LISTING                             = '[User Escort Create Listing Page] Create Listing'
export const CLUB_API_POST_CREATE_LISTING                                   = '[Club API] Post Create Listing';
export const CLUB_API_POST_CREATE_LISTING_FAIL                              = '[Club API] Post Create Listing Fail';
export const CLUB_API_POST_CREATE_LISTING_SUCCESS                           = '[Club API] Post Create Listing Success';



export class UserListingPageFetchListings implements Action {
    readonly type = USER_LISTING_PAGE_FETCH_LISTINGS;
    constructor() { }
}
export class UserDashboardPageListingComponentFetchListings implements Action {
    readonly type = USER_DASHBOARD_PAGE_LISTING_COMPONENT_FETCH_LISTINGS;
    constructor() { }
}
export class ClubApiGetUserListings implements Action {
    readonly type = CLUB_API_GET_USER_LISTINGS;
    constructor() { }
}

export class ClubApiGetUserListingsFail implements Action {
    readonly type = CLUB_API_GET_USER_LISTINGS_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiGetUserListingsSuccess implements Action {
    readonly type = CLUB_API_GET_USER_LISTINGS_SUCCESS;
    constructor(public payload: any) {}
}

export class UserListingProfilePageFetchListing implements Action {
    readonly type = USER_LISTING_PROFILE_PAGE_FETCH_LISTING;
    constructor() {}
}

export class ClubApiGetUserListing implements Action {
    readonly type = CLUB_API_GET_USER_LISTING;
    constructor(public payload: string) { }
}

export class ClubApiGetUserListingFail implements Action {
    readonly type = CLUB_API_GET_USER_LISTING_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiGetUserListingSuccess implements Action {
    readonly type = CLUB_API_GET_USER_LISTING_SUCCESS;
    constructor(public payload: any) {}
}

export class UserListingProfilePageDialogDeleteListing implements Action {
    readonly type = USER_LISTING_PROFILE_PAGE_DIALOG_DELETE_LISTING;
    constructor() {}
}

export class ClubApiDeleteUserListing implements Action {
    readonly type = CLUB_API_DELETE_USER_LISTING;
    constructor(public payload: any) { }
}

export class ClubApiDeleteUserListingFail implements Action {
    readonly type = CLUB_API_DELETE_USER_LISTING_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiDeleteUserListingSuccess implements Action {
    readonly type = CLUB_API_DELETE_USER_LISTING_SUCCESS;
    constructor() {}
}

export class UserListingProfilePageFetchListingStats implements Action {
    readonly type = USER_LISTING_PROFILE_PAGE_FETCH_LISTING_STATS;
    constructor(public payload: UserListingStatsRequest) { }
}

export class ClubApiGetUserListingStats implements Action {
    readonly type = CLUB_API_GET_USER_LISTING_STATS;
    constructor(public payload: {req: UserListingStatsRequest, UUID: string}) { }
}

export class ClubApiGetUserListingStatsFail implements Action {
    readonly type = CLUB_API_GET_USER_LISTING_STATS_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiGetUserListingStatsSuccess implements Action {
    readonly type = CLUB_API_GET_USER_LISTING_STATS_SUCCESS;
    constructor(public payload: {req: UserListingStatsRequest, res: ListingStat[]}) {}
}


export class CreateListingPageUnset implements Action {
    readonly type = CREATE_LISTING_PAGE_UNSET;
    constructor() { }
}
export class CreateListingPageCreateListing implements Action {
    readonly type = CREATE_LISTING_PAGE_CREATE_LISTING;
    constructor(public payload?: Listing) { }
}
export class ClubApiPostCreateListing implements Action {
    readonly type = CLUB_API_POST_CREATE_LISTING;
    constructor(public payload?: Listing) { }}

export class ClubApiPostCreateListingFail implements Action {
    readonly type = CLUB_API_POST_CREATE_LISTING_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiPostCreateListingSuccess implements Action {
    readonly type = CLUB_API_POST_CREATE_LISTING_SUCCESS;
    constructor(public payload?: any) {}
}

// action types
export type ListingsAction =
    | UserListingPageFetchListings
    | UserDashboardPageListingComponentFetchListings
    | ClubApiGetUserListings
    | ClubApiGetUserListingsFail
    | ClubApiGetUserListingsSuccess
    | UserListingProfilePageFetchListing
    | ClubApiGetUserListing
    | ClubApiGetUserListingFail
    | ClubApiGetUserListingSuccess
    | UserListingProfilePageDialogDeleteListing
    | ClubApiDeleteUserListing
    | ClubApiDeleteUserListingFail
    | ClubApiDeleteUserListingSuccess
    | UserListingProfilePageFetchListingStats
    | ClubApiGetUserListingStats
    | ClubApiGetUserListingStatsFail
    | ClubApiGetUserListingStatsSuccess
    | CreateListingPageUnset
    | CreateListingPageCreateListing
    | ClubApiPostCreateListing
    | ClubApiPostCreateListingFail
    | ClubApiPostCreateListingSuccess
    ;
