import { Action } from '@ngrx/store';

export const HOME_PAGE_RESET_LISTING_PAGE                       = '[Home Page] Reset Listing Page';
export const HOME_PAGE_RESET_LISTING_PAGE_SUCCESS               = '[Home Page] Reset Listing Page Success';
export const HOME_PAGE_INCREMENT_LISTING_PAGE                   = '[Home Page] Increment Listing Page';
export const HOME_PAGE_INCREMENT_LISTING_PAGE_SUCCESS           = '[Home Page] Increment Listing Page Success';
export const HOME_PAGE_INCREMENT_LISTING_PAGE_FAIL              = '[Home Page] Increment Listing Page Fail';

export const HOME_PAGE_PUSH_NEW_LISTINGS_TO_TOP                 = '[Home Page] Push New Listings to Top';
export const HOME_PAGE_PUSH_NEW_LISTINGS_TO_QUEUE               = '[Home Page] Push New Listings to Queue';

export const HOME_PAGE_VIEW_NEW_LISTINGS_QUEUE                  = '[Home Page] View New Listings Queue';
export const HOME_PAGE_PUSH_NEW_LISTINGS_QUEUE                  = '[Home Page] Push New Listings Queue';
export const HOME_PAGE_RELOAD_PAGE                              = '[Home Page] Reload Page';

export class HomePageResetListingPage implements Action {
    readonly type = HOME_PAGE_RESET_LISTING_PAGE;
    constructor() { }
}

export class HomePageResetListinPageSuccess implements Action {
    readonly type = HOME_PAGE_RESET_LISTING_PAGE_SUCCESS;
    constructor(public payload: number) { }
}

export class HomePageIncrementListingPage implements Action {
    readonly type = HOME_PAGE_INCREMENT_LISTING_PAGE;
    constructor() { }
}

export class HomePageIncrementListingPageSuccess implements Action {
    readonly type = HOME_PAGE_INCREMENT_LISTING_PAGE_SUCCESS;
    constructor(public payload: number) { }
}

export class HomePageIncrementListingPageFail implements Action {
    readonly type = HOME_PAGE_INCREMENT_LISTING_PAGE_FAIL;
    constructor() { }
}

export class HomePagePushNewListingsToTop implements Action {
    readonly type = HOME_PAGE_PUSH_NEW_LISTINGS_TO_TOP;
    constructor(public payload: string) { }
}

export class HomePagePushNewListingsToQueue implements Action {
    readonly type = HOME_PAGE_PUSH_NEW_LISTINGS_TO_QUEUE;
    constructor(public payload: string) { }
}

export class HomePageViewNewListingsQueue implements Action {
    readonly type = HOME_PAGE_VIEW_NEW_LISTINGS_QUEUE;
    constructor() { }
}

export class HomePagePushNewListingsQueue implements Action {
    readonly type = HOME_PAGE_PUSH_NEW_LISTINGS_QUEUE;
    constructor() { }
}

export class HomePageReloadPage implements Action {
    readonly type = HOME_PAGE_RELOAD_PAGE;
    constructor() { }
}


// action types
export type HomeAction =
    | HomePageResetListingPage
    | HomePageResetListinPageSuccess
    | HomePageIncrementListingPage
    | HomePageIncrementListingPageSuccess
    | HomePageIncrementListingPageFail
    | HomePagePushNewListingsToTop
    | HomePagePushNewListingsToQueue
    | HomePageViewNewListingsQueue
    | HomePagePushNewListingsQueue
    | HomePageReloadPage
    ;
