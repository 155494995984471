import { createSelector } from '@ngrx/store';

import * as fromUI from '../reducers';
import * as fromUserNotification from '../reducers/user-notification.reducer';
import * as fromFeature from '../../../features/store/reducers';
import { getMediaEntities } from '../../../features/store/selectors/media.selectors';
import { Notification, Media } from 'src/library/features/models';
import { getNotificationsEntities } from 'src/app/core/store/selectors/notifications.selectors';

export const getUserNotificationState = createSelector(
	fromUI.getUIState,
	(state: fromUI.UIState) => state.userNotification
);

export const getUserNotificationPageNumber = createSelector(
	getUserNotificationState,
	fromUserNotification.getUserNotificationPage
);

export const getUserNotificationOrder = createSelector(
	getUserNotificationState,
	fromUserNotification.getUserNotificationOrder
);

export const getUserNotificationMoreNotificationsLeft = createSelector(
	getUserNotificationState,
	fromUserNotification.getUserNotificationMoreNotificationsLeft
);

export const getNotificationState = createSelector(
	fromFeature.getFeaturesState,
	(state: fromFeature.FeaturesState) => state.listings
);

export const getUserNotificationList = createSelector(
	getUserNotificationOrder,
	getNotificationsEntities,
	(order, entities) => order.map((uuid) => entities[uuid])
);

// Get Denormalized listings
export const getUserNotificationView = createSelector(
	getUserNotificationList,
	getMediaEntities,
	(notifications, media): Notification[] =>
		notifications.map(
			(notification) =>
				notification && {
					...notification,
					media: media[notification.media as string]
				}
		)
);