import { SkipSelf, Optional, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import * as CoreServices from './services';
import * as CoreGuards from './guards';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducers, effects } from './store';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		StoreModule.forFeature('core', reducers),
		EffectsModule.forFeature(effects)
	],
	providers: [CoreServices.services, CoreGuards.guards]
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		// Import guard
		if (parentModule) {
			throw new Error(
				`${parentModule} has already been loaded. Import Core module in the AppModule only.`
			);
		}
	}
}
