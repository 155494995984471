import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';

import * as listingSearchActions from '../actions/listing-search.action';
import * as fromUI from '..';
import * as fromRoot from 'src/app/store';

import { Store } from '@ngrx/store';

@Injectable()
export class ListingSearchEffects {
	constructor(
		private actions$: Actions,
		private store: Store<fromUI.UIState>
	) {}

	// Reset Listing Page Number to 1
	listingSearchPageResetListingPage$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(listingSearchActions.LISTING_SEARCH_PAGE_RESET_LISTING_PAGE),
			map(() => {
				return new listingSearchActions.ListingSearchPageResetListingPageSuccess(
					1
				);
			})
		);
	});

	// Increments ListingSearch Page Listing Page Number
	listingSearchPageIncrementListingPage$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				listingSearchActions.LISTING_SEARCH_PAGE_INCREMENT_LISTING_PAGE
			),
			withLatestFrom(
				this.store.select(fromRoot.getRouterState),
				this.store.select(fromUI.getListingSearchState),
				(
					action: listingSearchActions.ListingSearchPageIncrementListingPage,
					router,
					ui
				) => {
					return {
						route: router.state.url,
						page: ui.listingPage
					};
				}
			),
			map((payload: { route: string; page: number }) => {
				const routeURI = payload.route.replace('/listing/search?', '');
				const newListingPageNumber = payload.page + 1;
				return new listingSearchActions.ListingSearchPageIncrementListingPageSuccess(
					{ URI: routeURI, page: newListingPageNumber }
				);
			})
		);
	});
}
