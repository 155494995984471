import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, tap, withLatestFrom } from 'rxjs/operators';

import * as userNotificationActions from '../actions/user-notification.action';
import * as notificationActions from 'src/app/core/store/actions/notifications.action';

@Injectable()
export class UserNotificationEffects {
	constructor(private actions$: Actions) {}

	// Reset Notification page and count, then fetch notifications
	userNotificationComponentResetandFetchNotifications$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				userNotificationActions.HEADER_NOTIFICATION_COMPONENT_OPEN_COMPONENT
			),
			map(() => {
				return new notificationActions.HeaderNotificationComponentFetchNotifications();
			})
		);
	});

	// Update Acknowleged count and set all as acknowledged
	userNotificationComponentMarkAllAsAcknowledged$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				userNotificationActions.HEADER_NOTIFICATION_COMPONENT_OPEN_COMPONENT
			),
			map(() => {
				return new notificationActions.HeaderNotificationComponentMarkAllNotificationsAsAcknowledged();
			})
		);
	});
}
