import { VisitorAgreementGuard } from './visitor-agreement.guard';
import { AuthGuard } from './auth.guard';
import { NoAuthGuard } from './no-auth.guard';
import { UserEmailVerificationGuard } from './user-email-verification.guard';
import { EscortGuard } from './escort.guard';
import { NoEscortGuard } from './no-escort.guard';
import { EscortUnverifiedGuard } from './escort-unverified';

export const guards: any[] = [
	VisitorAgreementGuard,
	AuthGuard,
	NoAuthGuard,
	UserEmailVerificationGuard,
	EscortGuard,
	NoEscortGuard,
	EscortUnverifiedGuard
];

export * from './visitor-agreement.guard';
export * from './auth.guard';
export * from './no-auth.guard';
export * from './user-email-verification.guard';
export * from './escort.guard';
export * from './no-escort.guard';
export * from './escort-unverified';