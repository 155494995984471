import { Action } from '@ngrx/store';
import { Error } from 'src/app/shared/services';
import { User } from 'src/library/features/models';

import { LoginContext, ResetContext, ForgotPasswordContext, RegisterContext, UserEmailValidationContext, UserUsernameValidationContext } from '../../services';

export const LOGIN_PAGE_LOGIN_USER                                  = '[Login Page] Login User';
export const CLUB_API_POST_LOGIN_USER                               = '[Club API] Post Login User';
export const CLUB_API_POST_LOGIN_USER_FAIL                          = '[Club API] Post Login User Fail';
export const CLUB_API_POST_LOGIN_USER_SUCCESS                       = '[Club API] POst Login User Success';
export const LOGIN_PAGE_AUTH_SUCCESS_NAVIGATE_NEXT                  = '[Login Page] Authentication Success. Navigate to next route'

export const HEADER_COMPONENT_CHECK_LOGIN_STATUS                    = '[Header Component] Check User Login Status';
export const HEADER_COMPONENT_CHECK_LOGIN_TRUE                      = '[Header Component] Check User Login True';
export const HEADER_COMPONENT_CHECK_LOGIN_FALSE                     = '[Header Component] Check User Login False';
export const HEADER_COMPONENT_GET_LOGGED_IN_USER_DETAILS            = '[Header Component] Get Logged In User Details';
export const HEADER_COMPONENT_USER_DETAILS_EXISTS                   = '[Header Component] User Detail Exists';

export const AUTH_GUARD_CHECK_LOGIN_STATUS                          = '[Auth Guard] Check User Login Status';
export const AUTH_GUARD_CHECK_LOGIN_TRUE                            = '[Auth Guard] Check User Login True';
export const AUTH_GUARD_CHECK_LOGIN_FALSE                           = '[Auth Guard] Check User Login False';
export const AUTH_GUARD_GET_LOGGED_IN_USER_DETAILS                  = '[Auth Guard] Get Logged In User Details';

export const CLUB_API_GET_AUTH_USER_DETAILS                         = '[Club API] Get Auth User Details';
export const CLUB_API_GET_AUTH_USER_DETAILS_FAIL                    = '[Club API] Get Auth User Details Fail';
export const CLUB_API_GET_AUTH_USER_DETAILS_SUCCESS                 = '[Club API] Get Auth User Details Success';
export const CLUB_API_GET_AUTH_USER_UNAUTHORISED_LOGIN              = '[Club API] Get Auth User Unauthorised Login';
export const AUTH_STORE_LOGIN_STATE_UPDATE                          = '[Core Store] Login State Update';
export const AUTH_STORE_LOGIN_STATE_CORRECT                         = '[Core Store] Login State Correct';
export const AUTH_STORE_LOGOUT_STATE_UPDATE                         = '[Core Store] Logout State Update';

export const COOKIE_STORAGE_CHECK_LOGIN_STATUS                      = '[Cookie Storage] Check User Login Status';
export const COOKIE_STORAGE_CHECK_LOGIN_TRUE                        = '[Cookie Storage] Check User Login True';
export const COOKIE_STORAGE_CHECK_LOGIN_FALSE                       = '[Cookie Storage] Check User Login False';
export const COOKIE_STORAGE_ATTEMPT_GET_AUTH_DETAILS                = '[Cookie Storage] Attempt Get Auth User Details';

export const HEADER_COMPONENT_LOGOUT_USER                           = '[Header Component] Logout User';
export const CLUB_API_POST_LOGOUT_USER                              = '[Club API] Post Logout User';
export const CLUB_API_POST_LOGOUT_USER_FAIL                         = '[Club API] Post Logout User Fail';
export const CLUB_API_POST_LOGOUT_USER_SUCCESS                      = '[Club API] POst Logout User Success';
export const HEADER_COMPONENT_LOGOUT_USER_SUCCESS_NAVIGATE_NEXT     = '[Header Component] Logout User Success. Navigate to next route'

export const FORGOTTEN_PASSWORD_PAGE_RESET_PASSWORD_REQUEST         = '[Forgotten Password Page] Reset Password Request'
export const CLUB_API_POST_RESET_PASSWORD_REQUEST                   = '[Club API] Post Reset Password Request';
export const CLUB_API_POST_RESET_PASSWORD_REQUEST_FAIL              = '[Club API] Post Reset Password Request Fail';
export const CLUB_API_POST_RESET_PASSWORD_REQUEST_SUCCESS           = '[Club API] Post Reset Password Request Success';

export const PASSWORD_RESET_PAGE_RESET_PASSWORD                     = '[Password Reset Page] Reset Password'
export const CLUB_API_POST_RESET_PASSWORD                           = '[Club API] Post Reset Password';
export const CLUB_API_POST_RESET_PASSWORD_FAIL                      = '[Club API] Post Reset Password Fail';
export const CLUB_API_POST_RESET_PASSWORD_SUCCESS                   = '[Club API] Post Reset Password Success';

export const CREATE_LISTING_PAGE_GET_LOGGED_IN_USER_DETAILS         = '[Create Listing Page] Get Logged In User Details'

export const USER_DASHBOARD_PAGE_GET_LOGGED_IN_USER_DETAILS         = '[User Dashbaord Page] Get Logged In User Details'

export const USER_DASHBOARD_PAGE_VERIFY_USER_EMAIL_REQUEST          = '[User Dashbaord Page] Verify User Email Request'
export const USER_EMAIL_VERIFICATION_PAGE_VERIFY_USER_EMAIL_REQUEST = '[User Email Verification Page] Verify User Email Request'
export const CLUB_API_POST_VERIFY_USER_EMAIL_REQUEST                = '[Club API] Post Verify User Email Request';
export const CLUB_API_POST_VERIFY_USER_EMAIL_REQUEST_FAIL           = '[Club API] Post Verify User Email Request Fail';
export const CLUB_API_POST_VERIFY_USER_EMAIL_REQUEST_SUCCESS        = '[Club API] Post Verify User Email Request Success';

export const USER_EMAIL_VERIFICATION_PAGE_VALIDATE_USERNAME         = '[User Email Verification Page] Validate Username'
export const CLUB_API_POST_USERNAME_VALIDATION                      = '[Club API] Post Username Validation';
export const CLUB_API_POST_USERNAME_VALIDATION_FAIL                 = '[Club API] Post Username Validation Fail';
export const CLUB_API_POST_USERNAME_VALIDATION_SUCCESS              = '[Club API] Post Username Validation Success';

export const USER_EMAIL_VERIFICATION_PAGE_VERIFY_USER_EMAIL         = '[User Email Verification Page] Verify User Email'
export const CLUB_API_POST_VERIFY_USER_EMAIL                        = '[Club API] Post Verify User Email';
export const CLUB_API_POST_VERIFY_USER_EMAIL_FAIL                   = '[Club API] Post Verify User Email Fail';
export const CLUB_API_POST_VERIFY_USER_EMAIL_SUCCESS                = '[Club API] Post Verify User Email Success';

export const REGISTER_PAGE_REGISTER_USER                            = '[Register Page] Register User'
export const CLUB_API_POST_REGISTER_USER                            = '[Club API] Post Register User';
export const CLUB_API_POST_REGISTER_USER_FAIL                       = '[Club API] Post Register User Fail';
export const CLUB_API_POST_REGISTER_USER_SUCCESS                    = '[Club API] Post Register User Success';

export const USER_PROFILE_EDIT_PAGE_UPDATE_USER                     = '[User Profile Edit Page] Update User'
export const CLUB_API_PUT_UPDATE_USER                               = '[Club API] Put Update User';
export const CLUB_API_PUT_UPDATE_USER_FAIL                          = '[Club API] Put Update User Fail';
export const CLUB_API_PUT_UPDATE_USER_SUCCESS                       = '[Club API] Put Update User Success';

export class LoginPageLoginUser implements Action {
    readonly type = LOGIN_PAGE_LOGIN_USER;
    constructor(public payload: LoginContext) { }
}
export class ClubApiPostLoginUser implements Action {
    readonly type = CLUB_API_POST_LOGIN_USER;
    constructor(public payload: LoginContext) { }}

export class ClubApiPostLoginUserFail implements Action {
    readonly type = CLUB_API_POST_LOGIN_USER_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiPostLoginUserSuccess implements Action {
    readonly type = CLUB_API_POST_LOGIN_USER_SUCCESS;
    constructor() {}
}

export class LoginPageSuccessNavigateNext implements Action {
    readonly type = LOGIN_PAGE_AUTH_SUCCESS_NAVIGATE_NEXT;
    constructor(public payload?: string) {}
}

export class HeaderComponentCheckUserLoginStatus implements Action {
    readonly type = HEADER_COMPONENT_CHECK_LOGIN_STATUS;
    constructor() {}
}

export class HeaderComponentCheckUserLoginTrue implements Action {
    readonly type = HEADER_COMPONENT_CHECK_LOGIN_TRUE;
    constructor() {}
}

export class HeaderComponentCheckUserLoginFalse implements Action {
    readonly type = HEADER_COMPONENT_CHECK_LOGIN_FALSE;
    constructor() {}
}

export class HeaderComponentGetLoggedInUserDetails implements Action {
    readonly type = HEADER_COMPONENT_GET_LOGGED_IN_USER_DETAILS;
    constructor() {}
}

export class HeaderComponentUserDetailsExists implements Action {
    readonly type = HEADER_COMPONENT_USER_DETAILS_EXISTS;
    constructor() {}
}


export class AuthGuardCheckUserLoginStatus implements Action {
    readonly type = AUTH_GUARD_CHECK_LOGIN_STATUS;
    constructor() {}
}

export class AuthGuardCheckUserLoginTrue implements Action {
    readonly type = AUTH_GUARD_CHECK_LOGIN_TRUE;
    constructor() {}
}

export class AuthGuardCheckUserLoginFalse implements Action {
    readonly type = AUTH_GUARD_CHECK_LOGIN_FALSE;
    constructor() {}
}

export class AuthGuardGetLoggedInUserDetails implements Action {
    readonly type = AUTH_GUARD_GET_LOGGED_IN_USER_DETAILS;
    constructor() {}
}

export class ClubApiGetAuthUserDetails implements Action {
    readonly type = CLUB_API_GET_AUTH_USER_DETAILS;
    constructor(public payload?: string) {}
}

export class ClubApiGetAuthUserDetailsFail implements Action {
    readonly type = CLUB_API_GET_AUTH_USER_DETAILS_FAIL;
    constructor(public errorMessage: any) { }
}

export class ClubApiGetAuthUserDetailsSuccess implements Action {
    readonly type = CLUB_API_GET_AUTH_USER_DETAILS_SUCCESS;
    constructor(public payload: any) { }
}

export class ClubApiGetAuthUserUnauthorisedLogin implements Action {
    readonly type = CLUB_API_GET_AUTH_USER_UNAUTHORISED_LOGIN;
    constructor(public errorMessage: any) { }
}

export class AuthStoreLoginStateUpdate implements Action {
    readonly type = AUTH_STORE_LOGIN_STATE_UPDATE;
    constructor() { }
}

export class AuthStoreLoginStateCorrect implements Action {
    readonly type = AUTH_STORE_LOGIN_STATE_CORRECT;
    constructor() { }
}

export class AuthStoreLogoutStateUpdate implements Action {
    readonly type = AUTH_STORE_LOGOUT_STATE_UPDATE;
    constructor() { }
}

export class CookieStorageCheckUserLoginStatus implements Action {
    readonly type = COOKIE_STORAGE_CHECK_LOGIN_STATUS;
    constructor() {}
}

export class CookieStorageCheckUserLoginTrue implements Action {
    readonly type = COOKIE_STORAGE_CHECK_LOGIN_TRUE;
    constructor() {}
}

export class CookieStorageCheckUserLoginFalse implements Action {
    readonly type = COOKIE_STORAGE_CHECK_LOGIN_FALSE;
    constructor() {}
}

export class CookieStorageAttemptGetUserAuthDetails implements Action {
    readonly type = COOKIE_STORAGE_ATTEMPT_GET_AUTH_DETAILS ;
    constructor() {}
}

export class HeaderComponentLogoutUser implements Action {
    readonly type = HEADER_COMPONENT_LOGOUT_USER;
    constructor() { }
}
export class ClubApiPostLogoutUser implements Action {
    readonly type = CLUB_API_POST_LOGOUT_USER;
    constructor(public payload?: any) { }}

export class ClubApiPostLogoutUserFail implements Action {
    readonly type = CLUB_API_POST_LOGOUT_USER_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiPostLogoutUserSuccess implements Action {
    readonly type = CLUB_API_POST_LOGOUT_USER_SUCCESS;
    constructor() {}
}

export class HeaderComponentLogoutUserSuccessNavigateNext implements Action {
    readonly type = HEADER_COMPONENT_LOGOUT_USER_SUCCESS_NAVIGATE_NEXT;
    constructor() {}
}

export class ForgottenPasswordPageResetPasswordRequest implements Action {
    readonly type = FORGOTTEN_PASSWORD_PAGE_RESET_PASSWORD_REQUEST;
    constructor(public payload?: ForgotPasswordContext) { }
}
export class ClubApiPostResetPasswordRequest implements Action {
    readonly type = CLUB_API_POST_RESET_PASSWORD_REQUEST;
    constructor(public payload?: ForgotPasswordContext) { }}

export class ClubApiPostResetPasswordRequestFail implements Action {
    readonly type = CLUB_API_POST_RESET_PASSWORD_REQUEST_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiPostResetPasswordRequestSuccess implements Action {
    readonly type = CLUB_API_POST_RESET_PASSWORD_REQUEST_SUCCESS;
    constructor() {}
}

export class PasswordResetPageResetPasswordRequest implements Action {
    readonly type = PASSWORD_RESET_PAGE_RESET_PASSWORD;
    constructor(public payload?: ResetContext) { }
}
export class ClubApiPostResetPassword implements Action {
    readonly type = CLUB_API_POST_RESET_PASSWORD;
    constructor(public payload?: ResetContext) { }}

export class ClubApiPostResetPasswordFail implements Action {
    readonly type = CLUB_API_POST_RESET_PASSWORD_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiPostResetPasswordSuccess implements Action {
    readonly type = CLUB_API_POST_RESET_PASSWORD_SUCCESS;
    constructor() {}
}

export class CreateListingPageGetLoggedInUserDetails implements Action {
    readonly type = CREATE_LISTING_PAGE_GET_LOGGED_IN_USER_DETAILS;
    constructor() { }
}

export class UserDashboardPageGetLoggedInUserDetails implements Action {
    readonly type = USER_DASHBOARD_PAGE_GET_LOGGED_IN_USER_DETAILS;
    constructor() { }
}

export class UserDashboardPageVerifyUserEmailRequest implements Action {
    readonly type = USER_DASHBOARD_PAGE_VERIFY_USER_EMAIL_REQUEST;
    constructor() { }
}

export class UserEmailVerificationPageVerifyUserEmailRequest implements Action {
    readonly type = USER_EMAIL_VERIFICATION_PAGE_VERIFY_USER_EMAIL_REQUEST;
    constructor() { }
}
export class ClubApiPostVerifyUserEmailRequest implements Action {
    readonly type = CLUB_API_POST_VERIFY_USER_EMAIL_REQUEST;
    constructor(public payload?: ResetContext) { }}

export class ClubApiPostVerifyUserEmailRequestFail implements Action {
    readonly type = CLUB_API_POST_VERIFY_USER_EMAIL_REQUEST_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiPostVerifyUserEmailRequestSuccess implements Action {
    readonly type = CLUB_API_POST_VERIFY_USER_EMAIL_REQUEST_SUCCESS;
    constructor() {}
}

export class UserEmailVerificationPageValidateUsername implements Action {
    readonly type = USER_EMAIL_VERIFICATION_PAGE_VALIDATE_USERNAME;
    constructor(public payload?: UserUsernameValidationContext ) { }
}
export class ClubApiPostUsernameValidation implements Action {
    readonly type = CLUB_API_POST_USERNAME_VALIDATION;
    constructor(public payload?: UserUsernameValidationContext) { }}

export class ClubApiPostUsernameValidationFail implements Action {
    readonly type = CLUB_API_POST_USERNAME_VALIDATION_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiPostUsernameValidationSuccess implements Action {
    readonly type = CLUB_API_POST_USERNAME_VALIDATION_SUCCESS;
    constructor() {}
}

export class UserEmailVerificationPageVerifyUserEmail implements Action {
    readonly type = USER_EMAIL_VERIFICATION_PAGE_VERIFY_USER_EMAIL;
    constructor(public payload?: UserEmailValidationContext ) { }
}
export class ClubApiPostVerifyUserEmail implements Action {
    readonly type = CLUB_API_POST_VERIFY_USER_EMAIL;
    constructor(public payload?: UserEmailValidationContext) { }}

export class ClubApiPostVerifyUserEmailFail implements Action {
    readonly type = CLUB_API_POST_VERIFY_USER_EMAIL_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiPostVerifyUserEmailSuccess implements Action {
    readonly type = CLUB_API_POST_VERIFY_USER_EMAIL_SUCCESS;
    constructor() {}
}


export class RegisterPageRegisterUser implements Action {
    readonly type = REGISTER_PAGE_REGISTER_USER;
    constructor(public payload?: RegisterContext) { }
}
export class ClubApiPostRegisterUser implements Action {
    readonly type = CLUB_API_POST_REGISTER_USER;
    constructor(public payload?: RegisterContext) { }}

export class ClubApiPostRegisterUserFail implements Action {
    readonly type = CLUB_API_POST_REGISTER_USER_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiPostRegisterUserSuccess implements Action {
    readonly type = CLUB_API_POST_REGISTER_USER_SUCCESS;
    constructor() {}
}

export class UserProfileEditPageUpdateUser implements Action {
    readonly type = USER_PROFILE_EDIT_PAGE_UPDATE_USER;
    constructor(public payload?: User) { }
}
export class ClubApiPutUpdateUser implements Action {
    readonly type = CLUB_API_PUT_UPDATE_USER;
    constructor(public payload?: User) { }}

export class ClubApiPutUpdateUserFail implements Action {
    readonly type = CLUB_API_PUT_UPDATE_USER_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiPutUpdateUserSuccess implements Action {
    readonly type = CLUB_API_PUT_UPDATE_USER_SUCCESS;
    constructor(public payload?: any) {}
}

// action types
export type AuthAction =
    | LoginPageLoginUser
    | ClubApiPostLoginUser
    | ClubApiPostLoginUserFail
    | ClubApiPostLoginUserSuccess
    | LoginPageSuccessNavigateNext
    | HeaderComponentCheckUserLoginStatus
    | HeaderComponentCheckUserLoginTrue
    | HeaderComponentCheckUserLoginFalse
    | HeaderComponentGetLoggedInUserDetails
    | HeaderComponentUserDetailsExists
    | AuthGuardCheckUserLoginStatus
    | AuthGuardCheckUserLoginTrue
    | AuthGuardCheckUserLoginFalse
    | AuthGuardGetLoggedInUserDetails
    | ClubApiGetAuthUserDetails
    | ClubApiGetAuthUserDetailsFail
    | ClubApiGetAuthUserDetailsSuccess
    | ClubApiGetAuthUserUnauthorisedLogin
    | AuthStoreLoginStateUpdate
    | AuthStoreLoginStateCorrect
    | AuthStoreLogoutStateUpdate
    | CookieStorageCheckUserLoginStatus
    | CookieStorageCheckUserLoginTrue
    | CookieStorageCheckUserLoginFalse
    | CookieStorageAttemptGetUserAuthDetails
    | HeaderComponentLogoutUser
    | ClubApiPostLogoutUser
    | ClubApiPostLogoutUserFail
    | ClubApiPostLogoutUserSuccess
    | HeaderComponentLogoutUserSuccessNavigateNext
    | ForgottenPasswordPageResetPasswordRequest
    | ClubApiPostResetPasswordRequest
    | ClubApiPostResetPasswordRequestFail
    | ClubApiPostResetPasswordRequestSuccess
    | PasswordResetPageResetPasswordRequest
    | ClubApiPostResetPassword
    | ClubApiPostResetPasswordFail
    | ClubApiPostResetPasswordSuccess
    | CreateListingPageGetLoggedInUserDetails
    | UserDashboardPageGetLoggedInUserDetails
    | UserDashboardPageVerifyUserEmailRequest
    | UserEmailVerificationPageVerifyUserEmailRequest
    | ClubApiPostVerifyUserEmailRequest
    | ClubApiPostVerifyUserEmailRequestFail
    | ClubApiPostVerifyUserEmailRequestSuccess
    | UserEmailVerificationPageValidateUsername
    | ClubApiPostUsernameValidation
    | ClubApiPostUsernameValidationFail
    | ClubApiPostUsernameValidationSuccess
    | UserEmailVerificationPageVerifyUserEmail
    | ClubApiPostVerifyUserEmail
    | ClubApiPostVerifyUserEmailFail
    | ClubApiPostVerifyUserEmailSuccess
    | RegisterPageRegisterUser
    | ClubApiPostRegisterUser
    | ClubApiPostRegisterUserFail
    | ClubApiPostRegisterUserSuccess
    | UserProfileEditPageUpdateUser
    | ClubApiPutUpdateUser
    | ClubApiPutUpdateUserFail
    | ClubApiPutUpdateUserSuccess
    ;
