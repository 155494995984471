import { createSelector } from '@ngrx/store';

import * as fromUI from '../reducers';
import * as fromCore from 'src/app/core/store/reducers';
import * as fromCoreListings from 'src/app/core/store/reducers/listings.reducer';
import * as fromUserEscortListing from '../reducers/user-escort-listing.reducer';
import { Listing, Media } from 'src/library/features/models';
import { getMediaEntities as getAuthMediaEntities } from 'src/app/core/store/selectors/media.selectors';

export const getUserEscortListingUIState = createSelector(
	fromUI.getUIState,
	(state: fromUI.UIState) => state.userEscortListing
);

export const getUserEscortListingOrder = createSelector(
	getUserEscortListingUIState,
	fromUserEscortListing.getUserEscortListingOrder
);

export const getUserEscortListingState = createSelector(
	fromCore.getCoreState,
	(state: fromCore.CoreState) => state.listings
);

export const getAuthListingsEntities = createSelector(
	getUserEscortListingState,
	fromCoreListings.getListingsEntities
);

export const getUserEscortListingsList = createSelector(
	getUserEscortListingOrder,
	getAuthListingsEntities,
	(order, listings) => order.map((uuid) => listings[uuid])
);

// Get Denormalized listings
export const getUserEscortListingsView = createSelector(
	getUserEscortListingsList,
	getAuthMediaEntities,
	(listings, media): Listing[] =>
		listings.map(
			(listing) =>
				listing && {
					...listing,
					media: (listing.media as string[]).map(
						(mediaUUID) => media[mediaUUID]
					) as Media[]
				}
		)
);
