import * as fromSuburbs from '../actions/suburbs.action';

import { LoadingState, ErrorState, CallState, getError } from './call-state';

import { Suburb } from '../../models';

export interface SuburbState {
	entities: { [ID: number]: Suburb };
	currentSearch: Suburb['ID'][];
	callState: CallState;
}

export const initialState: SuburbState = {
	entities: {},
	currentSearch: [],
	callState: LoadingState.INIT
};

export function reducer(
	state = initialState,
	action: fromSuburbs.SuburbsAction
): SuburbState {
	switch (action.type) {
		case fromSuburbs.CLUB_API_SEARCH_SUBURB: {
			return {
				...state,
				callState: LoadingState.LOADING
			};
		}

		case fromSuburbs.CLUB_API_SEARCH_SUBURB_SUCCESS: {
			const suburbs = action.payload.entities.suburbs;
			const currentSearch = action.payload.result;
			const entities = {
				...state.entities,
				...suburbs
			};

			return {
				...state,
				callState: LoadingState.LOADED,
				entities,
				currentSearch: currentSearch
			};
		}

		case fromSuburbs.CLUB_API_SEARCH_SUBURB_FAIL: {
			return {
				...state,
				callState: { errorMessage: action.errorMessage }
			};
		}

        case fromSuburbs.LOCATION_SELECTOR_COMPONENT_CLEAR_SEARCH: {
            return {
                ...state,
                currentSearch: []
            }
        }

		default:
			return state;
	}
}

export const getSuburbsLoading          = (state: SuburbState) => state.callState === LoadingState.LOADING;
export const getSuburbsLoaded           = (state: SuburbState) => state.callState === LoadingState.LOADED;
export const getSuburbsError            = (state: SuburbState) => getError(state.callState);
export const getSuburbsEntities         = (state: SuburbState) => state.entities;
export const getSuburbsCurrentSearch    = (state: SuburbState) => state.currentSearch;
