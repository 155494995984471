import { createSelector } from '@ngrx/store';

import * as fromRoot from '../../../store';
import * as fromCore from '../reducers';
import * as fromCoreAuth from '../reducers/auth.reducer';
import * as fromCoreListings from '../reducers/listings.reducer';

import { Listing } from 'src/library/features/models';
import { getMediaEntities } from './media.selectors';
import { getEscortsEntities } from './escorts.selectors';

export const getListingState = createSelector(
	fromCore.getCoreState,
	(state: fromCore.CoreState) => state.listings
);

export const getListingsEntities = createSelector(
	getListingState,
	fromCoreListings.getListingsEntities
);

// Get Listings API call
export const getListingsLoading = createSelector(
	getListingState,
	fromCoreListings.getListingsLoading
);

export const getListingsLoaded = createSelector(
	getListingState,
	fromCoreListings.getListingsLoaded
);

export const getListingsError = createSelector(
	getListingState,
	fromCoreListings.getListingsError
);

// Get Listing Stats API call
export const getListingStatsLoading = createSelector(
	getListingState,
	fromCoreListings.getListingStatsLoading
);

export const getListingStatsLoaded = createSelector(
	getListingState,
	fromCoreListings.getListingStatsLoaded
);

export const getListingStatsError = createSelector(
	getListingState,
	fromCoreListings.getListingStatsError
);

// Create Listing API call
export const getCreateListingLoading = createSelector(
	getListingState,
	fromCoreListings.getCreateListingLoading
);

export const getCreateListingLoaded = createSelector(
	getListingState,
	fromCoreListings.getCreateListingLoaded
);

export const getCreateListingError = createSelector(
	getListingState,
	fromCoreListings.getCreateListingError
);

// Update Listing API call
export const getUpdateListingLoading = createSelector(
	getListingState,
	fromCoreListings.getUpdateListingLoading
);

export const getUpdateListingLoaded = createSelector(
	getListingState,
	fromCoreListings.getUpdateListingLoaded
);

export const getUpdateListingError = createSelector(
	getListingState,
	fromCoreListings.getUpdateListingError
);

// Delete Listing API call
export const getDeleteListingLoading = createSelector(
	getListingState,
	fromCoreListings.getDeleteListingLoading
);

export const getDeleteListingLoaded = createSelector(
	getListingState,
	fromCoreListings.getDeleteListingLoaded
);

export const getDeleteListingError = createSelector(
	getListingState,
	fromCoreListings.getDeleteListingError
);

// Get Listing List
export const getListingsList = createSelector(getListingsEntities, (entities) =>
	Object.keys(entities).map((key) => entities[key])
);

// Get Denormalized listings
export const getListingsView = createSelector(
	getListingsList,
	getMediaEntities,
	(listings, media): Listing[] =>
		listings.map(
			(listing) =>
				listing && {
					...listing,
					media: (listing.media as string[]).map(
						(mediaUUID) => media[mediaUUID]
					)
				}
		)
);

// get Auth State to get Auth User entity
export const getCoreAuthStoreState = createSelector(
	fromCore.getCoreState,
	(state: fromCore.CoreState) => state.auth
);

// Get Auth User Details
export const getCoreAuthUserEntities = createSelector(
	getCoreAuthStoreState,
	fromCoreAuth.getAuthUserEntities
);

export const getListingByRouteUUID = createSelector(
	getListingsEntities,
	fromRoot.getRouterState,
	(listings, router): Listing =>
		router.state && listings[router.state.params.uuid]
);

// Get Denormalized listing by Route UUID
export const getListingByRouteIDView = createSelector(
	getListingByRouteUUID,
	getMediaEntities,	
	getCoreAuthUserEntities,
	getEscortsEntities,
	(listing: Listing, medias, users, escorts): Listing => {
		return listing && {
			...listing,
			media: (listing.media as string[]).map((uuid) => medias[uuid]),
			user: users[listing.user as string] && {
				...users[listing.user as string],
				avatar: medias[users[listing.user as string].avatar as string],
				escort: escorts[users[listing.user as string].escort as string] && {
					...escorts[users[listing.user as string].escort as string],
					avatar: medias[escorts[users[listing.user as string].escort as string].avatar as string],
					banner: medias[escorts[users[listing.user as string].escort as string].banner as string],
					galleries: (escorts[users[listing.user as string].escort as string].galleries).map((gallery) => {
						return gallery && {
							...gallery,
							media: (gallery.media as string[]).map((galleryMedia) => medias[galleryMedia]),
							thumbnail: medias[gallery.thumbnail as string]
						}
					})
				}
			}
		}
	}
);
