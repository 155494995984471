import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SocketService } from '../services/socket.service';

@Injectable()
export class SocketInterceptor implements HttpInterceptor {
	constructor(private socketService: SocketService) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
        // If connection instance of Echo is created, to append socket ID
        if (this.socketService.echo) {
            request = this.addSocketId(request);
        }

		return next.handle(request);
	}

	/**
	 * Attaches a X-Socket-ID to header for identification in API's broadcasts
	 *
	 * @param request The outgoing request object that requires the socket ID
	 */
	addSocketId(request: HttpRequest<unknown>): HttpRequest<any> {
		let socketId = this.socketService.echo.socketId();

		// Check if socket ID header is already set and socket ID is defined
		if (!request.headers.has('X-Socket-ID') && socketId) {
			return request.clone({
				headers: request.headers.set('X-Socket-ID', socketId)
			});
		} else {
			return request;
		}
	}
}
