import { DOCUMENT } from '@angular/common';
import {
	Injectable,
	ComponentFactoryResolver,
	Injector,
	Inject
} from '@angular/core';
import { SnackbarComponent, SnackbarType } from './snackbar.component';

@Injectable({
	providedIn: 'root'
})
export class SnackbarService {
	processingSnackbar = false;
	snackbarQueue: SnackbarQueueObj[] = [];

	constructor(
		private resolver: ComponentFactoryResolver,
		private injector: Injector,
		@Inject(DOCUMENT) private document: Document
	) {}

	/**
	 * Add message to Snackbar Queue
	 *
	 * @param {string} content Message Content
	 * @param {SnackbarCategory} [category='message'] Message Category
	 * @param {SnackbarType} [type] Type of Snackbar to display
	 * @param {number} [duration] Duration of Snackbar to display
	 * @param {string} [link] Link for snackbar
	 * @param {number} [count] count variable for message
	 */
	addMessage(
		content: string,
		category: SnackbarCategory = 'message',
		type?: SnackbarType,
		duration?: number,
		link?: string,
		count?: number
	): void {

		// Check if category is accummulative
		if (this.isAccumuluativeCategory(category)) {
			// Check if category already exists in queue
			const index = this.snackbarQueue.findIndex(
				(message) => message.category === category
			);

			if (index !== -1) {
				// If match then to update existing message in queue
				const newCount = this.snackbarQueue[index].count + count;
				const newMessage = {
					...this.snackbarQueue[index],
					content: this.snackbarQueue[index].content.replace(
						this.snackbarQueue[index].count.toString(),
						newCount.toString()
					),
					count: newCount
				};
				this.snackbarQueue[index] = newMessage;
			} else {
				// If no match then to push message to queue
				const newSnackbarMessage: SnackbarQueueObj = {
					category,
					content,
					type,
					duration,
					link,
					count
				};
				this.pushtoQueue(newSnackbarMessage);
			}
		} else {
			// If not to push message to queue
			const newSnackbarMessage: SnackbarQueueObj = {
				category,
				content,
				type,
				duration,
				link,
				count
			};
			this.pushtoQueue(newSnackbarMessage);
		}

		// Open new snackbar if not already opened
		if (!this.processingSnackbar) {
			this.open();
		}
	}

	/**
	 * Attempts to open a new snackbar component
	 * Will exit if snackbar already exists
	 * @returns {void}
	 */
	private open(): void {
		// Grab next message from queue
		const newMessage = this.getNextMessage();

		// If there is no next message to exit
		if (!newMessage) {
			this.processingSnackbar = false;
			return;
		}

		this.processingSnackbar = true;

		const factory =
			this.resolver.resolveComponentFactory(SnackbarComponent);
		const componentRef = factory.create(this.injector);

		// Pass on Component Inputs
		componentRef.instance.content = newMessage.content;
		componentRef.instance.duration = newMessage.duration;
		componentRef.instance.link = newMessage.link;
		componentRef.instance.type = newMessage.type;

		componentRef.hostView.detectChanges();

		const { nativeElement } = componentRef.location;

		this.document.body.appendChild(nativeElement);

		componentRef.instance.afterClose.subscribe(() => {
			componentRef.destroy();
			this.document.body.removeChild(nativeElement);

			// Fetch next message
			this.open();
		});
	}

	/**
	 * Fetches first message from queue and remove it
	 * @returns {(SnackbarQueueObj | undefined)}
	 */
	private getNextMessage(): SnackbarQueueObj | undefined {
		return this.snackbarQueue.length
			? this.snackbarQueue.shift()
			: undefined;
	}

	/**
	 * Check whether message is accummulative
	 * @param {SnackbarCategory} category
	 * @returns {Boolean}
	 */
	private isAccumuluativeCategory(category: SnackbarCategory): Boolean {
		switch (category) {
			case 'userSubscribed': {
				return true;
			}
			default: {
				return false;
			}
		}
	}


 /**
  * Pushs message to the SnackbarQueue
  * Allows different actions depending on the message category
  * @param {SnackbarQueueObj} message
  */
 private pushtoQueue(message: SnackbarQueueObj): void {
		switch (message.category) {
      case 'logout': {
        // Remove existing queue and push
        this.snackbarQueue.length = 0;
				this.snackbarQueue.push(message);
        break;
			}
			case 'action': {
        // Push to start of queue
				this.snackbarQueue.unshift(message);
        break;
			}
			default: {
				this.snackbarQueue.push(message);
        break;
			}
		}
	}
}

export type SnackbarCategory = 'message' | 'action' | 'logout' | 'userSubscribed';

export interface SnackbarQueueObj {
	category: SnackbarCategory;
	content: string;
	type?: SnackbarType;
	duration?: number;
	link?: string;
	count?: number;
}
