import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import {
	CustomHttpService,
	ErrorService,
	LogService
} from 'src/app/shared/services';
import {
	ClubAPIResponseService,
	PaginatedResponse
} from './api-response.service';
import { environment } from 'src/environments/environment';
import { ClubListing, Listing } from '../models';
import { Params } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class ListingsService {
	// API environment variables
	private ENV = `${environment.apiUrl}/${environment.apiVersion}`;
	// API Request information
	private API_END_POINT = 'listings'; // * Modify to change API path

	private URL = `${this.ENV}/${this.API_END_POINT}`;
	private SERVICE_NAME = `API (${this.API_END_POINT})`;

	constructor(
		private http: CustomHttpService,
		private logService: LogService,
		private errorService: ErrorService
	) {}

	private log(message: string) {
		this.logService.add(`${this.SERVICE_NAME}: ${message}`, 'success');
	}

	/**
	 * Get Latest Listings
	 * Use Pagination
	 * @param page - page number for pagination
	 * @returns Observable<PaginatedResponse<Listing[]>>
	 */
	getLatestListings(page: number): Observable<PaginatedResponse<Listing[]>> {
		const url = `${this.URL}`;
		const params = new HttpParams().set('page', page.toString());
		return this.http.get(url, params).pipe(
			// tap((res) => console.log(res)),
			map((res) =>
				ClubAPIResponseService.validateResponse<Listing[]>(
					res,
					'Listing',
					true,
					true
				)
			),
			tap((_) => this.log(`getLatestListings Page ${page} success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getLatestListings'
				)
			)
		);
	}

	/**
	 * Get Listing by uuid
	 * @param uuid - uuid
	 */
	getListing(uuid: string): Observable<Listing> {
		const url = `${this.URL}/${uuid}`;
		return this.http.get(url).pipe(
			// tap((res) => console.log(res)),
			map((res) =>
				ClubAPIResponseService.validateResponse<Listing>(
					res,
					'Listing',
					false
				)
			),
			tap((_) => this.log(`getListing ${uuid} success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getListing'
				)
			)
		);
	}

	/**
	 * Search Listings
	 * Use Pagination
	 * @param page - page number for pagination
	 * @returns Observable<PaginatedResponse<Listing[]>>
	 */
	postSearchListings(
		req: SearchListingRequest,
		page: number
	): Observable<PaginatedResponse<Listing[]>> {
		const url = `${this.URL}/search`;
		const params = Object.assign({page: page.toString()}, req); // Create new Object with params
		return this.http.post(url, params).pipe(
			// tap((res) => console.log(res)),
			map((res) =>
				ClubAPIResponseService.validateResponse<Listing[]>(
					res,
					'Listing',
					true,
					true
				)
			),
			tap((_) => this.log(`postSearchListings Page ${page} success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'postSearchListings'
				)
			)
		);
	}

	/**
	 *  Converts Listing data sent via socket to DTO model
	 *
	 * @param res ClubListing Response sent by socket
	 * @returns Listing
	 */
	convertSocketListing(res: any): Listing {
		const e = ClubAPIResponseService.ValidateSocketResponse<Listing>(
			res,
			'Listing',
			false
		);
		return e;
	}

	// Covert Params to Request format
	// ! fix to use object keys as type
	convertURIToRequest(params: Params): SearchListingRequest {
		return {
			strict: params.strict ? params.strict : true,
			age:
				params.amin && params.amax
					? {
							min: params.amin,
							max: params.amax
					  }
					: {},
			location:
				params.ln && params.llat && params.llong && params.lr
					? {
							name: decodeURIComponent(params.ln),
							latitude: params.llat,
							longitude: params.llong,
							radius: parseInt(params.lr) > 0 ? parseInt(params.lr) : undefined
					  }
					: {},
			tags: {
				ethnicities: params.tethnicity
					? [decodeURIComponent(params.tethnicity)]
					: []
			}
		};
	}
}

export interface SearchListingRequest {
	strict?: boolean;
	age?: {
		min?: string;
		max?: string;
	};
	location?: {
		name?: string;
		latitude?: number;
		longitude?: number;
		radius?: number;
	};
	tags?: {
		ethnicities?: string[];
	};
	page?: string;
}
