import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';

import * as coreListingActions from 'src/app/core/store/actions/listings.action';
import * as fromUI from '../../store';
import { Store } from '@ngrx/store';

import * as userEscortListingProfileActions from '../actions/user-escort-listing-profile.action';
import { UserListingStatsRequest } from 'src/library/features/services';
import { ListingStat } from 'src/library/features/models';

@Injectable()
export class UserEscortListingProfileEffects {
	constructor(
		private actions$: Actions,
		private store: Store<fromUI.UIState>
	) {}

	calculateViewGraph$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreListingActions.CLUB_API_GET_USER_LISTING_STATS_SUCCESS),
			map(
				(
					action: coreListingActions.ClubApiGetUserListingStatsSuccess
				) => {
					// Calculate information to display
					const nodes = this.plotViewGraphNodes(
						action.payload.req,
						action.payload.res
					);
					return new userEscortListingProfileActions.UserListingProfilePageCalculateViewGraph(
						nodes
					);
				}
			)
		);
	});

	calculateViewCard$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(coreListingActions.CLUB_API_GET_USER_LISTING_STATS_SUCCESS),
			withLatestFrom(
				this.store.select(fromUI.getUserEscortListingProfileUIState),
				(
					action: coreListingActions.ClubApiGetUserListingStatsSuccess,
					UIState
				) => {
					return {
						...action,
						viewTotalState: UIState.viewTotal
					};
				}
			),
			map((action) => {
				// Calculate information to display

				// If total already exists, then don't need to do anything;
				if (action.viewTotalState) {
					return new userEscortListingProfileActions.UserListingProfilePageCalculateViewCard(
						action.viewTotalState
					);
				} else {
					const res = action.payload.res;
					const viewTotal = {
						count: res[res.length - 1].cumulativeViews, // Get total views for listing
						rateOfChange:
							res[res.length - 1].views &&
							res[res.length - 2].cumulativeViews
								? res[res.length - 1].views /
								  res[res.length - 2].cumulativeViews
								: 0 // Get rate of increase from yesterday, check to see if variables are not null
					};
					return new userEscortListingProfileActions.UserListingProfilePageCalculateViewCard(
						viewTotal
					);
				}
			})
		);
	});

	// Convert response to useable graph nodes
	private plotViewGraphNodes(
		req: UserListingStatsRequest,
		res: ListingStat[]
	): any[] {
		const dataNodes = res.map((data) => {
			return {
				name: data.date,
				value: data.views
			};
		});
		return dataNodes;
	}
}
