import { Action } from '@ngrx/store';
import { Error } from 'src/app/shared/services';


export const LOCATION_SELECTOR_COMPONENT_SEARCH_SUBURB      = '[Location Selector Component] Search Suburb';
export const CLUB_API_SEARCH_SUBURB                        	= '[Club API] Search Suburb';
export const CLUB_API_SEARCH_SUBURB_FAIL                   	= '[Club API] Search Suburb Fail';
export const CLUB_API_SEARCH_SUBURB_SUCCESS                	= '[Club API] Search Suburb Success';
export const LOCATION_SELECTOR_COMPONENT_CLEAR_SEARCH      	= '[Location Selector Component] Clear Search Values';

export class LocationSelectorComponentSearchSuburb implements Action {
	readonly type = LOCATION_SELECTOR_COMPONENT_SEARCH_SUBURB;
	constructor(public payload: string) {}
}

export class ClubApiSearchSuburb implements Action {
	readonly type = CLUB_API_SEARCH_SUBURB;
	constructor(public payload: string) {}
}

export class ClubApiSearchSuburbFail implements Action {
	readonly type = CLUB_API_SEARCH_SUBURB_FAIL;
	constructor(public errorMessage: Error) {}
}

export class ClubApiSearchSuburbSuccess implements Action {
	readonly type = CLUB_API_SEARCH_SUBURB_SUCCESS;
	constructor(public payload: any) {}
}

export class LocationSelectorComponentClearSearch implements Action {
	readonly type = LOCATION_SELECTOR_COMPONENT_CLEAR_SEARCH;
	constructor() {}
}

// action types
export type SuburbsAction =
	| LocationSelectorComponentSearchSuburb
	| ClubApiSearchSuburb
	| ClubApiSearchSuburbFail
	| ClubApiSearchSuburbSuccess
	| LocationSelectorComponentClearSearch;
