import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import { Store } from '@ngrx/store';

import * as userActions from '../actions/users.action';
import * as fromServices from '../../services';
import * as fromRoot from '../../../../app/store';

import { normalize } from 'normalizr';
import * as fromSchema from '../../models/schema.model';

@Injectable()
export class UsersEffects {
    constructor(
        private actions$: Actions,
        private usersService: fromServices.UsersService,
        private store: Store<fromRoot.State>,
        private router: Router
    ) { }

    // Get All Users from API
    @Effect()
    fetchUsers$ = this.actions$.pipe(
        ofType(userActions.FETCH_USERS),
        switchMap(() => {
            return this.usersService.getUsers().pipe(
                map(users => {
                    console.log(users);
                    const normalizedData = normalize(users, [fromSchema.user]);
                    return new userActions.FetchUsersSuccess(normalizedData);
                }),
                catchError(error => of(new userActions.FetchUsersFail(error)))
            );
        })
    );

    // Get User by Route ID from API
    @Effect()
    fetchUserByID$ = this.actions$.pipe(
        ofType(
            userActions.PROFILE_PAGE_GUARD_FETCH_USER_FROM_ROUTE,
            userActions.USER_PROFILE_EDIT_PAGE_FETCH_USER_FROM_ROUTE),
        withLatestFrom(
            this.store.select(fromRoot.getRouterState),
            (action, router) => {
                return {
                    id: router.state.params.userId,
                    payload: action
                };
            }
        ),
        switchMap((newPayload) => {
            return this.usersService.getUser(newPayload.id).pipe(
                map(user => {
                    const normalizedData = normalize(user, fromSchema.user);
                    return new userActions.ClubApiGetUserSuccess(normalizedData);
                }),
                catchError(error => {
                    return of(new userActions.ClubApiGetUserFail(error));
                })
            );
        })
    );

    // Http 404 Error
    @Effect({ dispatch: false })
    errorStatus404$ = this.actions$.pipe(
        filter((payload: any) => payload && payload.errorMessage === '404'),
        switchMap((payload) => {
            this.router.navigate(['/404']);
            return EMPTY;
        })
    );
}
