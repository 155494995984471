import { createSelector } from '@ngrx/store';

import * as fromUI from '../reducers';
import * as fromHome from '../reducers/home.reducer';
import * as fromFeature from '../../../features/store/reducers';
import { getMediaEntities } from '../../../features/store/selectors/media.selectors';
import { Listing, Media } from 'src/library/features/models';
import { getListingsEntities } from 'src/library/features/store/selectors/listings.selectors';

export const getHomeState = createSelector(
	fromUI.getUIState,
	(state: fromUI.UIState) => state.home
);

export const getHomeListingPageNumber = createSelector(
	getHomeState,
	fromHome.getHomeListingPage
);

export const getHomeListingOrder = createSelector(
	getHomeState,
	fromHome.getHomeListingOrder
);

export const getHomeNewListingsEntities = createSelector(
	getHomeState,
	fromHome.getHomeNewListingsEntities
);

export const getHomeNewListingQueue = createSelector(
	getHomeState,
	fromHome.getHomeNewListingQueue
);

export const getListingState = createSelector(
	fromFeature.getFeaturesState,
	(state: fromFeature.FeaturesState) => state.listings
);

export const getHomeListingsList = createSelector(
	getHomeListingOrder,
	getListingsEntities,
	(order, listings) => order.map((uuid) => listings[uuid])
);

// Get Denormalized listings
export const getHomeListingsView = createSelector(
	getHomeListingsList,
	getHomeNewListingsEntities,
	getMediaEntities,
	(listings, newListings, media): Listing[] =>
		listings.map(
			(listing) =>
				listing && {
					...listing,
					isHomeViewNew: !!newListings[listing.UUID],
					media: (listing.media as string[]).map(
						(mediaUUID) => media[mediaUUID]
					) as Media[]
				}
		)
);

// Get New Listings Media
export const getHomeNewListingMedia = createSelector(
	getListingsEntities,
	getHomeNewListingQueue,
	getMediaEntities,
	(listings, newListings, media): Media[] =>
		newListings.map((key) => {
			const mediaString = listings[key].media[0] as string;
			return media[mediaString];
		})
);
