import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router
} from '@angular/router';
import { Observable, of } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromCore from '../../core/store';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private store: Store<fromCore.CoreState>
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		return this.isAuthenticated$().pipe(
			switchMap((isAuthenticated) => {
				if (isAuthenticated) {
					return of(true);
				} else {
					this.store.dispatch(
						new fromCore.AuthGuardCheckUserLoginStatus()
					);
					return this.isLoggedIn$().pipe(
						switchMap((isLoggedIn) => {
							if (isLoggedIn) {
								return of(true);
							} else {
								this.router.navigate(['/login'], {
									queryParams: { redirect: state.url },
									replaceUrl: true
								});
								return of(false);
							}
						})
					);
				}
			})
		);
	}

	isAuthenticated$(): Observable<boolean> {
		return this.store.select(fromCore.isAuthenticated).pipe(take(1));
	}

	isLoggedIn$(): Observable<boolean> {
		return this.store.select(fromCore.getCoreState).pipe(
			skipWhile((core) => core.auth.authGuardCheck),
			map((core) => core.auth.authState === 'LOADED'),
			take(1)
		);
	}
}
