import { Injectable } from '@angular/core';

import { CookieWrapperService } from '.';
import { Store } from '@ngrx/store';
import { AuthState } from '../store/reducers/auth.reducer';
import * as fromStore from '../../core/store/selectors';
import { take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class CredentialsService {
	referrerInterceptorLoggingInRequestFlag = false;

	constructor(
		private store: Store<AuthState>,
		private cookieStorage: CookieWrapperService
	) {}

	/**
	 * Checks is the user is authenticated.
	 * @return True if the user is authenticated.
	 */
	isAuthenticated(): boolean {
		let state: any;
		this.store
			.select(fromStore.isAuthenticated)
			.pipe(take(1))
			.subscribe((s) => (state = s));
		return state;
	}

	/**
	 * Sets the user authorised key.
	 * The key is a cookie that persists for a set duration once the user has successfully logged In.
	 */
	setAuthorisedCookie(): void {
		this.cookieStorage.setIsLoggedIn(true);
	}

	hasAuthorisedCookie(): boolean {
		return this.cookieStorage.getIsLoggedIn();
	}

	unsetAuthorisedCookie(): void {
		this.cookieStorage.removeIsLoggedIn();
	}
}
