import { createSelector } from '@ngrx/store';

import * as fromUI from '../reducers';
import * as fromSocket from '../reducers/socket.reducer';

export const getSocketState = createSelector(
	fromUI.getUIState,
	(state: fromUI.UIState) => state.socket
);

export const getSocketConnectionLoaded = createSelector(
	getSocketState,
	fromSocket.getSocketConnectionLoaded
);

export const getSocketConnectionLoading = createSelector(
	getSocketState,
	fromSocket.getSocketConnectionLoading
);

export const getSocketConnectionError = createSelector(
	getSocketState,
	fromSocket.getSocketConnectionError
);
