import { Action } from '@ngrx/store';
import { Error } from 'src/app/shared/services';
import { Notification } from 'src/library/features/models';
import { UserNotificationCountResponse } from 'src/library/features/services';


export const HEADER_NOTIFICATION_COMPONENT_FETCH_NOTIFICATION_COUNT                 = '[Header Notification Component] Fetch Notifications Count'
export const CLUB_API_GET_NOTIFICATION_COUNT                                        = '[Club API] Get Notifications Count';
export const CLUB_API_GET_NOTIFICATION_COUNT_FAIL                                   = '[Club API] Get Notifications Count Fail';
export const CLUB_API_GET_NOTIFICATION_COUNT_SUCCESS                                = '[Club API] Get Notifications Count Success';

export const HEADER_NOTIFICATION_COMPONENT_FETCH_NOTIFICATIONS                      = '[Header Notification Component] Fetch Notifications'
export const CLUB_API_GET_NOTIFICATIONS                                             = '[Club API] Get Notifications';
export const CLUB_API_GET_NOTIFICATIONS_FAIL                                        = '[Club API] Get Notifications Fail';
export const CLUB_API_GET_NOTIFICATIONS_SUCCESS                                     = '[Club API] Get Notifications Success';

export const HEADER_NOTIFICATION_COMPONENT_MARK_ALL_NOTIFICATIONS_AS_ACKNOWLEDGED   = '[Header Notification Component] Mark All Notifications as Acknowledged'
export const CLUB_API_ACKNOWLEDGED_NOTIFICATIONS                                    = '[Club API] Acknowledged Notifications'
export const CLUB_API_ACKNOWLEDGED_NOTIFICATIONS_FAIL                               = '[Club API] Acknowledged Notifications Fail'
export const CLUB_API_ACKNOWLEDGED_NOTIFICATIONS_SUCCESS                            = '[Club API] Acknowledged Notifications Success'

export const HEADER_NOTIFICATION_COMPONENT_MARK_ALL_NOTIFICATIONS_AS_READ           = '[Header Notification Component] Mark All Notifications as Read'
export const CLUB_API_READ_NOTIFICATIONS                                            = '[Club API] Read Notifications'
export const CLUB_API_READ_NOTIFICATIONS_FAIL                                       = '[Club API] Read Notifications Fail'
export const CLUB_API_READ_NOTIFICATIONS_SUCCESS                                    = '[Club API] Read Notifications Success'

export const HEADER_NOTIFICATION_COMPONENT_MARK_NOTIFICATION_AS_READ                = '[Header Notification Component] Mark Notification as Read'
export const CLUB_API_READ_NOTIFICATION                                             = '[Club API] Read Notification'
export const CLUB_API_READ_NOTIFICATION_FAIL                                        = '[Club API] Read Notification Fail'
export const CLUB_API_READ_NOTIFICATION_SUCCESS                                     = '[Club API] Read Notification Success'

export const HEADER_NOTIFICATION_COMPONENT_DELETE_NOTIFICATION                      = '[Header Notification Component] Delete Notification'
export const CLUB_API_DELETE_NOTIFICATION                                           = '[Club API] Delete Notification'
export const CLUB_API_DELETE_NOTIFICATION_FAIL                                      = '[Club API] Delete Notification Fail'
export const CLUB_API_DELETE_NOTIFICATION_SUCCESS                                   = '[Club API] Delete Notification Success'


export class HeaderNotificationComponentFetchNotificationCount implements Action {
    readonly type = HEADER_NOTIFICATION_COMPONENT_FETCH_NOTIFICATION_COUNT;
    constructor() { }
}
export class ClubApiGetNotificationCount implements Action {
    readonly type = CLUB_API_GET_NOTIFICATION_COUNT;
    constructor() { }
}
export class ClubApiGetNotificationCountFail implements Action {
    readonly type = CLUB_API_GET_NOTIFICATION_COUNT_FAIL;
    constructor(public errorMessage: Error) { }
}
export class ClubApiGetNotificationCountSuccess implements Action {
    readonly type = CLUB_API_GET_NOTIFICATION_COUNT_SUCCESS;
    constructor(public payload: UserNotificationCountResponse['acknowledged']) {}
}

export class HeaderNotificationComponentFetchNotifications implements Action {
    readonly type = HEADER_NOTIFICATION_COMPONENT_FETCH_NOTIFICATIONS;
    constructor() { }
}
export class ClubApiGetNotifications implements Action {
    readonly type = CLUB_API_GET_NOTIFICATIONS;
    constructor(public payload: number) { }
}
export class ClubApiGetNotificationsFail implements Action {
    readonly type = CLUB_API_GET_NOTIFICATIONS_FAIL;
    constructor(public errorMessage: Error) { }
}
export class ClubApiGetNotificationsSuccess implements Action {
    readonly type = CLUB_API_GET_NOTIFICATIONS_SUCCESS;
    constructor(public payload: any) {}
}

export class HeaderNotificationComponentMarkAllNotificationsAsAcknowledged implements Action {
    readonly type = HEADER_NOTIFICATION_COMPONENT_MARK_ALL_NOTIFICATIONS_AS_ACKNOWLEDGED;
    constructor() {}
}
export class ClubApiAcknowledgedNotifications implements Action {
    readonly type = CLUB_API_ACKNOWLEDGED_NOTIFICATIONS;
    constructor() { }
}
export class ClubApiAcknowledgedNotificationsFail implements Action {
    readonly type = CLUB_API_ACKNOWLEDGED_NOTIFICATIONS_FAIL;
    constructor(public errorMessage: Error) { }
}
export class ClubApiAcknowledgedNotificationsSuccess implements Action {
    readonly type = CLUB_API_ACKNOWLEDGED_NOTIFICATIONS_SUCCESS;
    constructor() {}
}

export class HeaderNotificationComponentMarkAllNotificationsAsRead implements Action {
    readonly type = HEADER_NOTIFICATION_COMPONENT_MARK_ALL_NOTIFICATIONS_AS_READ;
    constructor() {}
}
export class ClubApiReadNotifications implements Action {
    readonly type = CLUB_API_READ_NOTIFICATIONS;
    constructor() { }
}
export class ClubApiReadNotificationsFail implements Action {
    readonly type = CLUB_API_READ_NOTIFICATIONS_FAIL;
    constructor(public errorMessage: Error) { }
}
export class ClubApiReadNotificationsSuccess implements Action {
    readonly type = CLUB_API_READ_NOTIFICATIONS_SUCCESS;
    constructor() {}
}

export class HeaderNotificationComponentMarkNotificationAsRead implements Action {
    readonly type = HEADER_NOTIFICATION_COMPONENT_MARK_NOTIFICATION_AS_READ;
    constructor(public payload: Notification['UUID']) {}
}
export class ClubApiReadNotification implements Action {
    readonly type = CLUB_API_READ_NOTIFICATION;
    constructor(public payload: Notification['UUID']) { }
}
export class ClubApiReadNotificationFail implements Action {
    readonly type = CLUB_API_READ_NOTIFICATION_FAIL;
    constructor(public errorMessage: Error) { }
}
export class ClubApiReadNotificationSuccess implements Action {
    readonly type = CLUB_API_READ_NOTIFICATION_SUCCESS;
    constructor() {}
}

export class HeaderNotificationComponentDeleteNotification implements Action {
    readonly type = HEADER_NOTIFICATION_COMPONENT_DELETE_NOTIFICATION;
    constructor(public payload: Notification['UUID']) {}
}
export class ClubApiDeleteNotification implements Action {
    readonly type = CLUB_API_DELETE_NOTIFICATION;
    constructor(public payload: Notification['UUID']) { }
}
export class ClubApiDeleteNotificationFail implements Action {
    readonly type = CLUB_API_DELETE_NOTIFICATION_FAIL;
    constructor(public errorMessage: Error) { }
}
export class ClubApiDeleteNotificationSuccess implements Action {
    readonly type = CLUB_API_DELETE_NOTIFICATION_SUCCESS;
    constructor() {}
}

// action types
export type NotificationsAction =
    | HeaderNotificationComponentFetchNotificationCount
    | ClubApiGetNotificationCount
    | ClubApiGetNotificationCountFail
    | ClubApiGetNotificationCountSuccess
    | HeaderNotificationComponentFetchNotifications
    | ClubApiGetNotifications
    | ClubApiGetNotificationsFail
    | ClubApiGetNotificationsSuccess
    | HeaderNotificationComponentMarkAllNotificationsAsAcknowledged
    | ClubApiAcknowledgedNotifications
    | ClubApiAcknowledgedNotificationsFail
    | ClubApiAcknowledgedNotificationsSuccess
    | HeaderNotificationComponentMarkAllNotificationsAsRead
    | ClubApiReadNotifications
    | ClubApiReadNotificationsFail
    | ClubApiReadNotificationsSuccess
    | HeaderNotificationComponentMarkNotificationAsRead
    | ClubApiReadNotification
    | ClubApiReadNotificationFail
    | ClubApiReadNotificationSuccess
    | HeaderNotificationComponentDeleteNotification
    | ClubApiDeleteNotification
    | ClubApiDeleteNotificationFail
    | ClubApiDeleteNotificationSuccess
    ;
