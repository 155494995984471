import { Media } from '../../models';
import * as fromMedia from '../actions/media.action';
import * as fromUsers from '../actions/users.action';
import * as fromListings from '../actions/listings.action';
import * as fromEscorts from '../actions/escorts.action';
import * as fromSocket from 'src/library/ui/store/actions/socket.action';

export interface MediaState {
	entities: { [UUID: string]: Media };
}

export const initialState: MediaState = {
	entities: {}
};

export function reducer(
	state = initialState,
	action:
		| fromMedia.MediaAction
		| fromUsers.UsersAction
		| fromListings.ListingsAction
		| fromEscorts.EscortsAction
		| fromSocket.SocketAction
): MediaState {
	switch (action.type) {
		case fromSocket.WEBSOCKET_SERVICE_GET_NEW_NOTIFICATION:
		case fromSocket.WEBSOCKET_SERVICE_GET_NEW_LISTING:
		case fromEscorts.CLUB_API_GET_ESCORT_SUCCESS:
		case fromListings.CLUB_API_POST_SEARCH_LISTINGS_SUCCESS:
		case fromListings.CLUB_API_GET_LISTING_SUCCESS:
		case fromListings.CLUB_API_GET_LATEST_LISTINGS_SUCCESS:
		case fromUsers.FETCH_USERS_SUCCESS:
		case fromUsers.CLUB_API_GET_USER_SUCCESS: {
			const media = action.payload.entities.media;
			const entities = {
				...state.entities,
				...media
			};

			return {
				...state,
				entities
			};
		}

		default:
			return state;
	}
}

export const getMediaEntities = (state: MediaState) => state.entities;
