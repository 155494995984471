import { AuthService } from './auth.service';
import { CredentialsService } from './credentials.service';
import { UserForgotPasswordService } from './user-forgot-password.service';
import { UserResetPasswordService } from './user-reset-password.service';
import { UserVerificationRequestService } from './user-verification-request.service';
import { UserUsernameValidationService } from './user-username-validation.service';
import { UserVerificationService } from './user-verification.service';
import { RegisterService } from './register.service';
import { ClubAPIAuthResponseService } from './api-response.service';
import { CookieWrapperService } from './cookies.service';

export const services: any[] = [
	AuthService,
	CredentialsService,
	ClubAPIAuthResponseService,
	CookieWrapperService,
	UserForgotPasswordService,
	UserResetPasswordService,
	UserVerificationRequestService,
	UserUsernameValidationService,
	UserVerificationService,
	RegisterService
];

export * from './auth.service';
export * from './credentials.service';
export * from './api-response.service';
export * from './cookies.service';
export * from './user-forgot-password.service';
export * from './user-reset-password.service';
export * from './user-verification-request.service';
export * from './user-username-validation.service';
export * from './user-verification.service';
export * from './register.service';