import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducers, effects } from './store';

// services
import * as fromServices from './services';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        StoreModule.forFeature('features', reducers),
        EffectsModule.forFeature(effects)
    ],
    providers: [...fromServices.services],
    declarations: [],
    exports: [],
})
export class FeaturesModule { }
