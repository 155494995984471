import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class UserEmailVerificationGuard implements CanActivate {
	constructor(private router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		console.log(route);
		if (
			route &&
			route.queryParams &&
			route.queryParams.uuid &&
			route.queryParams.hash &&
			route.queryParams.signature &&
			route.queryParams.expires
		) {
			return true;
		} else {
			this.router.navigate(['/home'], {
				replaceUrl: true
			});
			return false;
		}
	}
}
