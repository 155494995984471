import * as fromListings from '../actions/listings.action';
import * as fromEscorts from '../actions/escorts.action';

import { LoadingState, ErrorState, CallState, getError } from './call-state';

import { Escort } from '../../models';

export interface EscortState {
	entities: { [handle: string]: Escort };
	callState: CallState;
}

export const initialState: EscortState = {
	entities: {},
	callState: LoadingState.INIT
};

export function reducer(
	state = initialState,
	action:
		| fromEscorts.EscortsAction
		| fromListings.ListingsAction
): EscortState {
	switch (action.type) {
		case fromEscorts.CLUB_API_GET_ESCORT: {
			return {
				...state,
				callState: LoadingState.LOADING
			};
		}

		case fromListings.CLUB_API_GET_LISTING_SUCCESS:
		case fromEscorts.CLUB_API_GET_ESCORT_SUCCESS: {
			const escorts = action.payload.entities.escorts;
			const entities = {
				...state.entities,
				...escorts
			};

			return {
				...state,
				callState: LoadingState.LOADED,
				entities
			};
		}

		case fromListings.CLUB_API_GET_LISTING_FAIL:
		case fromEscorts.CLUB_API_GET_ESCORT_FAIL: {
			return {
				...state,
				callState: { errorMessage: action.errorMessage }
			};
		}

		default:
			return state;
	}
}

export const getEscortsLoading = (state: EscortState) =>
	state.callState === LoadingState.LOADING;
export const getEscortsLoaded = (state: EscortState) =>
	state.callState === LoadingState.LOADED;
export const getEscortsError = (state: EscortState) =>
	getError(state.callState);
export const getEscortsEntities = (state: EscortState) => state.entities;
