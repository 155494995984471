import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromReports from '../reducers/reports.reducer';

export const getReportsState = createSelector(
	fromFeature.getFeaturesState,
	(state: fromFeature.FeaturesState) => state.reports
);

export const getReportLoading = createSelector(
	getReportsState,
	fromReports.getReportLoading
);

export const getReportError = createSelector(
	getReportsState,
	fromReports.getReportError
);

export const getReportLoaded = createSelector(
	getReportsState,
	fromReports.getReportLoaded
);
