import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as socketActions from '../actions/socket.action';
import * as fromUI from '..';
import * as fromCore from 'src/app/core/store';
import * as fromSchema from 'src/library/features/models/schema.model';

import { normalize } from 'normalizr';
import { Store } from '@ngrx/store';
import { SocketService } from 'src/app/shared/services/socket.service';
import {
	AuthNotificationService,
	ListingsService
} from 'src/library/features/services';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';

@Injectable()
export class SocketEffects {
	constructor(
		private actions$: Actions,
		private coreStore: Store<fromCore.CoreState>,
		private UIstore: Store<fromUI.UIState>,
		private socketService: SocketService,
		private snackbarService: SnackbarService,
		private listingService: ListingsService,
		private authNotificationService: AuthNotificationService
	) {}

	// Connect to laravel echo websockets from components
	websocketConnect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				socketActions.HEADER_NOTIFICATION_COMPONENT_WEBSOCKET_CONNECT,
				socketActions.HOME_PAGE_WEBSOCKET_CONNECT
			),
			map(() => {
				if (this.socketService.echo) {
					return new socketActions.WebSocketServiceWebSocketConnectionExist();
				} else {
					return new socketActions.WebSocketServiceWebSocketConnect();
				}
			})
		);
	});

	// Create instance of Echo connection
	connectToEchoOrFail$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(socketActions.WEBSOCKET_SERVICE_WEBSOCKET_CONNECT),
				tap(() => {
					this.socketService.initialise();
				})
			),
		{ dispatch: false }
	);

	// Home Page connect to public channel 'listing'
	// Check if already subscribed to channel
	homePageConnectToPublicListing$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(socketActions.HOME_PAGE_CHANNEL_LISTING_CONNECT),
			map(() => {
				if (
					!(
						this.socketService.echo &&
						!!this.socketService.echo.connector.channels.listing
					)
				) {
					return new socketActions.WebSocketServiceChannelListingConnect();
				} else {
					return new socketActions.WebSocketServiceChannelListingConnectionExist();
				}
			})
		);
	});

	// Connect to public channel 'listing' and start listening to ListingCreated Event
	// There is no direct effect dispatch. But to generate a new dispatch action on listen callback event
	connectToPublicListing$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(socketActions.WEBSOCKET_SERVICE_CHANNEL_LISTING_CONNECT),
				map(() => {
					this.socketService
						.joinPublicChannel('listing')
						.listen(
							'.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
							(data) => {
								const listing =
									this.listingService.convertSocketListing(
										data
									);
								const normalizedData = normalize(
									listing,
									fromSchema.listing
								);
								this.UIstore.dispatch(
									new socketActions.WebSocketServiceGetNewListing(
										normalizedData
									)
								);
							}
						);

					const callback = (eventName, data) => {
						/* 						console.log(
							`Listing Channel: The event ${eventName} was triggered with data ${JSON.stringify(
								data
							)}`
						);*/
					};
					this.socketService.bindChannelFunction('listing', callback);
				})
			),
		{ dispatch: false }
	);

	// Disconnect from  public channel 'listing'
	disconnectFromPublicListing$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(socketActions.HOME_PAGE_CHANNEL_LISTING_DISCONNECT),
				map(() => {
					this.socketService.leaveChannel('listing');
				})
			),
		{ dispatch: false }
	);

	// Home Page connect to private channel 'User.{UUID}'
	// Check if already subscribed to channel
	headerComponentConnectToPrivateUser$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				socketActions.HEADER_NOTIFICATION_COMPONENT_CHANNEL_USER_CONNECT
			),
			withLatestFrom(
				this.coreStore.select(fromCore.getAuthUser),
				(
					action: socketActions.HeaderNotificationComponentChannelUserConnect,
					user
				) => {
					return user.UUID;
				}
			),
			map((userUUID: string) => {
				if (
					!(
						this.socketService.echo &&
						!!this.socketService.echo.connector.channels[
							`private-User.${userUUID}`
						]
					)
				) {
					return new socketActions.WebSocketServiceChannelUserConnect(
						userUUID
					);
				} else {
					return new socketActions.WebSocketServiceChannelUserConnectionExist();
				}
			})
		);
	});

	// Connect to private channel 'User.{UUID}' and start listening to Events
	// There is no direct effect dispatch. But to generate a new dispatch action on listen callback event
	connectToPrivateUser$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(socketActions.WEBSOCKET_SERVICE_CHANNEL_USER_CONNECT),
				map(
					(
						action: socketActions.WebSocketServiceChannelUserConnect
					) => {
						this.socketService
							.joinPrivateChannel(`User.${action.payload}`)
							.listen(
								'.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
								(data) => {
									const notification =
										this.authNotificationService.convertSocketNotification(
											data
										);
									const normalizedData = normalize(
										notification,
										fromSchema.notification
									);
									// Push to snackbar
									this.snackbarService.addMessage(
										notification.description,
										'action',
										'default',
										3000,
										notification.link
									);
									// Add to Store
									this.UIstore.dispatch(
										new socketActions.WebSocketServiceGetNewNotification(
											normalizedData
										)
									);
								}
							);

						/* 					const callback = (eventName, data) => {
						console.log(
							`private-User.${action.payload} Channel: The event ${eventName} was triggered with data ${JSON.stringify(
								data
							)}`
						);
					};
					this.socketService.bindChannelFunction(`private-User.${action.payload}`, callback); */
					}
				)
			),
		{ dispatch: false }
	);
}
