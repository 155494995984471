import * as fromCoreAuth from '../actions/auth.action';
import * as fromCoreListings from '../actions/listings.action';
import * as fromUserEscortListingProfile from 'src/library/ui/store/actions/user-escort-listing-profile.action';

import { Listing } from 'src/library/features/models';
import {
	CallState,
	getError,
	LoadingState
} from 'src/library/features/store/reducers/call-state';

export interface ListingState {
	entities: { [handle: string]: Listing };
	callState: CallState;
	callStatsState: CallState;
	createState: CallState;
	updateState: CallState;
	deleteState: CallState;
}

export const initialState: ListingState = {
	entities: {},
	callState: LoadingState.INIT,
	callStatsState: LoadingState.INIT,
	createState: LoadingState.INIT,
	updateState: LoadingState.INIT,
	deleteState: LoadingState.INIT
};

export function reducer(
	state = initialState,
	action:
		| fromCoreAuth.AuthAction
		| fromCoreListings.ListingsAction
		| fromUserEscortListingProfile.UserEscorrtListingProfileAction
): ListingState {
	switch (action.type) {
		// Reset State to Initial on Load
		case fromUserEscortListingProfile.USER_LISTING_PROFILE_PAGE_INITIALISE_PAGE: {
			return {
				...state,
				callState: LoadingState.INIT,
				callStatsState: LoadingState.INIT,
				deleteState: LoadingState.INIT
			};
		}

		case fromCoreListings.CLUB_API_GET_USER_LISTING:
		case fromCoreListings.CLUB_API_GET_USER_LISTINGS: {
			return {
				...state,
				callState: LoadingState.LOADING
			};
		}

		case fromCoreListings.CLUB_API_GET_USER_LISTING_SUCCESS:
		case fromCoreAuth.CLUB_API_GET_AUTH_USER_DETAILS_SUCCESS:
		case fromCoreListings.CLUB_API_GET_USER_LISTINGS_SUCCESS: {
			const listings = action.payload.entities.listings;
			const entities = {
				...state.entities,
				...listings
			};

			return {
				...state,
				entities,
				callState: LoadingState.LOADED
			};
		}

		case fromCoreListings.CLUB_API_GET_USER_LISTING_FAIL:
		case fromCoreListings.CLUB_API_GET_USER_LISTINGS_FAIL: {
			return {
				...state,
				callState: { errorMessage: action.errorMessage }
			};
		}

		case fromCoreListings.CREATE_LISTING_PAGE_UNSET: {
			return {
				...state,
				createState: LoadingState.INIT
			};
		}

		case fromCoreListings.CLUB_API_GET_USER_LISTING_STATS: {
			return {
				...state,
				callStatsState: LoadingState.LOADING
			};
		}

		case fromCoreListings.CLUB_API_GET_USER_LISTING_STATS_SUCCESS: {
			return {
				...state,
				callStatsState: LoadingState.LOADED
			};
		}

		case fromCoreListings.CLUB_API_GET_USER_LISTING_STATS_FAIL: {
			return {
				...state,
				callStatsState: { errorMessage: action.errorMessage }
			};
		}

		case fromCoreListings.CLUB_API_POST_CREATE_LISTING: {
			return {
				...state,
				createState: LoadingState.LOADING
			};
		}

		case fromCoreListings.CLUB_API_POST_CREATE_LISTING_SUCCESS: {
			const listings = action.payload.entities.listings;
			const entities = {
				...state.entities,
				...listings
			};

			return {
				...state,
				entities,
				createState: LoadingState.LOADED
			};
		}

		case fromCoreListings.CLUB_API_POST_CREATE_LISTING_FAIL: {
			return {
				...state,
				createState: { errorMessage: action.errorMessage }
			};
		}

		case fromCoreListings.CLUB_API_DELETE_USER_LISTING: {
			return {
				...state,
				deleteState: LoadingState.LOADING
			};
		}

		case fromCoreListings.CLUB_API_DELETE_USER_LISTING_SUCCESS: {
			return {
				...state,
				deleteState: LoadingState.LOADED
			};
		}

		case fromCoreListings.CLUB_API_DELETE_USER_LISTING_FAIL: {
			return {
				...state,
				deleteState: { errorMessage: action.errorMessage }
			};
		}

		default:
			return state;
	}
}

export const getListingsEntities             	= (state: ListingState) => state.entities;
export const getListingsLoading     			= (state: ListingState) => state.callState === LoadingState.LOADING;
export const getListingsLoaded      			= (state: ListingState) => state.callState === LoadingState.LOADED;
export const getListingsError       			= (state: ListingState) => getError(state.callState);
export const getListingStatsLoading     		= (state: ListingState) => state.callStatsState === LoadingState.LOADING;
export const getListingStatsLoaded      		= (state: ListingState) => state.callStatsState === LoadingState.LOADED;
export const getListingStatsError       		= (state: ListingState) => getError(state.callStatsState);
export const getCreateListingLoading     		= (state: ListingState) => state.createState === LoadingState.LOADING;
export const getCreateListingLoaded      		= (state: ListingState) => state.createState === LoadingState.LOADED;
export const getCreateListingError       		= (state: ListingState) => getError(state.createState);
export const getUpdateListingLoading     		= (state: ListingState) => state.updateState === LoadingState.LOADING;
export const getUpdateListingLoaded      		= (state: ListingState) => state.updateState === LoadingState.LOADED;
export const getUpdateListingError       		= (state: ListingState) => getError(state.updateState);
export const getDeleteListingLoading     		= (state: ListingState) => state.deleteState === LoadingState.LOADING;
export const getDeleteListingLoaded      		= (state: ListingState) => state.deleteState === LoadingState.LOADED;
export const getDeleteListingError       		= (state: ListingState) => getError(state.deleteState);