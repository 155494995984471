import * as fromCoreMedia from '../actions/media.action';
import * as fromCoreAuth from '../actions/auth.action';
import * as fromCoreListings from '../actions/listings.action';
import * as fromCoreEscorts from '../actions/escorts.action';
import { Media } from 'src/library/features/models';

export interface MediaState {
	entities: { [uuid: string]: Media };
}

export const initialState: MediaState = {
	entities: {}
};

export function reducer(
	state = initialState,
	action:
		| fromCoreMedia.MediaAction
		| fromCoreAuth.AuthAction
		| fromCoreListings.ListingsAction
		| fromCoreEscorts.EscortsAction
): MediaState {
	switch (action.type) {
		case fromCoreAuth.CLUB_API_PUT_UPDATE_USER_SUCCESS:
		case fromCoreEscorts.CLUB_API_PUT_UPDATE_ESCORT_SUCCESS:
		case fromCoreListings.CLUB_API_GET_USER_LISTING_SUCCESS:
		case fromCoreListings.CLUB_API_GET_USER_LISTINGS_SUCCESS:
		case fromCoreListings.CLUB_API_POST_CREATE_LISTING_SUCCESS:
		case fromCoreAuth.CLUB_API_GET_AUTH_USER_DETAILS_SUCCESS: {
			const media = action.payload.entities.media;
			const entities = {
				...state.entities,
				...media
			};

			return {
				...state,
				entities
			};
		}

		default:
			return state;
	}
}

export const getMediaEntities = (state: MediaState) => state.entities;
