import { Action } from '@ngrx/store';
import { Error } from 'src/app/shared/services';
import { Listing, User } from 'src/library/features/models';

export const HEADER_NOTIFICATION_COMPONENT_WEBSOCKET_CONNECT    = '[Header Notification Component] Websocket Connect';
export const HOME_PAGE_WEBSOCKET_CONNECT                        = '[Home Page] Websocket Connect';
export const WEBSOCKET_SERVICE_WEBSOCKET_CONNECT                = '[Websocket Service] Websocket Connect';
export const WEBSOCKET_SERVICE_WEBSOCKET_CONNECTION_EXIST       = '[Websocket Service] Websocket Connection Exist';
export const WEBSOCKET_SERVICE_WEBSOCKET_CONNECT_RECONNECTING   = '[Websocket Service] Websocket Reconnecting';
export const WEBSOCKET_SERVICE_WEBSOCKET_CONNECT_SUCCESS        = '[Websocket Service] Websocket Connected';
export const WEBSOCKET_SERVICE_WEBSOCKET_CONNECT_FAIL           = '[Websocket Service] Websocket Fail to Connect';

export const HOME_PAGE_CHANNEL_LISTING_CONNECT                  = '[Home Page] Channel Listing Connect';
export const WEBSOCKET_SERVICE_CHANNEL_LISTING_CONNECT          = '[Websocket Service] Channel Listing Connect';
export const WEBSOCKET_SERVICE_CHANNEL_LISTING_CONNECTION_EXIST = '[Websocket Service] Channel Listing Connection Exist';
export const WEBSOCKET_SERVICE_GET_NEW_LISTING                  = '[Websocket Service] Get New Listing';
export const HOME_PAGE_CHANNEL_LISTING_DISCONNECT               = '[Home Page] Channel Listing Disconnect';

export const HEADER_NOTIFICATION_COMPONENT_CHANNEL_USER_CONNECT = '[Header Notification Component] Channel User Connect';
export const WEBSOCKET_SERVICE_CHANNEL_USER_CONNECT             = '[Websocket Service] Channel User Connect';
export const WEBSOCKET_SERVICE_CHANNEL_USER_CONNECTION_EXIST    = '[Websocket Service] Channel User Connection Exist';
export const WEBSOCKET_SERVICE_GET_NEW_NOTIFICATION             = '[Websocket Service] Get New Notification';

export class HeaderNotificationComponentWebSocketConnect implements Action {
    readonly type = HEADER_NOTIFICATION_COMPONENT_WEBSOCKET_CONNECT;
    constructor() { }
}

export class HomePageWebSocketConnect implements Action {
    readonly type = HOME_PAGE_WEBSOCKET_CONNECT;
    constructor() { }
}

export class WebSocketServiceWebSocketConnect implements Action {
    readonly type = WEBSOCKET_SERVICE_WEBSOCKET_CONNECT;
    constructor() { }
}

export class WebSocketServiceWebSocketConnectionExist implements Action {
    readonly type = WEBSOCKET_SERVICE_WEBSOCKET_CONNECTION_EXIST;
    constructor() { }
}

export class WebSocketServiceWebSocketConnectReconnecting implements Action {
    readonly type = WEBSOCKET_SERVICE_WEBSOCKET_CONNECT_RECONNECTING;
    constructor() { }
}

export class WebSocketServiceWebSocketConnectSuccess implements Action {
    readonly type = WEBSOCKET_SERVICE_WEBSOCKET_CONNECT_SUCCESS;
    constructor() { }
}

export class WebSocketServiceWebSocketConnectFail implements Action {
    readonly type = WEBSOCKET_SERVICE_WEBSOCKET_CONNECT_FAIL;
    constructor(public errorMessage: Error) { }
}

export class HomePageChannelListingConnect implements Action {
    readonly type = HOME_PAGE_CHANNEL_LISTING_CONNECT;
    constructor() { }
}
export class WebSocketServiceChannelListingConnect implements Action {
    readonly type = WEBSOCKET_SERVICE_CHANNEL_LISTING_CONNECT;
    constructor() { }
}

export class WebSocketServiceChannelListingConnectionExist implements Action {
    readonly type = WEBSOCKET_SERVICE_CHANNEL_LISTING_CONNECTION_EXIST;
    constructor() { }
}

export class WebSocketServiceGetNewListing implements Action {
    readonly type = WEBSOCKET_SERVICE_GET_NEW_LISTING;
    constructor(public payload: any ) { }
}

export class HomePageChannelListingDisconnect implements Action {
    readonly type = HOME_PAGE_CHANNEL_LISTING_DISCONNECT;
    constructor() { }
}

export class HeaderNotificationComponentChannelUserConnect implements Action {
    readonly type = HEADER_NOTIFICATION_COMPONENT_CHANNEL_USER_CONNECT;
    constructor() { }
}
export class WebSocketServiceChannelUserConnect implements Action {
    readonly type = WEBSOCKET_SERVICE_CHANNEL_USER_CONNECT;
    constructor(public payload: User['UUID']) { }
}

export class WebSocketServiceChannelUserConnectionExist implements Action {
    readonly type = WEBSOCKET_SERVICE_CHANNEL_USER_CONNECTION_EXIST;
    constructor() { }
}

export class WebSocketServiceGetNewNotification implements Action {
    readonly type = WEBSOCKET_SERVICE_GET_NEW_NOTIFICATION;
    constructor(public payload: any ) { }
}


// action types
export type SocketAction =
    | HeaderNotificationComponentWebSocketConnect
    | HomePageWebSocketConnect
    | WebSocketServiceWebSocketConnect
    | WebSocketServiceWebSocketConnectionExist
    | WebSocketServiceWebSocketConnectReconnecting
    | WebSocketServiceWebSocketConnectSuccess
    | WebSocketServiceWebSocketConnectFail
    | HomePageChannelListingConnect
    | WebSocketServiceChannelListingConnect
    | WebSocketServiceChannelListingConnectionExist
    | WebSocketServiceGetNewListing
    | HomePageChannelListingDisconnect
    | HeaderNotificationComponentChannelUserConnect
    | WebSocketServiceChannelUserConnect
    | WebSocketServiceChannelUserConnectionExist
    | WebSocketServiceGetNewNotification
    ;
