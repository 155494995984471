import {
	HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import {
	CustomHttpService,
	ErrorService,
	LogService
} from 'src/app/shared/services';
import {
	ClubAPIResponseService
} from './api-response.service';
import { environment } from 'src/environments/environment';
import { User } from '../models';

@Injectable({
	providedIn: 'root'
})
export class UsersService {
	// API environment variables
	private ENV = `${environment.apiUrl}/${environment.apiVersion}`;
	// API Request information
	private API_END_POINT = 'users'; // * Modify to change API path

	private URL = `${this.ENV}/${this.API_END_POINT}`;
	private SERVICE_NAME = `API (${this.API_END_POINT})`;

	constructor(
		private http: CustomHttpService,
		private logService: LogService,
		private errorService: ErrorService
	) {}

	private log(message: string) {
		this.logService.add(`${this.SERVICE_NAME}: ${message}`, 'success');
	}

	/**
	 * Get Users
	 */
	getUsers(): Observable<User[]> {
		const url = `${this.URL}`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<User[]>(
					res,
					'User',
					true
				)
			),
			tap((_) => this.log(`getUsers success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getUsers'
				)
			)
		);
	}

	/**
	 * Get User by id
	 * @param id - id
	 */
	getUser(id: number): Observable<User> {
		const url = `${this.URL}/${id}`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<User>(
					res,
					'User',
					false
				)
			),
			tap((_) => this.log(`getUser success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getUser'
				)
			)
		);
	}
}
