// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: true,
	apiUrl: 'https://api.dev.club521.com',
	apiVersion: 'api',
	channelKey: '24acaf9e4566afaaf00e',
	defaultAvatarURL: '../../../../../assets/icon/default-avatar.svg',
	socketUrl: 'api.dev.club521.com',
	socketPort: 443,
	unixBaseTime: 1560038400,
	gTag: 'G-1778HJD45S'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
