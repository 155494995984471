import {
	createSelector
} from '@ngrx/store';

import * as fromRoot from '../../../../app/store';
import { Media, Listing } from '../../models';
import * as fromFeature from '../reducers';
import * as fromListings from '../reducers/listings.reducer';

import { getMediaEntities } from './media.selectors';
import { getUsersEntities } from './users.selectors';
import { getEscortsEntities } from './escorts.selectors';

function checkEqual(a, b) {
	return (
		a && b && a.length === b.length && a.every((val, idx) => val === b[idx])
	);
}

export const getListingState = createSelector(
	fromFeature.getFeaturesState,
	(state: fromFeature.FeaturesState) => state.listings
);

export const getListingsEntities = createSelector(
	getListingState,
	fromListings.getListingsEntities
);

export const getListingsLoaded = createSelector(
	getListingState,
	fromListings.getListingsLoaded
);

export const getListingsLoading = createSelector(
	getListingState,
	fromListings.getListingsLoading
);

export const getListingsError = createSelector(
	getListingState,
	fromListings.getListingsError
);

export const getListingsList = createSelector(getListingsEntities, (entities) =>
	Object.keys(entities).map((key) => entities[key])
);

// Get Denormalized listings
export const getListingsView = createSelector(
	getListingsList,
	getMediaEntities,
	(listings, media): Listing[] =>
		listings.map(
			(listing) =>
				listing && {
					...listing,
					media: (listing.media as string[]).map(
						(mediaUUID) => media[mediaUUID]
					)
				}
		)
);

export const getListingByRouteUUID = createSelector(
	getListingsEntities,
	fromRoot.getRouterState,
	(listings, router): Listing =>
		router.state && listings[router.state.params.uuid]
);


// Get Denormalized listing by Route UUID
export const getListingByRouteIDView = createSelector(
	getListingByRouteUUID,
	getMediaEntities,
	getUsersEntities,
	getEscortsEntities,
	(listing: Listing, medias, users, escorts): Listing => {
		return listing && {
			...listing,
			media: (listing.media as string[]).map((uuid) => medias[uuid]),
			user: users[listing.user as string] && {
				...users[listing.user as string],
				avatar: medias[users[listing.user as string].avatar as string],
				escort: escorts[users[listing.user as string].escort as string] && {
					...escorts[users[listing.user as string].escort as string],
					avatar: medias[escorts[users[listing.user as string].escort as string].avatar as string],
					banner: medias[escorts[users[listing.user as string].escort as string].banner as string],
					galleries: (escorts[users[listing.user as string].escort as string].galleries).map((gallery) => {
						return gallery && {
							...gallery,
							media: (gallery.media as string[]).map((galleryMedia) => medias[galleryMedia]),
							thumbnail: medias[gallery.thumbnail as string]
						}
					})
				}
			}
		}
	}
);
