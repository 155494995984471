import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromAuth from './auth.reducer';
import * as fromMedia from './media.reducer';
import * as fromEscorts from './escorts.reducer';
import * as fromListings from './listings.reducer';
import * as fromNotifications from './notifications.reducer';

export interface CoreState {
	auth: fromAuth.AuthState;
	media: fromMedia.MediaState;
	escorts: fromEscorts.EscortState;
	listings: fromListings.ListingState;
	notifications: fromNotifications.NotificationState;
}

export const reducers: ActionReducerMap<CoreState> = {
	auth: fromAuth.reducer,
	media: fromMedia.reducer,
	escorts: fromEscorts.reducer,
	listings: fromListings.reducer,
	notifications: fromNotifications.reducer
};

export const getCoreState = createFeatureSelector<CoreState>('core');
