import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

import { Store } from '@ngrx/store';
import * as fromUI from 'src/library/ui/store';

@Component({
	selector: 'ftoclub-home-listing-new-listing-snackbar-view',
	templateUrl: './home-listing-new-listing-snackbar-view.component.html',
	styleUrls: ['./home-listing-new-listing-snackbar-view.component.scss']
})
export class HomeListingNewListingSnackbarViewComponent implements OnInit {
	listingQueueMedia$ = this.UIStore.select(fromUI.getHomeNewListingMedia);
	constructor(
		private UIStore: Store<fromUI.UIState>,
		public snackBarRef: MatSnackBarRef<HomeListingNewListingSnackbarViewComponent>
	) {}

	ngOnInit(): void {}

	clickShowListing(): void {
		this.UIStore.dispatch(new fromUI.HomePageViewNewListingsQueue());
		this.snackBarRef.dismiss();
	}
}
