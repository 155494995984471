import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromHome from './home.reducer';
import * as fromListingSearch from './listing-search.reducer';
import * as fromGlobal from './global.reducer';
import * as fromSocket from './socket.reducer';
import * as fromUserEscortListing from './user-escort-listing.reducer';
import * as fromUserEscortListingProfile from './user-escort-listing-profile.reducer';
import * as fromUserNotification from './user-notification.reducer';

export interface UIState {
	home: fromHome.HomeState;
	listingSearch: fromListingSearch.ListingSearchState;
	global: fromGlobal.GlobalState;
	socket: fromSocket.SocketState;
	// media: fromMedia.MediaState;
	userEscortListing: fromUserEscortListing.UserEscortListingState;
	userEscortListingProfile: fromUserEscortListingProfile.UserEscortListingProfileState;
	userNotification: fromUserNotification.UserNotificationState;
}

export const reducers: ActionReducerMap<UIState> = {
	home: fromHome.reducer,
	listingSearch: fromListingSearch.reducer,
	global: fromGlobal.reducer,
	socket: fromSocket.reducer,
	// media: fromMedia.reducer,
	userEscortListing: fromUserEscortListing.reducer,
	userEscortListingProfile: fromUserEscortListingProfile.reducer,
	userNotification: fromUserNotification.reducer
};

export const getUIState = createFeatureSelector<UIState>('ui');
