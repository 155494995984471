import { Action } from '@ngrx/store';
import { SearchListingRequest } from 'src/library/features/services';

export const LISTING_SEARCH_PAGE_SEARCH_PARAMS_CHANGED                      = '[Listing Search Page] Search Params Changed';
export const LISTING_SEARCH_PAGE_SEARCH_PARAMS_UNCHANGED                    = '[Listing Search Page] Search Params Unchanged';
export const LISTING_SEARCH_PAGE_RESET_LISTING_PAGE                         = '[Listing Search Page] Reset Listing Page';
export const LISTING_SEARCH_PAGE_RESET_LISTING_PAGE_SUCCESS                 = '[Listing Search Page] Reset Listing Page Success';
export const LISTING_SEARCH_PAGE_INCREMENT_LISTING_PAGE                     = '[Listing Search Page] Increment Listing Page';
export const LISTING_SEARCH_PAGE_INCREMENT_LISTING_PAGE_SUCCESS             = '[Listing Search Page] Increment Listing Page Success';

export const LISTING_SEARCH_PAGE_UPDATE_SEARCH_PARAMS                       = '[Listing Search Page] Update Search Parms';
export const LISTING_SEARCH_PAGE_CLEAR_SEARCH_PARAMS                        = '[Listing Search Page] Clear Search Parms';

export const LISTING_SEARCH_PAGE_GET_TOTAL_LISTINGS                         = '[Listing Search Page] Get Total Listings';

export class ListingSearchPageSearchParamsChanged implements Action {
    readonly type = LISTING_SEARCH_PAGE_SEARCH_PARAMS_CHANGED;
    constructor(public payload: { req: SearchListingRequest, page: number }) { }
}
export class ListingSearchPageSearchParamsUnchanged implements Action {
    readonly type = LISTING_SEARCH_PAGE_SEARCH_PARAMS_UNCHANGED;
    constructor(public payload: { req: SearchListingRequest, page: number }) { }
}

export class ListingSearchPageResetListingPage implements Action {
    readonly type = LISTING_SEARCH_PAGE_RESET_LISTING_PAGE;
    constructor() { }
}

export class ListingSearchPageResetListingPageSuccess implements Action {
    readonly type = LISTING_SEARCH_PAGE_RESET_LISTING_PAGE_SUCCESS;
    constructor(public payload: number) { }
}

export class ListingSearchPageIncrementListingPage implements Action {
    readonly type = LISTING_SEARCH_PAGE_INCREMENT_LISTING_PAGE;
    constructor() { }
}

export class ListingSearchPageIncrementListingPageSuccess implements Action {
    readonly type = LISTING_SEARCH_PAGE_INCREMENT_LISTING_PAGE_SUCCESS;
    constructor(public payload: { URI: string, page: number }) { }
}

export class ListingSearchPageUpdateSearchParams implements Action {
    readonly type = LISTING_SEARCH_PAGE_UPDATE_SEARCH_PARAMS;
    constructor(public payload: SearchListingRequest) { }
}

export class ListingSearchPageClearSearchParams implements Action {
    readonly type = LISTING_SEARCH_PAGE_CLEAR_SEARCH_PARAMS;
    constructor() { }
}

export class ListingSearchPageGetTotalListings implements Action {
    readonly type = LISTING_SEARCH_PAGE_GET_TOTAL_LISTINGS;
    constructor(public payload: number) { }
}


// action types
export type ListingSearchAction =
    | ListingSearchPageSearchParamsChanged
    | ListingSearchPageSearchParamsUnchanged
    | ListingSearchPageResetListingPage
    | ListingSearchPageResetListingPageSuccess
    | ListingSearchPageIncrementListingPage
    | ListingSearchPageIncrementListingPageSuccess
    | ListingSearchPageUpdateSearchParams
    | ListingSearchPageClearSearchParams
    | ListingSearchPageGetTotalListings
    ;
