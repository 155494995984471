import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import {
	ClubAPIAuthResponseService,
	ClubAPIAuthResponse
} from './api-response.service';
import {
	LogService,
	ErrorService,
	CustomHttpService
} from 'src/app/shared/services';

export interface LoginContext {
	email: string;
	password: string;
	remember?: boolean;
}
@Injectable({
	providedIn: 'root'
})
export class AuthService {
	// API environment variables
	private ENV = `${environment.apiUrl}/${environment.apiVersion}`;
	// API Request information
	private API_LOGIN_END_POINT = 'login'; // * Modify to change API path
	private API_LOGOUT_END_POINT = 'logout'; // * Modify to change API path

	private LOGIN_URL = `${this.ENV}/${this.API_LOGIN_END_POINT}`;
	private LOGOUT_URL = `${this.ENV}/${this.API_LOGOUT_END_POINT}`;
	private SERVICE_NAME = `API AUTH`;

	constructor(
		private http: CustomHttpService,
		private logService: LogService,
		private errorService: ErrorService
	) {}

	private log(message: string) {
		this.logService.add(`${this.SERVICE_NAME}: ${message}`, 'success');
	}

	/**
	 * Login the user. The grab the auth user details
	 *
	 * @param loginContext Login details to be used to log the user in.
	 * @param redirect URI to redirect after login if exists
	 */
	login(loginContext: LoginContext, redirect?: string): Observable<any> {
		return this.http
			.post<HttpResponse<ClubAPIAuthResponse>>(
				this.LOGIN_URL,
				loginContext
			)
			.pipe(
				map((res) =>
					ClubAPIAuthResponseService.validateResponse(res)
				),
				tap((_) => this.log(`Logged in Successfully.`)),
				catchError((res: HttpErrorResponse) =>
					this.errorService.handleAPIHttpError(
						res,
						this.SERVICE_NAME,
						'login'
					)
				)
			);
	}

	/**
	 * Logout the user.
	 *
	 */
	logout(): Observable<any> {
		return this.http.post<any>(this.LOGOUT_URL, '').pipe(
			tap((_) => this.log(`Logged out Successfully.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'logout'
				)
			)
		);
	}
}
