import { createSelector } from '@ngrx/store';

import * as fromUI from '../reducers';
import * as fromGlobal from '../reducers/global.reducer';

export const getGlobalState = createSelector(
	fromUI.getUIState,
	(state: fromUI.UIState) => state.global
);

export const getGlobalLanguageType = createSelector(
	getGlobalState,
	fromGlobal.getGlobalLanguageType
);
