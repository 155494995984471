<ng-container *transloco="let t; read: 'home'">
	<div class="container">
		<div class="content" (click)="clickShowListing()">
			<div
				class="listing-avatar"
				[ngClass]="{ 
                small: (listingQueueMedia$ | async).length === 1,
                medium: (listingQueueMedia$ | async).length === 2,
                large: (listingQueueMedia$ | async).length >= 3}"
			>
				<div
					class="avatar-more"
					*ngIf="(listingQueueMedia$ | async).length > 3"
				>
					<div
						class="avatar-text"
						*ngIf="(listingQueueMedia$ | async).length < 101; else maxListings"
					>
						+{{ (listingQueueMedia$ | async).length -2 }}
					</div>
					<ng-template #maxListings>
						<div class="avatar-text">+99</div>
					</ng-template>
				</div>
				<ng-container
					*ngFor="let media of listingQueueMedia$ | async; let i = index"
				>
					<div
						class="avatar"
						[ngStyle]="{ 'z-index': 100 - i, 'left': (i * 15) + 'px', 'opacity': (1 - i * 0.2)}"
						*ngIf="3 > i"
					>
						<div
							class="image-content"
							[ngStyle]="{ 'background-image': 'url(' + media.url + ')' }"
						></div>
					</div>
				</ng-container>
			</div>
            <div class="listing-icon">
                <mat-icon>north</mat-icon>
            </div>
			<div class="listing-text">{{ t('newListing', {'listingNo': (listingQueueMedia$ | async).length}) }}</div>
		</div>
	</div>
</ng-container>
