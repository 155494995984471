import { LoadingState, ErrorState, CallState, getError } from './call-state';

import * as fromGlobal from '../actions/global.action';
import { LanguageTypeValue } from 'src/library/features/models';

export interface GlobalState {
	language: LanguageTypeValue;
}

export const initialState: GlobalState = {
	language: null
};

export function reducer(
	state = initialState,
	action: fromGlobal.GlobalAction
): GlobalState {
	switch (action.type) {
		case fromGlobal.COOKIE_STORAGE_SET_LANGUAGE_SUCCESS_RELOAD_PAGE:
		case fromGlobal.COOKIE_STORAGE_SET_LANGUAGE_SUCCESS: {
			const language = action.payload;
			return {
				...state,
				language
			};
		}

		default:
			return state;
	}
}

export const getGlobalLanguageType = (state: GlobalState) => state.language;
