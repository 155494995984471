import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
	catchError,
	filter,
	map,
	switchMap,
	tap,
	withLatestFrom
} from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import { Store } from '@ngrx/store';

import * as suburbActions from '../actions/suburbs.action';
import * as fromServices from '../../services';
import * as fromRoot from 'src/app/store';

import { normalize } from 'normalizr';
import * as fromSchema from '../../models/schema.model';

@Injectable()
export class SuburbsEffects {
	constructor(
		private actions$: Actions,
		private suburbsService: fromServices.SuburbsService,
		private store: Store<fromRoot.State>
	) {}

	// Search for Suburbs
	searchSuburbs$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(suburbActions.LOCATION_SELECTOR_COMPONENT_SEARCH_SUBURB),
			map(
				(
					action: suburbActions.LocationSelectorComponentSearchSuburb
				) => {
					return new suburbActions.ClubApiSearchSuburb(
						action.payload
					);
				}
			)
		);
	});

	// get Suburbs from API
	getSuburbsBySearch$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(suburbActions.CLUB_API_SEARCH_SUBURB),
			switchMap((action: suburbActions.ClubApiSearchSuburb) => {
				return this.suburbsService.searchSuburbs(action.payload).pipe(
					map((suburbs) => {
						const normalizedData = normalize(suburbs, [
							fromSchema.suburb
						]);
						return new suburbActions.ClubApiSearchSuburbSuccess(
							normalizedData
						);
					}),
					catchError((error) => {
						return of(
							new suburbActions.ClubApiSearchSuburbFail(error)
						);
					})
				);
			})
		);
	});
/* 
	// Clear Suburb Search Values
	clearCurrentSuburbSearch$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(suburbActions.LOCATION_SELECTOR_COMPONENT_CLEAR_SEARCH)
			),
		{ dispatch: false }
	); */
}
