import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import {
	CustomHttpService,
	ErrorService,
	LogService
} from 'src/app/shared/services';
import { ClubAPIResponseService } from './api-response.service';
import { environment } from 'src/environments/environment';
import { EscortTagType } from '../models';

@Injectable({
	providedIn: 'root'
})
export class EscortTagsService {
	// API environment variables
	private ENV = `${environment.apiUrl}/${environment.apiVersion}`;
	// API Request information
	private API_END_POINT = 'tags'; // * Modify to change API path

	private URL = `${this.ENV}/${this.API_END_POINT}`;
	private SERVICE_NAME = `API (${this.API_END_POINT})`;

	constructor(
		private http: CustomHttpService,
		private logService: LogService,
		private errorService: ErrorService
	) {}

	private log(message: string) {
		this.logService.add(`${this.SERVICE_NAME}: ${message}`, 'success');
	}

	/**
	 * Get Ethnicities
	 */
	getEthnicities(): Observable<EscortTagType[]> {
		const url = `${this.URL}/ethnicities`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<EscortTagType[]>(
					res,
					'EscortTagType',
					true
				)
			),
			tap((_) => this.log(`getEthnicities success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getEthnicities'
				)
			)
		);
	}

	/**
	 * Get Hair Colours
	 */
	getHairColours(): Observable<EscortTagType[]> {
		const url = `${this.URL}/hair_colours`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<EscortTagType[]>(
					res,
					'EscortTagType',
					true
				)
			),
			tap((_) => this.log(`getHairColours success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getHairColours'
				)
			)
		);
	}

	/**
	 * Get Eye Colours
	 */
	getEyeColours(): Observable<EscortTagType[]> {
		const url = `${this.URL}/eye_colours`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<EscortTagType[]>(
					res,
					'EscortTagType',
					true
				)
			),
			tap((_) => this.log(`getEyeColours success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getEyeColours'
				)
			)
		);
	}

	/**
	 * Get Genders
	 */
	getGenders(): Observable<EscortTagType[]> {
		const url = `${this.URL}/sexes`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<EscortTagType[]>(
					res,
					'EscortTagType',
					true
				)
			),
			tap((_) => this.log(`getGenders success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getGenders'
				)
			)
		);
	}

	/**
	 * Get Sexual Preferences
	 */
	getSexualPreferences(): Observable<EscortTagType[]> {
		const url = `${this.URL}/sex_preferences`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<EscortTagType[]>(
					res,
					'EscortTagType',
					true
				)
			),
			tap((_) => this.log(`getSexualPreferences success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getSexualPreferences'
				)
			)
		);
	}

	/**
	 * Get Body Types
	 */
	getBodyTypes(): Observable<EscortTagType[]> {
		const url = `${this.URL}/body_types`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<EscortTagType[]>(
					res,
					'EscortTagType',
					true
				)
			),
			tap((_) => this.log(`getBodyTypes success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getBodyTypes'
				)
			)
		);
	}

	/**
	 * Get Spoken languages
	 */
	getSpokenLanguages(): Observable<EscortTagType[]> {
		const url = `${this.URL}/spoken_languages`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<EscortTagType[]>(
					res,
					'EscortTagType',
					true
				)
			),
			tap((_) => this.log(`getSpokenLanguages success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getSpokenLanguages'
				)
			)
		);
	}

	/**
	 * Get Plays
	 */
	getPlays(): Observable<EscortTagType[]> {
		const url = `${this.URL}/plays`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<EscortTagType[]>(
					res,
					'EscortTagType',
					true
				)
			),
			tap((_) => this.log(`getPlays success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getPlays'
				)
			)
		);
	}

	/**
	 * Get Masturbation Plays
	 */
	getMasturbationPlays(): Observable<EscortTagType[]> {
		const url = `${this.URL}/masturbation_plays`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<EscortTagType[]>(
					res,
					'EscortTagType',
					true
				)
			),
			tap((_) => this.log(`getMasturbationPlays success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getMasturbationPlays'
				)
			)
		);
	}

	/**
	 * Get Oral Plays
	 */
	getOralPlays(): Observable<EscortTagType[]> {
		const url = `${this.URL}/oral_plays`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<EscortTagType[]>(
					res,
					'EscortTagType',
					true
				)
			),
			tap((_) => this.log(`getOralPlays success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getOralPlays'
				)
			)
		);
	}

	/**
	 * Get Ejaculation Plays
	 */
	getEjaculationPlays(): Observable<EscortTagType[]> {
		const url = `${this.URL}/ejaculation_plays`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<EscortTagType[]>(
					res,
					'EscortTagType',
					true
				)
			),
			tap((_) => this.log(`getEjaculationPlays success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getEjaculationPlays'
				)
			)
		);
	}

	/**
	 * Get Service Plays
	 */
	getServicePlays(): Observable<EscortTagType[]> {
		const url = `${this.URL}/service_plays`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<EscortTagType[]>(
					res,
					'EscortTagType',
					true
				)
			),
			tap((_) => this.log(`getServicePlays success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getServicePlays'
				)
			)
		);
	}

	/**
	 * Get Experience Plays
	 */
	getExperiencePlays(): Observable<EscortTagType[]> {
		const url = `${this.URL}/experience_plays`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<EscortTagType[]>(
					res,
					'EscortTagType',
					true
				)
			),
			tap((_) => this.log(`getExperiencePlays success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getExperiencePlays'
				)
			)
		);
	}

	/**
	 * Get Other Plays
	 */
	getOtherPlays(): Observable<EscortTagType[]> {
		const url = `${this.URL}/other_plays`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<EscortTagType[]>(
					res,
					'EscortTagType',
					true
				)
			),
			tap((_) => this.log(`getOtherPlays success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getOtherPlays'
				)
			)
		);
	}
}
