import * as fromUsers from '../actions/users.action';
import * as fromListings from '../actions/listings.action';

import { LoadingState, ErrorState, CallState, getError } from './call-state';

import { User } from '../../models/';

export interface UserState {
	entities: { [UUID: string]: User };
	callState: CallState;
}

export const initialState: UserState = {
	entities: {},
	callState: LoadingState.INIT
};

export function reducer(
	state = initialState,
	action: fromUsers.UsersAction | fromListings.ListingsAction
): UserState {
	switch (action.type) {
		case fromUsers.USER_PROFILE_EDIT_PAGE_FETCH_USER_FROM_ROUTE:
		case fromUsers.PROFILE_PAGE_GUARD_FETCH_USER_FROM_ROUTE: {
			return {
				...state,
				callState: LoadingState.LOADING
			};
		}

		case fromListings.CLUB_API_GET_LISTING_SUCCESS:
		case fromUsers.FETCH_USERS_SUCCESS:
		case fromUsers.CLUB_API_GET_USER_SUCCESS: {
			const users = action.payload.entities.users;
			const entities = {
				...state.entities,
				...users
			};

			return {
				...state,
				callState: LoadingState.LOADED,
				entities
			};
		}

		case fromUsers.CLUB_API_GET_USER_FAIL: {
			return {
				...state,
				callState: { errorMessage: action.errorMessage }
			};
		}

		/*         case fromAuthPet.CLUB_API_DELETE_AUTH_PET_SUCCESS: {
            const petId = action.payload;
            const entities = {
                ...state.entities
            };
            // maps each entitiy object and remove the reference entity
            Object.keys(entities).map(userKey => {
                entities[userKey] = {
                    ...entities[userKey],
                    pets: entities[userKey].pets.filter((id: number) => id !== +petId)
                };
            });

            return {
                ...state,
                callState: LoadingState.LOADED,
                entities
            };
        } */

		default:
			return state;
	}
}

export const getUsersLoading = (state: UserState) =>
	state.callState === LoadingState.LOADING;
export const getUsersLoaded = (state: UserState) =>
	state.callState === LoadingState.LOADED;
export const getUsersError = (state: UserState) => getError(state.callState);
export const getUsersEntities = (state: UserState) => state.entities;
