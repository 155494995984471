import { Action } from '@ngrx/store';

export const HEADER_NOTIFICATION_COMPONENT_OPEN_COMPONENT                               = '[Header Notification Component] Open Component';

export class HeaderNotificationComponentOpenComponent implements Action {
    readonly type = HEADER_NOTIFICATION_COMPONENT_OPEN_COMPONENT;
    constructor() { }
}



// action types
export type UserNotificationAction =
    | HeaderNotificationComponentOpenComponent
    ;
