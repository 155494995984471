import { Error } from "src/app/shared/services";

export const enum LoadingState {
    INIT = 'INIT',
    LOADING = 'LOADING',
    LOADED = 'LOADED',
}
export interface ErrorState {
    errorMessage: string | Error;
}

export type CallState = LoadingState | ErrorState;

// Helper function to extract error, if there is one.
export function getError(callState: CallState): string | Error | null {
    if ((callState as ErrorState).errorMessage !== undefined) {
        return (callState as ErrorState).errorMessage;
    }
    return null;
}
