import { LoadingState, ErrorState, CallState, getError } from './call-state';

import * as fromCoreListings from 'src/app/core/store/actions/listings.action';
import * as fromUserEscortListingProfile from '../actions/user-escort-listing-profile.action';
import { UserListingStatsRequestPeriod } from 'src/library/features/services';

export interface UserEscortListingProfileState {
	chartState: CallState;
	stats: any[];
	statsDuration: UserListingStatsRequestPeriod;
	viewTotal: {
		count: number;
		rateOfChange: number;
	};
	cardState: CallState;
	health: number;
}

export const initialState: UserEscortListingProfileState = {
	chartState: LoadingState.INIT,
	stats: [],
	statsDuration: null,
	viewTotal: null,
	cardState: LoadingState.INIT,
	health: null

};

export function reducer(
	state = initialState,
	action: fromUserEscortListingProfile.UserEscorrtListingProfileAction | fromCoreListings.ListingsAction
): UserEscortListingProfileState {
	switch (action.type) {

		case fromUserEscortListingProfile.USER_LISTING_PROFILE_PAGE_INITIALISE_PAGE: {
			return {
				...initialState
			}
		}

		case fromCoreListings.CLUB_API_GET_USER_LISTING_STATS: {
			const duration = action.payload.req.period;
			return {
				...state,
				statsDuration: duration,
				chartState: LoadingState.LOADING,
				cardState: state.viewTotal ? LoadingState.LOADED : LoadingState.LOADING
			};
		}

		case fromUserEscortListingProfile.USER_LISTING_PROFILE_PAGE_CALCULATE_VIEW_GRAPH: {
			const stats = action.payload;
			return {
				...state,
				stats,
				chartState: LoadingState.LOADED
			}
		}

		case fromCoreListings.CLUB_API_GET_USER_LISTING_STATS_FAIL: {
			return {
				...state,
				chartState: { errorMessage: action.errorMessage },
				cardState: state.viewTotal ? LoadingState.LOADED : { errorMessage: action.errorMessage }
			};
		}

		case fromUserEscortListingProfile.USER_LISTING_PROFILE_PAGE_CALCULATE_VIEW_CARD: {
			const viewTotal = action.payload;
			return {
				...state,
				viewTotal,
				cardState: LoadingState.LOADED
			}
		}

		case fromCoreListings.CLUB_API_GET_USER_LISTING_SUCCESS: {
			const listings = action.payload.entities.listings
			const listing = listings[Object.keys(listings)[0]];
			const health = listing.health;
			return {
				...state,
				health
			};
		}

		default:
			return state;
	}
}

export const getListingProfileStats         	= (state: UserEscortListingProfileState) => state.stats;
export const getListingProfileStatsDuration     = (state: UserEscortListingProfileState) => state.statsDuration;
export const getListingViewChartLoading     	= (state: UserEscortListingProfileState) => state.chartState === LoadingState.LOADING;
export const getListingViewChartLoaded      	= (state: UserEscortListingProfileState) => state.chartState === LoadingState.LOADED;
export const getListingViewChartError       	= (state: UserEscortListingProfileState) => getError(state.chartState);
export const getListingViewTotal         		= (state: UserEscortListingProfileState) => state.viewTotal;
export const getListingViewCardLoading     		= (state: UserEscortListingProfileState) => state.cardState === LoadingState.LOADING;
export const getListingViewCardLoaded      		= (state: UserEscortListingProfileState) => state.cardState === LoadingState.LOADED;
export const getListingViewCardError       		= (state: UserEscortListingProfileState) => getError(state.cardState);
