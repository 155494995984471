import { UsersEffects } from './users.effect';
import { ListingsEffects } from './listings.effect';
import { EscortsEffects } from './escorts.effect';
import { SuburbsEffects } from './suburbs.effect';
import { EscortTagsEffects } from './escort-tags.effect';
import { ReportsEffects } from './reports.effect';

export const effects: any[] = [UsersEffects, ListingsEffects, EscortsEffects, SuburbsEffects, EscortTagsEffects, ReportsEffects];

export * from './users.effect';
export * from './listings.effect';
export * from './escorts.effect';
export * from './suburbs.effect';
export * from './escort-tags.effect';
export * from './reports.effect';