import { Action } from '@ngrx/store';
import { Error } from 'src/app/shared/services';
import { SearchListingRequest } from '../../services';


export const HOME_PAGE_FETCH_LATEST_LISTINGS                        = '[Home Page] Fetch Latest Listings';
export const CLUB_API_GET_LATEST_LISTINGS                           = '[Club API] Get Latest Listings';
export const CLUB_API_GET_LATEST_LISTINGS_FAIL                      = '[Club API] Get Latest Listings Fail';
export const CLUB_API_GET_LATEST_LISTINGS_SUCCESS                   = '[Club API] Get Latest Listings Success';
export const HOME_PAGE_FETCH_MORE_LISTINGS                          = '[Home Page] Fetch more Listings'

export const LISTING_PROFILE_PAGE_FETCH_LISTING                     = '[Listing Profile Page] Fetch Listing'
export const CLUB_API_GET_LISTING                                   = '[Club API] Get Listing'
export const CLUB_API_GET_LISTING_FAIL                              = '[Club API] Get Listing Fail'
export const CLUB_API_GET_LISTING_SUCCESS                           = '[Club API] Get Listing Success'

export const LISTING_SEARCH_PAGE_FETCH_LISTINGS                     = '[Listing Search Page] Fetch Search Listings';
export const CLUB_API_POST_SEARCH_LISTINGS                          = '[Club API] Post Search Listings';
export const CLUB_API_POST_SEARCH_LISTINGS_FAIL                     = '[Club API] Post Search Listings Fail';
export const CLUB_API_POST_SEARCH_LISTINGS_RESPONSE                 = '[Club API] Post Search Listings Get Success Response';
export const CLUB_API_POST_SEARCH_LISTINGS_SUCCESS                  = '[Club API] Post Search Listings Success';
export const LISTING_SEARCH_PAGE_FETCH_MORE_SEARCH_LISTINGS         = '[Listing Search Page] Fetch more Search Listings'

export class HomePageFetchLatestListings implements Action {
    readonly type = HOME_PAGE_FETCH_LATEST_LISTINGS;
    constructor() { }
}
export class ClubApiGetLatestListings implements Action {
    readonly type = CLUB_API_GET_LATEST_LISTINGS;
    constructor(public payload: number) { }}

export class ClubApiGetLatestListingsFail implements Action {
    readonly type = CLUB_API_GET_LATEST_LISTINGS_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiGetLatestListingsSuccess implements Action {
    readonly type = CLUB_API_GET_LATEST_LISTINGS_SUCCESS;
    constructor(public payload: any) {}
}

export class HomePageFetchMoreListings implements Action {
    readonly type = HOME_PAGE_FETCH_MORE_LISTINGS;
    constructor(public payload?: string) {}
}

export class ListingProfilePageFetchListing implements Action {
    readonly type = LISTING_PROFILE_PAGE_FETCH_LISTING;
    constructor() {}
}

export class ClubApiGetListing implements Action {
    readonly type = CLUB_API_GET_LISTING;
    constructor(public payload: string) { }}

export class ClubApiGetListingFail implements Action {
    readonly type = CLUB_API_GET_LISTING_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiGetListingSuccess implements Action {
    readonly type = CLUB_API_GET_LISTING_SUCCESS;
    constructor(public payload: any) {}
}

export class ListingSearchPageFetchListings implements Action {
    readonly type = LISTING_SEARCH_PAGE_FETCH_LISTINGS;
    constructor() { }
}
export class ClubApiPostSearchListings implements Action {
    readonly type = CLUB_API_POST_SEARCH_LISTINGS;
    constructor(public payload: { req: SearchListingRequest, page: number }) { }}

export class ClubApiPostSearchListingsFail implements Action {
    readonly type = CLUB_API_POST_SEARCH_LISTINGS_FAIL;
    constructor(public errorMessage: Error) { }
}

export class ClubApiPostSearchListingsResponse implements Action {
    readonly type = CLUB_API_POST_SEARCH_LISTINGS_RESPONSE;
    constructor(public payload: any) {}
}

export class ClubApiPostSearchListingsSuccess implements Action {
    readonly type = CLUB_API_POST_SEARCH_LISTINGS_SUCCESS;
    constructor(public payload: any) {}
}

export class ListingSearchPageFetchMoreSearchListings implements Action {
    readonly type = LISTING_SEARCH_PAGE_FETCH_MORE_SEARCH_LISTINGS;
    constructor(public payload?: string) {}
}


// action types
export type ListingsAction =
    | HomePageFetchLatestListings
    | ClubApiGetLatestListings
    | ClubApiGetLatestListingsFail
    | ClubApiGetLatestListingsSuccess
    | HomePageFetchMoreListings
    | ListingProfilePageFetchListing
    | ClubApiGetListing
    | ClubApiGetListingFail
    | ClubApiGetListingSuccess
    | ListingSearchPageFetchListings
    | ClubApiPostSearchListings
    | ClubApiPostSearchListingsFail
    | ClubApiPostSearchListingsResponse
    | ClubApiPostSearchListingsSuccess
    | ListingSearchPageFetchMoreSearchListings
    ;
