import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
	catchError,
	filter,
	map,
	switchMap,
	withLatestFrom
} from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import { Store } from '@ngrx/store';

import * as escortActions from '../actions/escorts.action';
import * as fromServices from '../../services';
import * as fromRoot from 'src/app/store';

import { normalize } from 'normalizr';
import * as fromSchema from '../../models/schema.model';

@Injectable()
export class EscortsEffects {
	constructor(
		private actions$: Actions,
		private escortsService: fromServices.EscortsService,
		private store: Store<fromRoot.State>
	) {}

	// Get Listing by Route handle
	fetchListingByRouteID$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(escortActions.ESCORT_PROFILE_PAGE_FETCH_ESCORT),
			withLatestFrom(
				this.store.select(fromRoot.getRouterState),
				(action, router) => {
					return router.state.params.handle;
				}
			),
			map((handle) => {
				return new escortActions.ClubApiGetEscort(handle);
			})
		);
	});

	// Get Listing from API
	getListingByHandle$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(escortActions.CLUB_API_GET_ESCORT),
			switchMap((action: escortActions.ClubApiGetEscort) => {
				return this.escortsService.getEscort(action.payload).pipe(
					map((escort) => {
						console.log(escort);
						const normalizedData = normalize(
							escort,
							fromSchema.escort
						);
						return new escortActions.ClubApiGetEscortSuccess(
							normalizedData
						);
					}),
					catchError((error) => {
						return of(
							new escortActions.ClubApiGetEscortFail(error)
						);
					})
				);
			})
		);
	});
}
