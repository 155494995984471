// Angular Modules
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

// App Modules
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { environment } from '../environments/environment';

// Providers
import { InterceptorProviders } from './shared/interceptors/interceptor';

// Dependencies
import { DateFnsModule } from 'ngx-date-fns';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SimplebarAngularModule } from 'simplebar-angular';
import { SwiperModule } from 'swiper/angular';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
	RouterStateSerializer,
	StoreRouterConnectingModule
} from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CustomSerializer, reducers } from './store';
import { CookieService } from 'ngx-cookie-service';

// Redux Stores
import { FeaturesModule } from 'src/library/features/features.module';
import { UIModule } from 'src/library/ui/ui.module';

import { SnackbarModule } from './shared/components/snackbar/snackbar.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import {
	NgxGoogleAnalyticsModule,
	NgxGoogleAnalyticsRouterModule
} from 'ngx-google-analytics';
import {
	ScrollService,
	STORAGE_PROVIDERS,
	windowProvider,
	WindowToken
} from './shared/services';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutingModule,
		CoreModule,
		HammerModule,
		DateFnsModule,
		ImageCropperModule,
		SimplebarAngularModule,
		SwiperModule,
		StoreModule.forRoot(reducers, {}),
		StoreRouterConnectingModule.forRoot(),
		StoreDevtoolsModule.instrument({
			maxAge: 40,
			logOnly: environment.production
		}),
		EffectsModule.forRoot([]),
		FeaturesModule,
		UIModule,
		SnackbarModule,
		MatSnackBarModule,
		TranslocoRootModule,
		NgxGoogleAnalyticsModule.forRoot(environment.gTag),
		NgxGoogleAnalyticsRouterModule
	],
	providers: [
		InterceptorProviders,
		CookieService,
		ScrollService,
		STORAGE_PROVIDERS,
		{ provide: WindowToken, useFactory: windowProvider },
		{ provide: RouterStateSerializer, useClass: CustomSerializer }
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
