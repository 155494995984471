import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import {
	PusherChannel,
	PusherPrivateChannel,
	PusherPresenceChannel,
	Channel
} from 'laravel-echo/dist/channel';
import { LogService } from '.';
import { Store } from '@ngrx/store';
import * as fromUI from 'src/library/ui/store';

@Injectable({
	providedIn: 'root'
})
export class SocketService {
	// API environment variables
	private ENV = `${environment.socketUrl}`;
	// API Request information
	private API_END_POINT = 'broadcasting'; // * Modify to change API path

	private URL = `${environment.apiUrl}/${this.API_END_POINT}`;
	private SERVICE_NAME = `API Auth (${this.API_END_POINT})`;

	private pusher: Pusher;
	public echo: Echo;

	constructor(
		private http: HttpClient,
		private logService: LogService,
		private store: Store<fromUI.UIState>
	) {}

	initialise(): void {
		// Initialise Pusher with channel key
		this.pusher = new Pusher(environment.channelKey);
		// Echo Options
		const options = {
			broadcaster: 'pusher',
			key: environment.channelKey,
			cluster: 'ap3',
			wsHost: environment.socketUrl,
			wsPort: environment.socketPort,
			wssHost: environment.socketUrl,
			wssPort: environment.socketPort,
			disableStats: true,
			encrypted: true,
			forceTLS: true,
			enabledTransports: ['ws', 'wss'],
			authorizer: (channel: PusherChannel, options: any) => {
				return {
					authorize: (
						socketId: string,
						callback: (arg0: boolean, arg1: any) => {}
					) => {
						this.authorise(channel, socketId).subscribe(
							(authToken) => {
								this.broadcastLog(
									`Successfully joined private channel: ${channel.name}`,
									'success'
								);
								callback(false, authToken);
							},
							(error) => {
								this.broadcastLog(
									`Failed to join private channel: ${channel.name}`,
									'error'
								);
								callback(true, error);
							}
						);
					}
				};
			}
		};
		// Initialise Echo if connection doesn't already exist
		if (!this.echo) {
			this.echo = new Echo(options);
		}

		/* 		
        this.echo.connector.pusher.connection.bind('connected', () =>
			console.log('Echo Instance connected')
		);

		this.echo.connector.pusher.connection.bind('error', (error) =>
			console.log(`Connection error ${error}`)
		); 
        */

		// Update Ngrx Store on any state changes
		this.echo.connector.pusher.connection.bind('state_change', (states) => {
			switch (states.current) {
				case 'connecting': {
					this.store.dispatch(
						new fromUI.WebSocketServiceWebSocketConnectReconnecting()
					);
					break;
				}
				case 'connected': {
					this.store.dispatch(
						new fromUI.WebSocketServiceWebSocketConnectSuccess()
					);
					break;
				}
				case 'unavailable': {
					// ! Add Error Message
					this.store.dispatch(
						new fromUI.WebSocketServiceWebSocketConnectFail(null)
					);
					break;
				}
			}
		});
	}

	private log(message: string) {
		this.logService.add(`${this.SERVICE_NAME}: ${message}`, 'success');
	}

	private broadcastLog(
		message: string,
		type?: 'success' | 'error' | 'default'
	): void {
		this.logService.add(`SocketService: ${message}`, type);
	}

	/**
	 * Authorises user to a private channel
	 *
	 * @param channel - The channel to authorise
	 * @param socketId - The socket ID of the connection
	 */
	private authorise(
		channel: PusherChannel,
		socketId: string
	): Observable<{ auth: string }> {
		const url = `${this.URL}/auth`;
		return this.http
			.post<any>(url, {
				socket_id: socketId,
				channel_name: channel.name
			})
			.pipe(
				tap((_) =>
					this.log(
						`Authorising connection to private channel: ${channel.name}`
					)
				)
			);
	}

	/**
     *  Binds a callback function to a specific channel
     * @param name Channel Name
     * @param callback - e.g
     * const callback = (eventName, data) => {
            console.log(
              `bind CHANNEL_NAME: The event ${eventName} was triggered with data ${JSON.stringify(
                data
              )}`
            );
          };
     */
	bindChannelFunction(name: string, callback: any): void {
		// Check if connection has succeeded
		const channel = this.echo.connector.pusher.subscribe(name);
		channel.bind('pusher:subscription_succeeded', () => {
			this.log(`Channel ${name} connected`);
		});

		if (this.echo.connector.channels[name]) {
			this.echo.connector.channels[name].pusher.connection.bind_global(
				callback
			);
			this.broadcastLog(
				`bindChannelFunction() Listening to events on channel '${name}'`,
				'success'
			);
		} else {
			this.broadcastLog(
				`bindChannelFunction() Channel '${name}' does not exist`,
				'error'
			);
		}
	}

	/**
	 * Join a public channel
	 *
	 * @param name - Name of the channel
	 */
	joinPublicChannel(name: string): Channel {
		return this.echo.channel(name);
	}

	/**
	 * Join a private channel
	 *
	 * @param name - Name of the channel
	 */
	joinPrivateChannel(name: string): PusherPrivateChannel {
		// automatically prefixes channel with `private-`
		return this.echo.private(name) as PusherPrivateChannel;
	}

	/**
	 * Join a presence channel
	 *
	 * @param name - Name of the channel
	 */
	joinPresenceChannel(name: string): PusherPresenceChannel {
		// automatically prefixes channel with `presence-`
		return this.echo.join(name) as PusherPresenceChannel;
	}

	/**
	 * Leave a channel and its associated private and presence channels
	 *
	 * @param name - Name of the channel
	 */
	leaveChannel(name: string) {
		this.echo.leave(name);
		this.log(`Channel ${name} disconnected`);
	}

	/**
	 * Leave a specific channel
	 *
	 * @param name - Name of the channel
	 */
	leaveSpecificChannel(name: string) {
		this.echo.leaveChannel(name);
	}
}
