import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import { catchError, map, tap } from 'rxjs/operators';
import {
	LogService,
	ErrorService,
	CustomHttpService
} from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { ClubAPIResponse, ClubAPIResponseService } from '..';
import { Listing, ListingStat } from '../../models';

@Injectable({
	providedIn: 'root'
})
export class AuthListingService {
	// API environment variables
	private ENV = `${environment.apiUrl}/${environment.apiVersion}`;
	// API Request information
	private API_END_POINT = 'user/listings'; // * Modify to change API path

	private URL = `${this.ENV}/${this.API_END_POINT}`;
	private SERVICE_NAME = `API Auth (${this.API_END_POINT})`;

	constructor(
		private http: CustomHttpService,
		private logService: LogService,
		private errorService: ErrorService
	) {}

	private log(message: string) {
		this.logService.add(`${this.SERVICE_NAME}: ${message}`, 'success');
	}

	/**
	 * Get User Listings
	 */
	getListings(): Observable<Listing[]> {
		const url = `${this.URL}`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<Listing[]>(
					res,
					'Listing',
					true
				)
			),
			tap((_) => this.log(`Get User Listings success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getListings'
				)
			)
		);
	}

	/**
	 * Get Listing by uuid
	 * @param uuid - uuid
	 */
	getListing(uuid: string): Observable<Listing> {
		const url = `${this.URL}/${uuid}`;
		return this.http.get(url).pipe(
			// tap((res) => console.log(res)),
			map((res) =>
				ClubAPIResponseService.validateResponse<Listing>(
					res,
					'Listing',
					false
				)
			),
			tap((_) => this.log(`getListing ${uuid} success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getListing'
				)
			)
		);
	}

	/**
	 * Post Listing by UUID and params to get listing stats
	 * @param uuid - uuid
	 */
	getListingStats(
		uuid: string,
		req: UserListingStatsRequest
	): Observable<ListingStat[]> {
		const url = `${this.URL}/${uuid}/graph`;
		return this.http.post(url, req).pipe(
			// tap((res) => console.log(res)),
			map((res) =>
				ClubAPIResponseService.validateResponse<ListingStat[]>(
					res,
					'ListingStat',
					true
				)
			),
			tap((_) => this.log(`getListingStats ${uuid} success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getListingStats'
				)
			)
		);
	}

	/**
	 * Listing registration
	 */
	createListing(listing: Listing): Observable<Listing> {
		const req = ClubAPIResponseService.ModelToAPIConverter(
			listing,
			'Listing'
		);
		return this.http.post<ClubAPIResponse<Listing>>(this.URL, req).pipe(
			// tap((res) => console.log(res)),
			map((res) =>
				ClubAPIResponseService.validateResponse<Listing>(
					res,
					'Listing',
					false
				)
			),
			tap((_) => this.log(`Create Listing Successfully.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'createListing'
				)
			)
		);
	}

	/**
	 * Update User Listing details
	 */
	updateListing(listing: Listing): Observable<Listing> {
		const req = ClubAPIResponseService.ModelToAPIConverter(
			listing,
			'Listing'
		);
		return this.http.put<ClubAPIResponse<Listing>>(this.URL, req).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<Listing>(
					res,
					'Listing',
					false
				)
			),
			tap((_) => this.log(`Update Listing Successfully.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'updateListing'
				)
			)
		);
	}

	/**
	 * Delete Listing by uuid
	 * @param uuid - uuid
	 */
	deleteListing(uuid: string): Observable<any> {
		const url = `${this.URL}/${uuid}`;
		return this.http.delete(url).pipe(
			tap((_) => this.log(`deleteListing ${uuid} success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'deleteListing'
				)
			)
		);
	}
}

export interface UserListingStatsRequest {
	period: UserListingStatsRequestPeriod;
	from: Date;
	to: Date;
}

export type UserListingStatsRequestPeriod =
	| 'hourly'
	| 'daily'
	| 'weekly'
	| 'monthly'
	| 'yearly';
