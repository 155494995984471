import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import * as globalActions from '../actions/global.action';
import { CookieWrapperService } from 'src/app/core/services';
import { TranslocoService } from '@ngneat/transloco';
import { enAU, zhCN } from 'date-fns/locale';
import { LanguageTypeValue } from 'src/library/features/models';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { Locale } from 'date-fns';

@Injectable()
export class GlobalEffects {
	constructor(
		private actions$: Actions,
		private cookieStorage: CookieWrapperService,
		private translocoService: TranslocoService,
		public config: DateFnsConfigurationService
	) {}

	private changeDateLocale(locale: LanguageTypeValue): Locale {
		switch (locale) {
			case 'en': {
				return enAU;
			}
			case 'zh-CN': {
				return zhCN;
			}
		}
	}

	visitorAgreementPageSetLnaguage$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(globalActions.VISITOR_AGREEMENT_PAGE_SET_LANGUAGE),
				map((action: globalActions.VisitorAgreementPageSetLangauge) => {
					// Set Cookie
					this.cookieStorage.setGlobalLanguage(action.payload);
					this.translocoService.setActiveLang(action.payload); // Set i18n locale
					this.config.setLocale(
						this.changeDateLocale(action.payload)
					); // Set DateFns locale
				})
			),
		{ dispatch: false }
	);

	acceptLanguageInterceptorSetLanguage$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(globalActions.ACCEPT_LANGUAGE_INTERCEPTOR_SET_LANGUAGE),
			map((action: globalActions.HeaderComponentSetLanguage) => {
				// Set Cookie
				this.cookieStorage.setGlobalLanguage(action.payload);
				this.translocoService.setActiveLang(action.payload); // Set i18n locale
				this.config.setLocale(this.changeDateLocale(action.payload)); // Set DateFns locale
				return new globalActions.CookieStorageSetLanguageSuccess(
					action.payload
				);
			})
		);
	});

	headerComponentSetLanguage$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(globalActions.HEADER_COMPONENT_SET_LANGUAGE),
			map((action: globalActions.HeaderComponentSetLanguage) => {
				// Set Cookie
				this.cookieStorage.setGlobalLanguage(action.payload);
				this.translocoService.setActiveLang(action.payload); // Set i18n locale
				this.config.setLocale(this.changeDateLocale(action.payload)); // Set DateFns locale
				return new globalActions.CookieStorageSetLanguageSuccessReloadPage(
					action.payload
				);
			})
		);
	});

	// If set language successfully, to refresh page
	globalSetLanguageSuccessReloadPage$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(
					globalActions.COOKIE_STORAGE_SET_LANGUAGE_SUCCESS_RELOAD_PAGE
				),
				map((route) => {
					window.location.reload();
				})
			),
		{ dispatch: false }
	);
}
