import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { of } from 'rxjs';
import {
	catchError,
	map,
	switchMap,
	tap,
	withLatestFrom
} from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { Store } from '@ngrx/store';
import * as authActions from '../actions/auth.action';
import * as fromCore from '../reducers';
import * as fromRoot from '../../../store';

import { normalize } from 'normalizr';
import * as fromSchema from 'src/library/features/models/schema.model';

import {
	AuthService,
	CredentialsService,
	RegisterService,
	UserForgotPasswordService,
	UserResetPasswordService,
	UserUsernameValidationService,
	UserVerificationRequestService,
	UserVerificationService
} from '../../services';
import { AuthUsersService } from 'src/library/features/services';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { TranslocoService } from '@ngneat/transloco';
@Injectable()
export class AuthEffects {
	constructor(
		private authService: AuthService,
		private authUsersService: AuthUsersService,
		private credentialsService: CredentialsService,
		private userForgotPasswordService: UserForgotPasswordService,
		private userResetPasswordService: UserResetPasswordService,
		private userVerificationRequestService: UserVerificationRequestService,
		private userUsernameValidationService: UserUsernameValidationService,
		private userVerificationService: UserVerificationService,
		private registerService: RegisterService,
		private actions$: Actions,
		private store: Store<fromRoot.State>,
		private snackbarService: SnackbarService,
		private translocoService: TranslocoService,
		private router: Router
	) {}

	// Login user from login page
	loginPageLoginUser$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.LOGIN_PAGE_LOGIN_USER),
			map(
				(action: authActions.LoginPageLoginUser) =>
					new authActions.ClubApiPostLoginUser(action.payload)
			)
		);
	});

	// Log User in via API
	postLoginUser$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.CLUB_API_POST_LOGIN_USER),
			switchMap((action: authActions.ClubApiPostLoginUser) => {
				return this.authService.login(action.payload).pipe(
					map(() => {
						return new authActions.ClubApiPostLoginUserSuccess();
					}),
					catchError((error) => {
						return of(
							new authActions.ClubApiPostLoginUserFail(error)
						);
					})
				);
			})
		);
	});

	// Log User in success via API, to redirect to redirect URI else to home
	postLoginUserSuccess$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.CLUB_API_POST_LOGIN_USER_SUCCESS),
			withLatestFrom(
				this.store.select(fromRoot.getRouterState),
				(
					action: authActions.ClubApiGetAuthUserDetailsSuccess,
					router
				) => {
					return {
						routeRedirect: router.state.queryParams.redirect
							? router.state.queryParams.redirect
							: null
					};
				}
			),
			map((newPayload: { routeRedirect: string }) => {
				// Set Authorised Cookie to know that the user has logged in successfully
				this.credentialsService.setAuthorisedCookie();
				if (!!newPayload.routeRedirect) {
					this.router.navigate([newPayload.routeRedirect], {
						replaceUrl: true
					});
				} else {
					this.router.navigate(['/home'], { replaceUrl: true });
				}
				return new authActions.LoginPageSuccessNavigateNext();
			})
		);
	});

	// Check if user is logged in with Header
	headerComponentCheckIsLoggedIn$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.HEADER_COMPONENT_CHECK_LOGIN_STATUS),
			withLatestFrom(
				this.store.select(fromCore.getCoreState),
				(
					action: authActions.HeaderComponentCheckUserLoginStatus,
					core
				) => {
					return core.auth.loginState === 'LOADED';
				}
			),
			map((isLoggedIn) => {
				if (isLoggedIn) {
					return new authActions.HeaderComponentCheckUserLoginTrue();
				} else {
					return new authActions.HeaderComponentCheckUserLoginFalse();
				}
			})
		);
	});

	// If user is logged in, and is not authorised, to get logged in user's details
	headerComponentGetLoggedInUserDetails$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.HEADER_COMPONENT_CHECK_LOGIN_TRUE),
			withLatestFrom(
				this.store.select(fromCore.getCoreState),
				(action: authActions.AuthGuardCheckUserLoginStatus, core) => {
					return core.auth.authState === 'LOADED';
				}
			),
			map((isAuth) => {
				if (!isAuth) {
					return new authActions.HeaderComponentGetLoggedInUserDetails();
				} else {
					return new authActions.HeaderComponentUserDetailsExists();
				}
			})
		);
	});

	// Check if user is logged in with Auth Guard
	authGuardCheckIsLoggedIn$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.AUTH_GUARD_CHECK_LOGIN_STATUS),
			withLatestFrom(
				this.store.select(fromCore.getCoreState),
				(action: authActions.AuthGuardCheckUserLoginStatus, core) => {
					return core.auth.loginState === 'LOADED';
				}
			),
			map((isLoggedIn) => {
				if (isLoggedIn) {
					return new authActions.AuthGuardCheckUserLoginTrue();
				} else {
					return new authActions.AuthGuardCheckUserLoginFalse();
				}
			})
		);
	});

	// If user is logged in, to get logged in user's details
	authGuardGetLoggedInUserDetails$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.AUTH_GUARD_CHECK_LOGIN_TRUE),
			map(() => new authActions.AuthGuardGetLoggedInUserDetails())
		);
	});

	// If user is not logged in, to check stored cookie session exists.
	cookieStorageCheckIsLogin$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				authActions.HEADER_COMPONENT_CHECK_LOGIN_FALSE,
				authActions.AUTH_GUARD_CHECK_LOGIN_FALSE
			),
			map(() => new authActions.CookieStorageCheckUserLoginStatus())
		);
	});

	// Check cookie storage to see if user is already logged in before
	cookieStorageCheckLogin$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.COOKIE_STORAGE_CHECK_LOGIN_STATUS),
			withLatestFrom(
				this.store.select(fromCore.getCoreState),
				(action: authActions.AuthGuardCheckUserLoginStatus, core) => {
					return core.auth.logoutState === 'LOADED';
				}
			),
			map((isLoggedOut) => {
				// Check service
				if (
					this.credentialsService.hasAuthorisedCookie() &&
					!isLoggedOut
				) {
					return new authActions.CookieStorageCheckUserLoginTrue();
				} else {
					return new authActions.CookieStorageCheckUserLoginFalse();
				}
			})
		);
	});

	// Attempts to get Auth User
	cookieStorageAttemptGetAuthUser$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.COOKIE_STORAGE_CHECK_LOGIN_TRUE),
			map(() => new authActions.CookieStorageAttemptGetUserAuthDetails())
		);
	});

	// Get Auth User Details by API
	getAuthUserDetails$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				authActions.CLUB_API_GET_AUTH_USER_DETAILS,
				authActions.HEADER_COMPONENT_GET_LOGGED_IN_USER_DETAILS,
				authActions.CREATE_LISTING_PAGE_GET_LOGGED_IN_USER_DETAILS,
				authActions.USER_DASHBOARD_PAGE_GET_LOGGED_IN_USER_DETAILS,
				authActions.AUTH_GUARD_GET_LOGGED_IN_USER_DETAILS,
				authActions.COOKIE_STORAGE_ATTEMPT_GET_AUTH_DETAILS
			),
			switchMap(() => {
				return this.authUsersService.getUser().pipe(
					map((user) => {
						// console.log(user);
						const normalizedData = normalize(user, fromSchema.user);
						return new authActions.ClubApiGetAuthUserDetailsSuccess(
							normalizedData
						);
					}),
					catchError((error) => {
						return of(
							new authActions.ClubApiGetAuthUserDetailsFail(error)
						);
					})
				);
			})
		);
	});

	// If Get Auth User is successful, check if Login state and cookie is set correctly
	updateLoginState$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.CLUB_API_GET_AUTH_USER_DETAILS_SUCCESS),
			withLatestFrom(
				this.store.select(fromCore.getCoreState),
				(
					action: authActions.ClubApiGetAuthUserDetailsSuccess,
					core
				) => {
					return core.auth.loginState === 'LOADED';
				}
			),
			map((loginStateCorrect: boolean) => {
				if (!this.credentialsService.hasAuthorisedCookie()) {
					this.credentialsService.setAuthorisedCookie();
				}
				if (!loginStateCorrect) {
					return new authActions.AuthStoreLoginStateUpdate();
				} else {
					return new authActions.AuthStoreLoginStateCorrect();
				}
			})
		);
	});

	// Check If getAuthUser failed error is due to unauthorised
	checkAuthUserError$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.CLUB_API_GET_AUTH_USER_DETAILS_FAIL),
			map((action: authActions.ClubApiGetAuthUserDetailsFail) => {
				if (action.errorMessage.code === 'B10101') {
					this.credentialsService.unsetAuthorisedCookie();
					this.router.navigate(['/login']);
					return new authActions.AuthStoreLogoutStateUpdate();
				} else {
					return new authActions.AuthStoreLoginStateCorrect();
				}
			})
		);
	});

	// Logout user from Header Component
	headerComponentLogoutUser$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.HEADER_COMPONENT_LOGOUT_USER),
			map(() => new authActions.ClubApiPostLogoutUser())
		);
	});

	// Log User out via API
	postLogoutUser$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.CLUB_API_POST_LOGOUT_USER),
			switchMap((action: authActions.ClubApiPostLogoutUser) => {
				return this.authService.logout().pipe(
					map(() => {
						this.credentialsService.unsetAuthorisedCookie();
						this.snackbarService.addMessage(
							this.translocoService.translate(
								'snackbar.logoutSuccess'
							),
							'logout',
							'success'
						);
						return new authActions.ClubApiPostLogoutUserSuccess();
					}),
					catchError((error) => {
						this.snackbarService.addMessage(
							error,
							'action',
							'error'
						);
						return of(
							new authActions.ClubApiPostLogoutUserFail(error)
						);
					})
				);
			})
		);
	});

	// Log User out success via API
	postLogoutUserSuccess$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.CLUB_API_POST_LOGOUT_USER_SUCCESS),
			map((action: authActions.ClubApiPostLoginUserSuccess) => {
				this.router.navigate([`/login`]);
				return new authActions.HeaderComponentLogoutUserSuccessNavigateNext();
			})
		);
	});

	// Forgotten Password Page request reset password
	forgottenPasswordPageResetPassword$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.FORGOTTEN_PASSWORD_PAGE_RESET_PASSWORD_REQUEST),
			map(
				(
					action: authActions.ForgottenPasswordPageResetPasswordRequest
				) => {
					return new authActions.ClubApiPostResetPasswordRequest(
						action.payload
					);
				}
			)
		);
	});

	// Reset Password request via API
	postResetPasswordRequest$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.CLUB_API_POST_RESET_PASSWORD_REQUEST),
			switchMap((action: authActions.ClubApiPostResetPasswordRequest) => {
				return this.userForgotPasswordService
					.requestReset(action.payload)
					.pipe(
						map(() => {
							return new authActions.ClubApiPostResetPasswordRequestSuccess();
						}),
						catchError((error) => {
							// If 422 due to incorrect email still proceed
							if (error.ok) {
								return of(
									new authActions.ClubApiPostResetPasswordRequestSuccess()
								);
							} else {
								return of(
									new authActions.ClubApiPostResetPasswordRequestFail(
										error
									)
								);
							}
						})
					);
			})
		);
	});

	// Reset password requestsuccess via API
	postResetPasswordRequestSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(
					authActions.CLUB_API_POST_RESET_PASSWORD_REQUEST_SUCCESS
				),
				map(() => {
					this.router.navigate([`/password-reset/sent`]);
				})
			),
		{ dispatch: false }
	);

	// Password Reset Page reset password
	passwordResetPageResetPassword$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.PASSWORD_RESET_PAGE_RESET_PASSWORD),
			withLatestFrom(
				this.store.select(fromRoot.getRouterState),
				(
					action: authActions.PasswordResetPageResetPasswordRequest,
					router
				) => {
					return {
						...action,
						payload: {
							...action.payload,
							token: router.state.queryParams.token
								? router.state.queryParams.token
								: null
						}
					};
				}
			),
			map((action: authActions.PasswordResetPageResetPasswordRequest) => {
				return new authActions.ClubApiPostResetPassword(action.payload);
			})
		);
	});

	// Reset Password via API
	postResetPassword$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.CLUB_API_POST_RESET_PASSWORD),
			switchMap((action: authActions.ClubApiPostResetPassword) => {
				return this.userResetPasswordService
					.resetPassword(action.payload)
					.pipe(
						map(() => {
							return new authActions.ClubApiPostResetPasswordSuccess();
						}),
						catchError((error) => {
							return of(
								new authActions.ClubApiPostResetPasswordFail(
									error
								)
							);
						})
					);
			})
		);
	});

	// Reset password success via API
	postResetPasswordSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(authActions.CLUB_API_POST_RESET_PASSWORD_SUCCESS),
				map(() => {
					this.snackbarService.addMessage(
						this.translocoService.translate(
							'snackbar.passwordResetSuccess'
						),
						'action',
						'success'
					);
					this.router.navigate([`/login`]);
				})
			),
		{ dispatch: false }
	);

	// Register Page register user
	registerPageRegisterUser$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.REGISTER_PAGE_REGISTER_USER),
			map((action: authActions.RegisterPageRegisterUser) => {
				return new authActions.ClubApiPostRegisterUser(action.payload);
			})
		);
	});

	// Register User via API
	postRegisterUser$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.CLUB_API_POST_REGISTER_USER),
			switchMap((action: authActions.ClubApiPostRegisterUser) => {
				return this.registerService.register(action.payload).pipe(
					map(() => {
						return new authActions.ClubApiPostRegisterUserSuccess();
					}),
					catchError((error) => {
						return of(
							new authActions.ClubApiPostRegisterUserFail(error)
						);
					})
				);
			})
		);
	});

	// Register user Success via API
	postRegisterUserSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(authActions.CLUB_API_POST_REGISTER_USER_SUCCESS),
				map(() => {
					this.router.navigate([`/user/dashboard`]);
				})
			),
		{ dispatch: false }
	);

	// Verify User Email Request
	verifyUserEmailRequest$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				authActions.USER_DASHBOARD_PAGE_VERIFY_USER_EMAIL_REQUEST,
				authActions.USER_EMAIL_VERIFICATION_PAGE_VERIFY_USER_EMAIL_REQUEST
			),
			map(() => {
				return new authActions.ClubApiPostVerifyUserEmailRequest();
			})
		);
	});

	// Verify User Email Request via API
	postVerifyUserEmailRequest$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.CLUB_API_POST_VERIFY_USER_EMAIL_REQUEST),
			switchMap(() => {
				return this.userVerificationRequestService
					.requestVerification()
					.pipe(
						map(() => {
							return new authActions.ClubApiPostVerifyUserEmailRequestSuccess();
						}),
						catchError((error) => {
							return of(
								new authActions.ClubApiPostVerifyUserEmailRequestFail(
									error
								)
							);
						})
					);
			})
		);
	});

	// Check valid username
	checkValidUsername$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.USER_EMAIL_VERIFICATION_PAGE_VALIDATE_USERNAME),
			map(
				(
					action: authActions.UserEmailVerificationPageValidateUsername
				) => {
					return new authActions.ClubApiPostUsernameValidation(
						action.payload
					);
				}
			)
		);
	});

	// Check valid username Request via API
	postCheckValidUsername$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.CLUB_API_POST_USERNAME_VALIDATION),
			switchMap((action: authActions.ClubApiPostUsernameValidation) => {
				return this.userUsernameValidationService
					.checkValidUsername(action.payload)
					.pipe(
						map(() => {
							return new authActions.ClubApiPostUsernameValidationSuccess();
						}),
						catchError((error) => {
							return of(
								new authActions.ClubApiPostUsernameValidationFail(
									error
								)
							);
						})
					);
			})
		);
	});

	// Verify User Email
	verifyUserEmail$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.USER_EMAIL_VERIFICATION_PAGE_VERIFY_USER_EMAIL),
			withLatestFrom(
				this.store.select(fromRoot.getRouterState),
				(
					action: authActions.UserEmailVerificationPageVerifyUserEmail,
					router
				) => {
					return {
						username: action.payload.username,
						uuid: router.state.queryParams.uuid
							? router.state.queryParams.uuid
							: null,
						hash: router.state.queryParams.hash
							? router.state.queryParams.hash
							: null,
						signature: router.state.queryParams.signature
							? router.state.queryParams.signature
							: null,
						expires: router.state.queryParams.expires
							? router.state.queryParams.expires
							: null
					};
				}
			),
			map((newPayload: any) => {
				return new authActions.ClubApiPostVerifyUserEmail(newPayload);
			})
		);
	});

	// Verify User Email via API
	postVerifyUserEmail$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.CLUB_API_POST_VERIFY_USER_EMAIL),
			switchMap((action: authActions.ClubApiPostVerifyUserEmail) => {
				return this.userVerificationService
					.verifyUser(action.payload)
					.pipe(
						map(() => {
							return new authActions.ClubApiPostVerifyUserEmailSuccess();
						}),
						catchError((error) => {
							return of(
								new authActions.ClubApiPostVerifyUserEmailFail(
									error
								)
							);
						})
					);
			})
		);
	});

	// Verify User Email success via API
	postVerifyUserEmailSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(authActions.CLUB_API_POST_VERIFY_USER_EMAIL_SUCCESS),
				map(() => {
					this.snackbarService.addMessage(
						this.translocoService.translate(
							'snackbar.userAccountVerifySuccess'
						),
						'action',
						'success'
					);
					this.router.navigate([`/user/dashboard`]);
				})
			),
		{ dispatch: false }
	);

	// Update USER
	updateUser$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.USER_PROFILE_EDIT_PAGE_UPDATE_USER),
			map((action: authActions.UserProfileEditPageUpdateUser) => {
				return new authActions.ClubApiPutUpdateUser(action.payload);
			})
		);
	});

	// Update User via API
	putUpdateUser$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authActions.CLUB_API_PUT_UPDATE_USER),
			switchMap((action: authActions.ClubApiPutUpdateUser) => {
				return this.authUsersService.updateUser(action.payload).pipe(
					map((user) => {
						const normalizedData = normalize(user, fromSchema.user);
						return new authActions.ClubApiPutUpdateUserSuccess(
							normalizedData
						);
					}),
					catchError((error) => {
						return of(
							new authActions.ClubApiPutUpdateUserFail(error)
						);
					})
				);
			})
		);
	});

	// Update User via API success
	postUpdateUserSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(authActions.CLUB_API_PUT_UPDATE_USER_SUCCESS),
				map(() => {
					this.snackbarService.addMessage(
						this.translocoService.translate(
							'snackbar.userProfileUpdateSuccess'
						),
						'action',
						'success'
					);
					this.router.navigate([`/user/dashboard`]);
				})
			),
		{ dispatch: false }
	);
}
