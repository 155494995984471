import { Action } from '@ngrx/store';

export const USER_LISTING_PROFILE_PAGE_INITIALISE_PAGE 				= '[User Escort Listing Profile Page] Initialise Page';
export const USER_LISTING_PROFILE_PAGE_CALCULATE_VIEW_GRAPH 		= '[User Escort Listing Profile Page] Calculate View Graph';
export const USER_LISTING_PROFILE_PAGE_CALCULATE_VIEW_CARD 			= '[User Escort Listing Profile Page] Calculate View Card';

export class UserListingProfilePageInitialisePage implements Action {
	readonly type = USER_LISTING_PROFILE_PAGE_INITIALISE_PAGE;
	constructor() {}
}

export class UserListingProfilePageCalculateViewGraph implements Action {
	readonly type = USER_LISTING_PROFILE_PAGE_CALCULATE_VIEW_GRAPH;
	constructor(public payload: any[]) {}
}

export class UserListingProfilePageCalculateViewCard implements Action {
	readonly type = USER_LISTING_PROFILE_PAGE_CALCULATE_VIEW_CARD;
	constructor(
		public payload: {
			count: number;
			rateOfChange: number;
		}
	) {}
}

// action types
export type UserEscorrtListingProfileAction =
	| UserListingProfilePageInitialisePage
	| UserListingProfilePageCalculateViewGraph
	| UserListingProfilePageCalculateViewCard
	;
