import { createSelector } from '@ngrx/store';

import * as fromCore from '../reducers';
import * as fromAuth from '../reducers/auth.reducer';

import { getMediaEntities } from './media.selectors';
import { getEscortsEntities } from './escorts.selectors';
import { User } from 'src/library/features/models/user.model';
import { getListingsEntities } from './listings.selectors';

function checkEqual(a, b) {
	return (
		a && b && a.length === b.length && a.every((val, idx) => val === b[idx])
	);
}

export const getAuthStoreState = createSelector(
	fromCore.getCoreState,
	(state: fromCore.CoreState) => state.auth
);

// Login API call
export const getLoginLoading = createSelector(
	getAuthStoreState,
	fromAuth.getLoginLoading
);

export const getLoginLoaded = createSelector(
	getAuthStoreState,
	fromAuth.getLoginLoaded
);

export const getLoginError = createSelector(
	getAuthStoreState,
	fromAuth.getLoginError
);

// Logout API call
export const getLogoutLoading = createSelector(
	getAuthStoreState,
	fromAuth.getLogoutLoading
);

export const getLogoutLoaded = createSelector(
	getAuthStoreState,
	fromAuth.getLogoutLoaded
);

export const getLogoutPageError = createSelector(
	getAuthStoreState,
	fromAuth.getLogoutError
);

// Register API call
export const getRegisterLoading = createSelector(
	getAuthStoreState,
	fromAuth.getRegisterLoading
);

export const getRegisterLoaded = createSelector(
	getAuthStoreState,
	fromAuth.getRegisterLoaded
);

export const getRegisterPageError = createSelector(
	getAuthStoreState,
	fromAuth.getRegisterError
);

// Verify User Email Request API call
export const getRequestVerifyUserEmailLoading = createSelector(
	getAuthStoreState,
	fromAuth.getRequestVerifyUserEmailLoading
);

export const getRequestVerifyUserEmailLoaded = createSelector(
	getAuthStoreState,
	fromAuth.getRequestVerifyUserEmailLoaded
);

export const getRequestVerifyUserEmailPageError = createSelector(
	getAuthStoreState,
	fromAuth.getRequestVerifyUserEmailError
);

// Validate Username API call
export const getUsernameValidationLoading = createSelector(
	getAuthStoreState,
	fromAuth.getUsernameValidationLoading
);

export const getUsernameValidationLoaded = createSelector(
	getAuthStoreState,
	fromAuth.getUsernameValidationLoaded
);

export const getUsernameValidationError = createSelector(
	getAuthStoreState,
	fromAuth.getUsernameValidationError
);

// Verify User Email API call
export const getVerifyUserEmailLoading = createSelector(
	getAuthStoreState,
	fromAuth.getVerifyUserEmailLoading
);

export const getVerifyUserEmailLoaded = createSelector(
	getAuthStoreState,
	fromAuth.getVerifyUserEmailLoaded
);

export const getVerifyUserEmailPageError = createSelector(
	getAuthStoreState,
	fromAuth.getVerifyUserEmailError
);

// Forgot Password API call
export const getForgotPasswordLoading = createSelector(
	getAuthStoreState,
	fromAuth.getForgotPasswordLoading
);

export const getForgotPasswordLoaded = createSelector(
	getAuthStoreState,
	fromAuth.getForgotPasswordLoaded
);

export const getForgotPasswordPageError = createSelector(
	getAuthStoreState,
	fromAuth.getForgotPasswordError
);

// Reset Password API call
export const getResetPasswordLoading = createSelector(
	getAuthStoreState,
	fromAuth.getResetPasswordLoading
);

export const getResetPasswordLoaded = createSelector(
	getAuthStoreState,
	fromAuth.getResetPasswordLoaded
);

export const getResetPasswordPageError = createSelector(
	getAuthStoreState,
	fromAuth.getResetPasswordError
);

// Get Auth User API call
export const getAuthUserLoading = createSelector(
	getAuthStoreState,
	fromAuth.getAuthLoading
);

export const getAuthUserLoaded = createSelector(
	getAuthStoreState,
	fromAuth.getAuthLoaded
);

export const getAuthUserError = createSelector(
	getAuthStoreState,
	fromAuth.getAuthError
);

// Update Auth User API call
export const getUpdateUserLoading = createSelector(
	getAuthStoreState,
	fromAuth.getUpdateUserLoading
);

export const getUpdateUserLoaded = createSelector(
	getAuthStoreState,
	fromAuth.getUpdateUserLoaded
);

export const getUpdateUserError = createSelector(
	getAuthStoreState,
	fromAuth.getUpdateUserError
);

// Get Auth User Details
export const getAuthUserEntities = createSelector(
	getAuthStoreState,
	fromAuth.getAuthUserEntities
);

export const getStoreAuthUser = createSelector(
	getAuthStoreState,
	fromAuth.getAuthUser
);

export const isAuthenticated = createSelector(
	getStoreAuthUser,
	(userUUID) => !!userUUID
);

export const getAuthUser = createSelector(
	getAuthUserEntities,
	getStoreAuthUser,
	(users, userUUID): User => (!!userUUID && !!users ? users[userUUID] : null)
);

// Get Denormalized Auth User
export const getAuthUserView = createSelector(
	getAuthUser,
	getMediaEntities,
	getEscortsEntities,
	getListingsEntities,
	(user, medias, escort, listings): User =>
		user && {
			...user,
			avatar: medias[user.avatar as string],
			escort: escort[user.escort as string] && {
				...escort[user.escort as string],
				avatar: medias[escort[user.escort as string].avatar as string],
				banner: medias[escort[user.escort as string].banner as string],
				galleries: escort[user.escort as string].galleries.map(
					(gallery) => {
						return (
							gallery && {
								...gallery,
								media: (gallery.media as string[]).map(
									(galleryMedia) => medias[galleryMedia]
								),
								thumbnail: medias[gallery.thumbnail as string]
							}
						);
					}
				)
			},
			listings: user.listings && (user.listings as string[]).map(
				(listing) => listings[listing]
			)
		}
);
