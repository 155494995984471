import { createSelector } from '@ngrx/store';

import * as fromUI from '../reducers';
import * as fromListingSearch from '../reducers/listing-search.reducer';
import * as fromFeature from '../../../features/store/reducers';
import { getMediaEntities } from '../../../features/store/selectors/media.selectors';
import { Listing, Media } from 'src/library/features/models';
import { getListingsEntities } from 'src/library/features/store/selectors/listings.selectors';
import { concat } from 'rxjs';

export const getListingSearchState = createSelector(
	fromUI.getUIState,
	(state: fromUI.UIState) => state.listingSearch
);

export const getListingSearchLoading = createSelector(
	getListingSearchState,
	fromListingSearch.getListingSearchLoading
);

export const getListingSearchError = createSelector(
	getListingSearchState,
	fromListingSearch.getListingSearchError
);

export const getListingSearchListingPageNumber = createSelector(
	getListingSearchState,
	fromListingSearch.getListingSearchListingPage
);

export const getListingSearchIsReset = createSelector(
	getListingSearchState,
	fromListingSearch.getListingSearchIsReset
)

export const getListingSearchQueryLocation = createSelector(
	getListingSearchState,
	(state) => {
		const query = state.query;
		if (
			query &&
			query.location &&
			query.location.name &&
			query.location.latitude &&
			query.location.longitude
		) {
			const derp = {
				name: query.location.name,
				latitude: query.location.latitude,
				longitude: query.location.longitude
			};
			return {
				name: query.location.name,
				latitude: query.location.latitude,
				longitude: query.location.longitude
			};
		}
	}
);

export const getListingSearchQueryLocationRadius = createSelector(
	getListingSearchState,
	(state) => {
		const query = state.query;
		if (query && query.location && query.location.radius) {
			return query.location.radius;
		}
	}
);

export const getListingSearchQueryURI = createSelector(
	getListingSearchState,
	(state) => {
		const query = state.query;

		if (query) {
			if (query.location) {
				const search = {
					ln: query.location.name,
					llat: query.location.latitude,
					llong: query.location.longitude,
					lr: query.location.radius
				};
				const URI = Object.entries(search)
					.filter(([key, val]) => !!val)
					.map(([key, val]) => `${encodeURIComponent(key)}=${val}`)
					.join('&');
				return URI;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

export const getListingSearchListingOrder = createSelector(
	getListingSearchState,
	fromListingSearch.getListingSearchListingOrder
);

export const getListingSearchListingTotal = createSelector(
	getListingSearchState,
	fromListingSearch.getListingSearchListingTotal
);

export const getListingSearchListingsList = createSelector(
	getListingSearchListingOrder,
	getListingsEntities,
	(order, listings) => order.map((uuid) => listings[uuid])
);

// Get Denormalized listings
export const getListingSearchListingsView = createSelector(
	getListingSearchListingsList,
	getMediaEntities,
	(listings, media): Listing[] =>
		listings.map(
			(listing) =>
				listing && {
					...listing,
					media: (listing.media as string[]).map(
						(mediaUUID) => media[mediaUUID]
					) as Media[]
				}
		)
);

// Get New Listings Media
export const getListingSearchNewListingMedia = createSelector(
	getListingsEntities,
	getMediaEntities,
	(listings, newListings, media): Media[] =>
		newListings.map((key) => {
			const mediaString = listings[key].media[0] as string;
			return media[mediaString];
		})
);
