import { createSelector } from '@ngrx/store';

import * as fromRoot from '../../../../app/store';
import * as fromFeature from '../reducers';
import * as fromEscorts from '../reducers/escorts.reducer';

import { Escort } from '../../models/';
import { getMediaEntities } from './media.selectors';

export const getEscortState = createSelector(
	fromFeature.getFeaturesState,
	(state: fromFeature.FeaturesState) => state.escorts
);

export const getEscortsEntities = createSelector(
	getEscortState,
	fromEscorts.getEscortsEntities
);

export const getEscortsLoaded = createSelector(
	getEscortState,
	fromEscorts.getEscortsLoaded
);

export const getEscortsLoading = createSelector(
	getEscortState,
	fromEscorts.getEscortsLoading
);

export const getEscortsError = createSelector(
	getEscortState,
	fromEscorts.getEscortsError
);

export const getEscortsList = createSelector(getEscortsEntities, (entities) =>
	Object.keys(entities).map((key) => entities[key])
);

export const getEscortByRouteHandle = createSelector(
	getEscortsEntities,
	fromRoot.getRouterState,
	(escorts, router): Escort =>
		router.state && escorts[router.state.params.handle]
);

// Get Denormalized escort by Route Handle
export const getEscortByRouteHandleView = createSelector(
	getEscortByRouteHandle,
	getMediaEntities,
	getEscortsEntities,
	(escort: Escort, medias): Escort => {
		return (
			escort && {
				...escort,
				avatar: medias[escort.avatar as string],
				banner: medias[escort.banner as string],
				galleries: escort.galleries.map((gallery) => {
					return (gallery && {
						...gallery,
						media: (gallery.media as string[]).map((galleryMedia) => medias[galleryMedia]),
						thumbnail: medias[gallery.thumbnail as string]
					})
				})
			}
		);
	}
);
