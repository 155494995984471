import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromSuburbs from '../reducers/suburbs.reducer';

import { Suburb } from '../../models';

export const getSuburbState = createSelector(
	fromFeature.getFeaturesState,
	(state: fromFeature.FeaturesState) => state.suburbs
);

export const getSuburbsEntities = createSelector(
	getSuburbState,
	fromSuburbs.getSuburbsEntities
);

export const getSuburbsLoaded = createSelector(
	getSuburbState,
	fromSuburbs.getSuburbsLoaded
);

export const getSuburbsLoading = createSelector(
	getSuburbState,
	fromSuburbs.getSuburbsLoading
);

export const getSuburbsError = createSelector(
	getSuburbState,
	fromSuburbs.getSuburbsError
);

export const getSuburbsList = createSelector(getSuburbsEntities, (entities) =>
	Object.keys(entities).map((key) => entities[key])
);

// Get Denormalized suburbs
export const getSuburbsView = createSelector(
	getSuburbsList,
	(suburbs): Suburb[] =>
		suburbs.map(
			(suburb) =>
				suburb && {
					...suburb
				}
		)
);

export const getCurrentSearchSuburbs = createSelector(
	getSuburbState,
	fromSuburbs.getSuburbsCurrentSearch
);

export const getCurrentSearchSuburbsView = createSelector(
	getSuburbsEntities,
	getCurrentSearchSuburbs,
	(suburbs, searchSuburbsID) => searchSuburbsID.map((id) => suburbs[id])
);
