import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	Renderer2,
	ViewChild
} from '@angular/core';
import { Router } from '@angular/router';

export type SnackbarType = 'default' | 'success' | 'error';

@Component({
	selector: 'ftoclub-snackbar',
	templateUrl: './snackbar.component.html',
	styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit, AfterViewInit {
	@Input() content = '';
	@Input() type: SnackbarType = 'default';
	@Input() link: string;
	@Input() duration: number; //* If duration is 0, the snackbar will not dismiss by itself
	@Output() afterClose = new EventEmitter<boolean>();
	@ViewChild('snackBar', { static: false }) snackBar: ElementRef;

	constructor(private renderer: Renderer2, private router: Router) {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		// If there is no duration set, to set default to 3000ms
		this.duration = this.duration === undefined ? 3000 : this.duration;

		// Check if duration is set, if it is to aninimate out after set duration
		const animate = (value) =>
			this.renderer.setStyle(
				this.snackBar.nativeElement,
				'animation',
				value
			);
		if (this.duration === 0) {
			animate(`snackbarIn 0.3s`);
		} else {
			animate(
				`snackbarIn 300ms cubic-bezier(0.61, 0.47, 0.68, 1.12),
        snackbarOut 500ms ease-in-out ${this.duration}ms`
			);
		}
	}
	// On Clicking Snackbar
	onClick(): void {
		if (this.link) {
			this.router.navigate([this.link]);
			this.close();
		}
	}

	// Close Snackbar
	close(): void {
		this.renderer.setStyle(
			this.snackBar.nativeElement,
			'animation',
			'snackbarOut 500ms ease-in-out'
		);
	}

	// Checks for animation trigger event
	// trigger EventEmitter to close snackbar if is fading out
	animationDone(event: AnimationEvent): void {
		if (event.animationName === 'snackbarOut') {
			this.afterClose.emit(true);
		}
	}
}
