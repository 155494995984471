import {
	Inject,
	Injectable,
	InjectionToken,
	StaticProvider
} from '@angular/core';
import { ListingView } from 'src/app/modules/pages/home/listing-navigation-bar-view/home-listing-navigation-bar-view.component';

export const WindowToken = new InjectionToken<Window>('Window');
export function windowProvider() {
	return window;
}

export const LocalStorage = new InjectionToken<Storage>('LocalStorage');
export const SessionStorage = new InjectionToken<Storage>('SessionStorage');

export const STORAGE_PROVIDERS: StaticProvider[] = [
	{
		provide: LocalStorage,
		useFactory: (win: Window) => getStorage(win, 'localStorage'),
		deps: [WindowToken]
	},
	{
		provide: SessionStorage,
		useFactory: (win: Window) => getStorage(win, 'sessionStorage'),
		deps: [WindowToken]
	}
];

export class NoopStorage implements Storage {
	length = 0;
	clear() {}
	getItem() {
		return null;
	}
	key() {
		return null;
	}
	removeItem() {}
	setItem() {}
}

function getStorage(
	win: Window,
	storageType: 'localStorage' | 'sessionStorage'
): Storage {
	// When cookies are disabled in the browser, even trying to access `window[storageType]` throws an
	// error. If so, return a no-op storage.
	try {
		return win[storageType];
	} catch {
		return new NoopStorage();
	}
}

interface IStorage<T> {
	getItem(key: T): any | null;
	setItem(key: T, value: any): void;
	removeItem(key: T): void;
	clear(): void;
}

/*
 * Session Storage Wrapper
 */
interface SessionStorage {
	scrollPosition: string;
	scrollLocationHref: string;
}

@Injectable({
	providedIn: 'root'
})
export class SessionStorageService {
	constructor(
		@Inject(SessionStorage) private storage: IStorage<keyof SessionStorage>
	) {}

	/*
	 * scrollPosition
	 */
	public getScrollPosition(): string {
		return this.storage.getItem('scrollPosition');
	}
	public setScrollPosition(value: string): void {
		this.storage.setItem('scrollPosition', value);
	}
	public removeScrollPosition(): void {
		return this.storage.removeItem('scrollPosition');
	}

	/*
	 * scrollLocationHref
	 */
	public getScrollPositionHref(): string {
		return this.storage.getItem('scrollLocationHref');
	}
	public setScrollPositionHref(value: string): void {
		this.storage.setItem('scrollLocationHref', value);
	}
	public removeScrollPositionHref(): void {
		return this.storage.removeItem('scrollLocationHref');
	}

	public clearStorage() {
		this.storage.clear();
	}
}

/*
 * Local Storage Wrapper
 */
interface LocalStorage {
	preferences: LocalStoragePreferences;
}
interface LocalStoragePreferences {
	hasDisabledEscortButton: boolean;
	homeListingView: ListingView;
	hasVisitorAgreementAccepted: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {
	constructor(
		@Inject(LocalStorage) private storage: IStorage<keyof LocalStorage>
	) {}

	/*
	 * preferences.hasDisabledEscortButton
	 */
	public getIsBecomeEscortHeaderButtonDisabled(): boolean {
		const storagePreferences: LocalStoragePreferences = JSON.parse(
			this.storage.getItem('preferences')
		);
		return storagePreferences && storagePreferences.hasDisabledEscortButton;
	}
	public setIsBecomeEscortHeaderButtonDisabled(value: boolean): void {
		const storagePreferences: LocalStoragePreferences = JSON.parse(
			this.storage.getItem('preferences')
		);

		this.storage.setItem(
			'preferences',
			JSON.stringify({
				...storagePreferences,
				hasDisabledEscortButton: value
			})
		);
	}

	/*
	 * preferences.homeListingView
	 */
	public geHomeListingView(): ListingView {
		const storagePreferences: LocalStoragePreferences = JSON.parse(
			this.storage.getItem('preferences')
		);
		// Check to make sure value is valid
		const view = ['grid', 'view'].includes(
			storagePreferences && storagePreferences.homeListingView
		)
			? storagePreferences && storagePreferences.homeListingView
			: null;
		return view as ListingView;
	}
	public setHomeListingView(value: ListingView): void {
		const storagePreferences: LocalStoragePreferences = JSON.parse(
			this.storage.getItem('preferences')
		);

		this.storage.setItem(
			'preferences',
			JSON.stringify({
				...storagePreferences,
				homeListingView: value
			})
		);
	}

	/*
	 * preferences.hasVisitorAgreementAccepted
	 */
	public getIsVisitorAgreementAccepted(): boolean {
		const storagePreferences: LocalStoragePreferences = JSON.parse(
			this.storage.getItem('preferences')
		);
		return (
			storagePreferences && storagePreferences.hasVisitorAgreementAccepted
		);
	}
	public setIsVisitorAgreementAccepted(value: boolean): void {
		const storagePreferences: LocalStoragePreferences = JSON.parse(
			this.storage.getItem('preferences')
		);

		this.storage.setItem(
			'preferences',
			JSON.stringify({
				...storagePreferences,
				hasVisitorAgreementAccepted: value
			})
		);
	}

	public clearStorage() {
		this.storage.clear();
	}
}
