import { Action } from '@ngrx/store';
import { Error } from 'src/app/shared/services';
import { Report } from '../../models';


export const LISTING_REPORT_DIALOG_INITALISE           	= '[Listing Report Dialog] Initialise';
export const LISTING_REPORT_DIALOG_REPORT_LISTING       = '[Listing Report Dialog] Report Listing';
export const CLUB_API_POST_REPORT                       = '[Club API] Post Report';
export const CLUB_API_POST_REPORT_FAIL                  = '[Club API] Post Report Fail';
export const CLUB_API_POST_REPORT_SUCCESS               = '[Club API] Post Report Success';

export class ListingReportDialogInitialise implements Action {
	readonly type = LISTING_REPORT_DIALOG_INITALISE;
	constructor() {}
}

export class ListingReportDialogReportListing implements Action {
	readonly type = LISTING_REPORT_DIALOG_REPORT_LISTING;
	constructor(public payload: Report) {}
}

export class ClubApiPostReport implements Action {
	readonly type = CLUB_API_POST_REPORT;
	constructor(public payload: Report) {}
}

export class ClubApiPostReportFail implements Action {
	readonly type = CLUB_API_POST_REPORT_FAIL;
	constructor(public errorMessage: Error) {}
}

export class ClubApiPostReportSuccess implements Action {
	readonly type = CLUB_API_POST_REPORT_SUCCESS;
	constructor() {}
}

// action types
export type ReportsAction =
	| ListingReportDialogInitialise
	| ListingReportDialogReportListing
	| ClubApiPostReport
	| ClubApiPostReportFail
	| ClubApiPostReportSuccess
	;
