import { createSelector } from '@ngrx/store';

import * as fromRoot from '../../../store';
import * as fromCore from '../reducers';
import * as fromCoreAuth from '../reducers/auth.reducer';
import * as fromCoreNotifications from '../reducers/notifications.reducer';


export const getNotificationState = createSelector(
	fromCore.getCoreState,
	(state: fromCore.CoreState) => state.notifications
);

export const getNotificationsEntities = createSelector(
	getNotificationState,
	fromCoreNotifications.getNotificationsEntities
);

export const getNotificationUnacknowledged = createSelector(
	getNotificationState,
	fromCoreNotifications.getNotificationUnacknowledged
);

// Get Notifications API call
export const getNotificationsLoading = createSelector(
	getNotificationState,
	fromCoreNotifications.getNotificationsLoading
);

export const getNotificationsLoaded = createSelector(
	getNotificationState,
	fromCoreNotifications.getNotificationsLoaded
);

export const getNotificationsError = createSelector(
	getNotificationState,
	fromCoreNotifications.getNotificationsError
);

/* 
// Update Notification API call
export const getUpdateNotificationLoading = createSelector(
	getNotificationState,
	fromCoreNotifications.getUpdateNotificationLoading
);

export const getUpdateNotificationLoaded = createSelector(
	getNotificationState,
	fromCoreNotifications.getUpdateNotificationLoaded
);

export const getUpdateNotificationError = createSelector(
	getNotificationState,
	fromCoreNotifications.getUpdateNotificationError
);

// Delete Notification API call
export const getDeleteNotificationLoading = createSelector(
	getNotificationState,
	fromCoreNotifications.getDeleteNotificationLoading
);

export const getDeleteNotificationLoaded = createSelector(
	getNotificationState,
	fromCoreNotifications.getDeleteNotificationLoaded
);

export const getDeleteNotificationError = createSelector(
	getNotificationState,
	fromCoreNotifications.getDeleteNotificationError
); */
