import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HomeListingNewListingSnackbarViewComponent } from './home-listing-new-listing-snackbar-view.component';

@Injectable({
	providedIn: 'root'
})
export class HomeListingNewListingSnackbarViewService {
	constructor(private snackbar: MatSnackBar) {}

	public open(): void {
		this.snackbar.openFromComponent(
			HomeListingNewListingSnackbarViewComponent,
			{
				horizontalPosition: 'center',
				verticalPosition: 'top',
				duration: 3000,
				panelClass: 'club-snackbar-new-listing'
			}
		);
	}
}
