import * as fromCoreAuth from '../actions/auth.action';
import * as fromCoreEscorts from '../actions/escorts.action';
import * as fromCoreListings from '../actions/listings.action';

import { Escort } from 'src/library/features/models';
import {
	CallState,
	getError,
	LoadingState
} from 'src/library/features/store/reducers/call-state';

export interface EscortState {
	entities: { [handle: string]: Escort };
	handleValidationState: CallState;
	createState: CallState;
	updateState: CallState;
	verificationState: CallState;
}

export const initialState: EscortState = {
	entities: {},
	handleValidationState: LoadingState.INIT,
	createState: LoadingState.INIT,
	updateState: LoadingState.INIT,
	verificationState: LoadingState.INIT
};

export function reducer(
	state = initialState,
	action:
		| fromCoreAuth.AuthAction
		| fromCoreEscorts.EscortsAction
		| fromCoreListings.ListingsAction
): EscortState {
	switch (action.type) {
		case fromCoreListings.CLUB_API_GET_USER_LISTING_SUCCESS:
		case fromCoreListings.CLUB_API_GET_USER_LISTINGS_SUCCESS:
		case fromCoreAuth.CLUB_API_GET_AUTH_USER_DETAILS_SUCCESS: {
			const escorts = action.payload.entities.escorts;
			const entities = {
				...state.entities,
				...escorts
			};

			return {
				...state,
				entities
			};
		}

		case fromCoreEscorts.CLUB_API_POST_HANDLE_VALIDATION: {
			return {
				...state,
				handleValidationState: LoadingState.LOADING
			};
		}

		case fromCoreEscorts.CLUB_API_POST_HANDLE_VALIDATION_SUCCESS: {
			return {
				...state,
				handleValidationState: LoadingState.LOADED
			};
		}

		case fromCoreEscorts.CLUB_API_POST_HANDLE_VALIDATION_FAIL: {
			return {
				...state,
				handleValidationState: { errorMessage: action.errorMessage }
			};
		}

		case fromCoreEscorts.CLUB_API_POST_CREATE_ESCORT: {
			return {
				...state,
				createState: LoadingState.LOADING
			};
		}

		case fromCoreEscorts.CLUB_API_POST_CREATE_ESCORT_SUCCESS: {
			const escorts = action.payload.entities.escorts;
			const entities = {
				...state.entities,
				...escorts
			};

			return {
				...state,
				entities,
				createState: LoadingState.LOADED
			};
		}

		case fromCoreEscorts.CLUB_API_POST_CREATE_ESCORT_FAIL: {
			return {
				...state,
				createState: { errorMessage: action.errorMessage }
			};
		}

		case fromCoreEscorts.CLUB_API_PUT_UPDATE_ESCORT: {
			return {
				...state,
				updateState: LoadingState.LOADING
			};
		}

		case fromCoreEscorts.CLUB_API_PUT_UPDATE_ESCORT_SUCCESS: {
			const escorts = action.payload.entities.escorts;
			const entities = {
				...state.entities,
				...escorts
			};

			return {
				...state,
				entities,
				updateState: LoadingState.LOADED
			};
		}

		case fromCoreEscorts.CLUB_API_PUT_UPDATE_ESCORT_FAIL: {
			return {
				...state,
				updateState: { errorMessage: action.errorMessage }
			};
		}

		case fromCoreEscorts.CLUB_API_POST_ESCORT_VERIFICATION: {
			return {
				...state,
				createState: LoadingState.LOADING
			};
		}

		case fromCoreEscorts.CLUB_API_POST_ESCORT_VERIFICATION_SUCCESS: {
			return {
				...state,
				createState: LoadingState.LOADED
			};
		}

		case fromCoreEscorts.CLUB_API_POST_ESCORT_VERIFICATION_FAIL: {
			return {
				...state,
				createState: { errorMessage: action.errorMessage }
			};
		}

		default:
			return state;
	}
}

export const getEscortsEntities = (state: EscortState) => state.entities;
export const getHandleValidationLoading = (state: EscortState) =>
	state.handleValidationState === LoadingState.LOADING;
export const getHandleValidationLoaded = (state: EscortState) =>
	state.handleValidationState === LoadingState.LOADED;
export const getHandleValidationError = (state: EscortState) =>
	getError(state.handleValidationState);
export const getCreateEscortLoading = (state: EscortState) =>
	state.createState === LoadingState.LOADING;
export const getCreateEscortLoaded = (state: EscortState) =>
	state.createState === LoadingState.LOADED;
export const getCreateEscortError = (state: EscortState) =>
	getError(state.createState);
export const getUpdateEscortLoading = (state: EscortState) =>
	state.updateState === LoadingState.LOADING;
export const getUpdateEscortLoaded = (state: EscortState) =>
	state.updateState === LoadingState.LOADED;
export const getUpdateEscortError = (state: EscortState) =>
	getError(state.updateState);
export const getEscortVerificationLoading = (state: EscortState) =>
	state.verificationState === LoadingState.LOADING;
export const getEscortVerificationLoaded = (state: EscortState) =>
	state.verificationState === LoadingState.LOADED;
export const getEscortVerificationError = (state: EscortState) =>
	getError(state.verificationState);
