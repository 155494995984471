import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/shared/services';

@Injectable()
export class VisitorAgreementGuard implements CanActivate {
	constructor(
		private localStorage: LocalStorageService,
		private router: Router,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		
		if (!this.localStorage.getIsVisitorAgreementAccepted()) {
			this.router.navigate(['/disclaimer'], {
				queryParams: { redirect: state.url },
				replaceUrl: true
			});
			return false;
		} else {
			return true;
		}
	}
}
