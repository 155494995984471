import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import {
	CustomHttpService,
	ErrorService,
	LogService
} from 'src/app/shared/services';
import {
	ClubAPIResponseService
} from './api-response.service';
import { environment } from 'src/environments/environment';
import { Escort } from '../models';

@Injectable({
	providedIn: 'root'
})
export class EscortsService {
	// API environment variables
	private ENV = `${environment.apiUrl}/${environment.apiVersion}`;
	// API Request information
	private API_END_POINT = 'escorts'; // * Modify to change API path

	private URL = `${this.ENV}/${this.API_END_POINT}`;
	private SERVICE_NAME = `API (${this.API_END_POINT})`;

	constructor(
		private http: CustomHttpService,
		private logService: LogService,
		private errorService: ErrorService
	) {}

	private log(message: string) {
		this.logService.add(`${this.SERVICE_NAME}: ${message}`, 'success');
	}

	/**
	 * Get Escort by handle
	 * @param handle - handle
	 */
	getEscort(handle: string): Observable<Escort> {
		const url = `${this.URL}/${handle}`;
		return this.http.get(url).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<Escort>(
					res,
					'Escort',
					false
				)
			),
			tap((_) => this.log(`getEscort ${handle} success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'getEscort'
				)
			)
		);
	}
}
