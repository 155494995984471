import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { catchError, map, tap } from 'rxjs/operators';
import {
	LogService,
	ErrorService,
	CustomHttpService
} from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { ClubAPIResponse } from '..';

export interface EscortHandleValidationContext {
	handle: string;
}

interface APIRequestModel {
	handle: string;
}

@Injectable({
	providedIn: 'root'
})
export class AuthEscortHandleValidationService {
	// API environment variables
	private ENV = `${environment.apiUrl}/${environment.apiVersion}`;
	// API Request information
	private API_END_POINT = 'escort/verification/handle'; // * Modify to change API path

	private URL = `${this.ENV}/${this.API_END_POINT}`;
	private SERVICE_NAME = `API (${this.API_END_POINT})`;

	constructor(
		private http: CustomHttpService,
		private logService: LogService,
		private errorService: ErrorService
	) {}

	private log(message: string) {
		this.logService.add(`${this.SERVICE_NAME}: ${message}`, 'success');
	}

	/**
	 * Escort Handle verification
	 */
	checkValidEscortHandle(
		context: EscortHandleValidationContext
	): Observable<any> {
		const req = this.requestConverter(context);
		return this.http
			.post<HttpResponse<ClubAPIResponse<any>>>(this.URL, req)
			.pipe(
				tap((_) => this.log(`Check valid escort handle Successfully.`)),
				catchError((res: HttpErrorResponse) =>
					this.errorService.handleAPIHttpError(
						res,
						this.SERVICE_NAME,
						'checkValidEscortHandle'
					)
				)
			);
	}

	/**
	 * Convert Context to API request
	 */
	private requestConverter(
		context: EscortHandleValidationContext
	): APIRequestModel {
		const req: APIRequestModel = {
			handle: context.handle
		};
		return req;
	}
}
