import { LoadingState, ErrorState, CallState, getError } from './call-state';

import * as fromCoreListings from 'src/app/core/store/actions/listings.action';

export interface UserEscortListingState {
	listingOrder: string[];
	viewState: CallState;
}

export const initialState: UserEscortListingState = {
	listingOrder: [],
	viewState: LoadingState.INIT
};

export function reducer(
	state = initialState,
	action: fromCoreListings.ListingsAction
): UserEscortListingState {
	switch (action.type) {

		case fromCoreListings.CLUB_API_GET_USER_LISTINGS_SUCCESS: {
			const listingOrder = action.payload.result;

			return {
				...state,
				listingOrder
			};
		}

		default:
			return state;
	}
}

export const getUserEscortListingLoading 				= (state: UserEscortListingState) => state.viewState === LoadingState.LOADING;
export const getUserEscortListingLoaded 				= (state: UserEscortListingState) => state.viewState === LoadingState.LOADED;
export const getUserEscortListingError 					= (state: UserEscortListingState) => getError(state.viewState);
export const getUserEscortListingOrder 					= (state: UserEscortListingState) => state.listingOrder;