import { LoadingState, ErrorState, CallState, getError } from './call-state';

import * as fromHome from '../actions/home.action';
import * as fromFeatureListings from '../../../features/store/actions/listings.action';

export interface HomeState {
	listingPage: number;
	listingOrder: string[];
	listingNewEntities: { [key: string]: string };
	newListingQueue: string[];
	viewState: CallState;
}

export const initialState: HomeState = {
	listingPage: 1,
	listingOrder: [],
	listingNewEntities: {},
	newListingQueue: [],
	viewState: LoadingState.INIT
};

export function reducer(
	state = initialState,
	action: fromHome.HomeAction | fromFeatureListings.ListingsAction
): HomeState {
	switch (action.type) {
		case fromHome.HOME_PAGE_RESET_LISTING_PAGE_SUCCESS: {
			const pageNumber = action.payload;
			return {
				...state,
				listingOrder: [],
				listingPage: pageNumber
			};
		}

		case fromHome.HOME_PAGE_INCREMENT_LISTING_PAGE_SUCCESS: {
			const pageNumber = action.payload;
			return {
				...state,
				listingPage: pageNumber
			};
		}

		case fromFeatureListings.CLUB_API_GET_LATEST_LISTINGS_SUCCESS: {
			const newListings = action.payload.result;
			const oldlistings = state.listingOrder;
			const listingArray = [];
			listingArray.push(...oldlistings, ...newListings);
			// remove duplicates
			const listingOrder = Array.from(new Set(listingArray));

			return {
				...state,
				listingOrder
			};
		}

		case fromHome.HOME_PAGE_PUSH_NEW_LISTINGS_TO_TOP: {
			const newListings = [action.payload];
			const oldlistings = state.listingOrder;
			const listingArray = [];
			listingArray.push(...newListings, ...oldlistings);
			// remove duplicates
			const listingOrder = Array.from(new Set(listingArray));

			// Add listings to be marked as new
			const listingNewEntities = {
				...state.listingNewEntities,
				[action.payload]: action.payload
			};

			return {
				...state,
				listingOrder,
				listingNewEntities
			};
		}

		case fromHome.HOME_PAGE_PUSH_NEW_LISTINGS_TO_QUEUE: {
			const newListings = [action.payload];
			const oldlistings = state.newListingQueue;
			const listingArray = [];
			listingArray.push(...newListings, ...oldlistings);
			// remove duplicates
			const newListingQueue = Array.from(new Set(listingArray));

			return {
				...state,
				newListingQueue
			};
		}

		case fromHome.HOME_PAGE_PUSH_NEW_LISTINGS_QUEUE: {
			const newListings = state.newListingQueue;
			const oldlistings = state.listingOrder;
			const listingArray = [];
			listingArray.push(...newListings, ...oldlistings);
			// remove duplicates
			const listingOrder = Array.from(new Set(listingArray));

			// Add listings to be marked as new
			// Convert array into Object with key string
			const listingNewEntities = newListings.reduce((acc, curr) => {
				acc[curr] = curr;
				return acc;
			}, {});
			// Clear new listing queue
			const newListingQueue = [];

			return {
				...state,
				listingOrder,
				listingNewEntities,
				newListingQueue
			};
		}

		default:
			return state;
	}
}

export const getHomeLoading 			= (state: HomeState) => state.viewState === LoadingState.LOADING;
export const getHomeLoaded 				= (state: HomeState) => state.viewState === LoadingState.LOADED;
export const getHomeError 				= (state: HomeState) => getError(state.viewState);
export const getHomeListingPage 		= (state: HomeState) => state.listingPage;
export const getHomeListingOrder 		= (state: HomeState) => state.listingOrder;
export const getHomeNewListingsEntities = (state: HomeState) =>state.listingNewEntities;
export const getHomeNewListingQueue 	= (state: HomeState) =>state.newListingQueue;
