import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { ClubAPIAuthResponse } from './api-response.service';
import {
	LogService,
	ErrorService,
	CustomHttpService
} from 'src/app/shared/services';

export interface UserUsernameValidationContext {
	username: string;
}

interface APIRequestModel {
	username: string;
}

@Injectable({
	providedIn: 'root'
})
export class UserUsernameValidationService {
	// API environment variables
	private ENV = `${environment.apiUrl}/${environment.apiVersion}`;
	// API Request information
	private API_END_POINT = 'user/verification/username'; // * Modify to change API path

	private URL = `${this.ENV}/${this.API_END_POINT}`;
	private SERVICE_NAME = `API (${this.API_END_POINT})`;

	constructor(
		private http: CustomHttpService,
		private logService: LogService,
		private errorService: ErrorService
	) {}

	private log(message: string) {
		this.logService.add(`${this.SERVICE_NAME}: ${message}`, 'success');
	}

	/**
	 * User email verification
	 */
	checkValidUsername(
		context: UserUsernameValidationContext
	): Observable<any> {
		const req = this.requestConverter(context);
		return this.http
			.post<HttpResponse<ClubAPIAuthResponse>>(this.URL, req)
			.pipe(
				tap((_) => this.log(`Check valid username Successfully.`)),
				catchError((res: HttpErrorResponse) =>
					this.errorService.handleAPIHttpError(
						res,
						this.SERVICE_NAME,
						'checkValidUsername'
					)
				)
			);
	}

	/**
	 * Convert Context to API request
	 */
	private requestConverter(
		context: UserUsernameValidationContext
	): APIRequestModel {
		const req: APIRequestModel = {
			username: context.username
		};
		return req;
	}
}
