import { LoadingState, ErrorState, CallState, getError } from './call-state';

import * as fromUserNotification from '../actions/user-notification.action';
import * as fromCoreNotifications from 'src/app/core/store/actions/notifications.action';

export interface UserNotificationState {
	notificationPage: number;
	notificationOrder: string[];
	moreNotifications: boolean;
}

export const initialState: UserNotificationState = {
	notificationPage: 1,
	notificationOrder: [],
	moreNotifications: true,
};

export function reducer(
	state = initialState,
	action: fromUserNotification.UserNotificationAction | fromCoreNotifications.NotificationsAction
): UserNotificationState {
	switch (action.type) {
		case fromUserNotification.HEADER_NOTIFICATION_COMPONENT_OPEN_COMPONENT: {
			return {
				...state,
				notificationOrder: [],
				notificationPage: 1,
				moreNotifications: true
			};
		}

		case fromCoreNotifications.CLUB_API_GET_NOTIFICATIONS_SUCCESS: {
			const newNotifications = action.payload.result;
			const oldnotifications = state.notificationOrder;
			const newNotificationPage = newNotifications.length !== 0 ? state.notificationPage + 1 : state.notificationPage;
			const moreNotifications = newNotifications.length !== 0;
			const notificationArray = [];
			notificationArray.push(...oldnotifications, ...newNotifications);
			// remove duplicates
			const notificationOrder = Array.from(new Set(notificationArray));

			return {
				...state,
				notificationOrder,
				notificationPage: newNotificationPage,
				moreNotifications
			};
		}

		case fromCoreNotifications.CLUB_API_DELETE_NOTIFICATION: {
			// Remove Notification from list
			const newOrder = state.notificationOrder.filter((value) => {
				return value !== action.payload
			})

			return {
				...state,
				notificationOrder: newOrder
			}
		}

		default:
			return state;
	}
}

export const getUserNotificationPage 						= (state: UserNotificationState) => state.notificationPage;
export const getUserNotificationOrder 						= (state: UserNotificationState) => state.notificationOrder;
export const getUserNotificationMoreNotificationsLeft		= (state: UserNotificationState) => state.moreNotifications;