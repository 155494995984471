<div class="snackbar" (animationend)="animationDone($event)" #snackBar>
	<div
		class="snackbar-container"
		[ngClass]="{'default': type === 'default', 'success': type === 'success', 'error': type === 'error'}"
		(click)="onClick()"
	>
		<div class="snackbar-background">
			<div class="snackbar-background-image"></div>
		</div>
		<div class="snackbar-content">
			<div class="text">{{ content }}</div>
			<button
				mat-icon-button
				*ngIf="duration === 0"
				class="club-primary-transparent"
				disableRipple="true"
				(click)="close()"
			>
				&#x2715;
			</button>
		</div>
	</div>
</div>
