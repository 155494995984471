import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router
} from '@angular/router';
import { Observable, of } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromCore from '../store';
import { map, skipWhile, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class EscortUnverifiedGuard implements CanActivate {
	constructor(
		private router: Router,
		private store: Store<fromCore.CoreState>
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		return this.isUnverifiedEscort$().pipe(
			switchMap((isNotVerifiedEscort) => {
				if (isNotVerifiedEscort) {
					return of(true);
				} else {
					this.router.navigate(['/user/dashboard'], {
						replaceUrl: true
					});
					return of(false);
				}
			})
		);
	}

	isUnverifiedEscort$(): Observable<boolean> {
		return this.store.select(fromCore.getCoreState).pipe(
			skipWhile((core) => core.auth.authGuardCheck), // Wait for core user details to finish loading
			switchMap(() =>
				this.store
					.select(fromCore.getAuthUserView)
					.pipe(
						map(
							(user) =>
								!user.escort ||
								(user.escort &&
									(user.escort.verifiedStatus ===
										'unverified' ||
										user.escort.verifiedStatus ===
											'rejected'))
						)
					)
			),
			take(1)
		);
	}
}
