import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, tap, withLatestFrom } from 'rxjs/operators';

import * as homeActions from '../actions/home.action';
import * as socketActions from '../actions/socket.action';
import * as fromUI from '../../store';

import { Store } from '@ngrx/store';
import { ScrollService } from 'src/app/shared/services';
import { HomeListingNewListingSnackbarViewService } from 'src/app/modules/pages/home/listing-new-listing-snackbar-view/home-listing-new-listing-snackbar-view.service';

@Injectable()
export class HomeEffects {
	constructor(
		private actions$: Actions,
		private store: Store<fromUI.UIState>,
		private scrollService: ScrollService,
		private homeListingNewListingSnackbarService: HomeListingNewListingSnackbarViewService
	) {}

	// Reset Listing Page Number to 1
	homePageResetListingPage$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(homeActions.HOME_PAGE_RESET_LISTING_PAGE),
			map(() => {
				return new homeActions.HomePageResetListinPageSuccess(1);
			})
		);
	});

	// Increments Home Page Listing Page Number
	homePageIncrementListingPage$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(homeActions.HOME_PAGE_INCREMENT_LISTING_PAGE),
			withLatestFrom(
				this.store.select(fromUI.getHomeState),
				(action: homeActions.HomePageIncrementListingPage, ui) => {
					return ui.listingPage;
				}
			),
			map((listingPageNumber: number) => {
				const newListingPageNumber = listingPageNumber + 1;
				return new homeActions.HomePageIncrementListingPageSuccess(
					newListingPageNumber
				);
			})
		);
	});

	// Check to see where the user view is currently
	// If they are at the top, to push listing into view, else to add to queue
	pushListingToQueueOrDisplay$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(socketActions.WEBSOCKET_SERVICE_GET_NEW_LISTING),
			withLatestFrom(
				this.store.select(fromUI.getHomeState),
				(action: socketActions.WebSocketServiceGetNewListing, ui) => {
					return {
						action,
						newListingQueue: ui.newListingQueue
					};
				}
			),
			map(({ action, newListingQueue }) => {
				// Fetch current scroll position
				// If not set to assume 0
				const currentScrollY =
					this.scrollService.getStoredScrollPosition()
						? this.scrollService.getStoredScrollPosition()[1]
						: 0;

				// Allow Yposition offset before listings are added to queue
				const scrollOffset = 100;
				// If scrollView is past offset and there is no new listings in queue
				if (
					currentScrollY < scrollOffset &&
					newListingQueue.length === 0
				) {
					// Push into View
					return new homeActions.HomePagePushNewListingsToTop(
						action.payload.result
					);
				} else {
					// Add to Queue
					return new homeActions.HomePagePushNewListingsToQueue(
						action.payload.result
					);
				}
			})
		);
	});

	// Push new listings to queue and to display snackbar
	homePageShowNewListingSnackbar$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(homeActions.HOME_PAGE_PUSH_NEW_LISTINGS_TO_QUEUE),
				tap(() => {
					this.homeListingNewListingSnackbarService.open();
				})
			),
		{ dispatch: false }
	);

	// Tries to push new listings queue into listing
	// If there are more than 14 listings in the queue, to force window reload (temp fix for pagination)
	homePageViewNewListingsQueue$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(homeActions.HOME_PAGE_VIEW_NEW_LISTINGS_QUEUE),
			withLatestFrom(
				this.store.select(fromUI.getHomeState),
				(action: homeActions.HomePageViewNewListingsQueue, ui) => {
					return {
						newListingQueue: ui.newListingQueue
					};
				}
			),
			map((action) => {
				const reloadLimit = 14; // Number of listings required before refreshing page
				const newListingsNo = action.newListingQueue.length;
				if (newListingsNo > reloadLimit) {
					return new homeActions.HomePageReloadPage();
				} else {
					this.scrollService.scrollToTop();
					return new homeActions.HomePagePushNewListingsQueue();
				}
			})
		);
	});

	// Reload Page
	homePageReload$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(homeActions.HOME_PAGE_RELOAD_PAGE),
				tap(() => window.location.reload())
			),
		{ dispatch: false }
	);
}
