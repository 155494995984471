import * as fromEscortTags from '../actions/escort-tags.action';

import { LoadingState, ErrorState, CallState, getError } from './call-state';

import { EscortTagType } from '../../models';

export interface EscortTagsState {
	ethnicities: {
		entities: { [ID: number]: EscortTagType };
		callState: CallState;
	};
	hairColours: {
		entities: { [ID: number]: EscortTagType };
		callState: CallState;
	};
	eyeColours: {
		entities: { [ID: number]: EscortTagType };
		callState: CallState;
	};
	genders: {
		entities: { [ID: number]: EscortTagType };
		callState: CallState;
	};
	sexualPreferences: {
		entities: { [ID: number]: EscortTagType };
		callState: CallState;
	};
	bodyTypes: {
		entities: { [ID: number]: EscortTagType };
		callState: CallState;
	};
	spokenLanguages: {
		entities: { [ID: number]: EscortTagType };
		callState: CallState;
	};
	plays: {
		entities: { [ID: number]: EscortTagType };
		callState: CallState;
	};
}

export const initialState: EscortTagsState = {
	ethnicities: {
		entities: {},
		callState: LoadingState.INIT
	},
	hairColours: {
		entities: {},
		callState: LoadingState.INIT
	},
	eyeColours: {
		entities: {},
		callState: LoadingState.INIT
	},
	genders: {
		entities: {},
		callState: LoadingState.INIT
	},
	sexualPreferences: {
		entities: {},
		callState: LoadingState.INIT
	},
	bodyTypes: {
		entities: {},
		callState: LoadingState.INIT
	},
	spokenLanguages: {
		entities: {},
		callState: LoadingState.INIT
	},
	plays: {
		entities: {},
		callState: LoadingState.INIT
	}
};

export function reducer(
	state = initialState,
	action: fromEscortTags.EscortTagsAction
): EscortTagsState {
	switch (action.type) {
		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_ETHNICITIES: {
			return {
				...state,
				ethnicities: {
					...state.ethnicities,
					callState: LoadingState.LOADING
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_ETHNICITIES_SUCCESS: {
			const tags = action.payload;
			const entities = {
				...state.ethnicities.entities,
				...tags
			};

			return {
				...state,
				ethnicities: {
					entities,
					callState: LoadingState.LOADED
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_ETHNICITIES_FAIL: {
			return {
				...state,
				ethnicities: {
					...state.ethnicities,
					callState: { errorMessage: action.errorMessage }
				}
			};
		}


		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_HAIR_COLOURS: {
			return {
				...state,
				hairColours: {
					...state.hairColours,
					callState: LoadingState.LOADING
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_HAIR_COLOURS_SUCCESS: {
			const tags = action.payload;
			const entities = {
				...state.hairColours.entities,
				...tags
			};

			return {
				...state,
				hairColours: {
					entities,
					callState: LoadingState.LOADED
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_HAIR_COLOURS_FAIL: {
			return {
				...state,
				hairColours: {
					...state.hairColours,
					callState: { errorMessage: action.errorMessage }
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_EYE_COLOURS: {
			return {
				...state,
				eyeColours: {
					...state.eyeColours,
					callState: LoadingState.LOADING
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_EYE_COLOURS_SUCCESS: {
			const tags = action.payload;
			const entities = {
				...state.eyeColours.entities,
				...tags
			};

			return {
				...state,
				eyeColours: {
					entities,
					callState: LoadingState.LOADED
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_EYE_COLOURS_FAIL: {
			return {
				...state,
				eyeColours: {
					...state.eyeColours,
					callState: { errorMessage: action.errorMessage }
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_GENDERS: {
			return {
				...state,
				genders: {
					...state.genders,
					callState: LoadingState.LOADING
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_GENDERS_SUCCESS: {
			const tags = action.payload;
			const entities = {
				...state.genders.entities,
				...tags
			};

			return {
				...state,
				genders: {
					entities,
					callState: LoadingState.LOADED
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_GENDERS_FAIL: {
			return {
				...state,
				genders: {
					...state.genders,
					callState: { errorMessage: action.errorMessage }
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_SEXUAL_PREFERENCES: {
			return {
				...state,
				sexualPreferences: {
					...state.sexualPreferences,
					callState: LoadingState.LOADING
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_SEXUAL_PREFERENCES_SUCCESS: {
			const tags = action.payload;
			const entities = {
				...state.sexualPreferences.entities,
				...tags
			};

			return {
				...state,
				sexualPreferences: {
					entities,
					callState: LoadingState.LOADED
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_SEXUAL_PREFERENCES_FAIL: {
			return {
				...state,
				sexualPreferences: {
					...state.sexualPreferences,
					callState: { errorMessage: action.errorMessage }
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_BODY_TYPES: {
			return {
				...state,
				bodyTypes: {
					...state.bodyTypes,
					callState: LoadingState.LOADING
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_BODY_TYPES_SUCCESS: {
			const tags = action.payload;
			const entities = {
				...state.bodyTypes.entities,
				...tags
			};

			return {
				...state,
				bodyTypes: {
					entities,
					callState: LoadingState.LOADED
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_BODY_TYPES_FAIL: {
			return {
				...state,
				bodyTypes: {
					...state.bodyTypes,
					callState: { errorMessage: action.errorMessage }
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_SPOKEN_LANGUAGES: {
			return {
				...state,
				spokenLanguages: {
					...state.spokenLanguages,
					callState: LoadingState.LOADING
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_SPOKEN_LANGUAGES_SUCCESS: {
			const tags = action.payload;
			const entities = {
				...state.spokenLanguages.entities,
				...tags
			};

			return {
				...state,
				spokenLanguages: {
					entities,
					callState: LoadingState.LOADED
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_SPOKEN_LANGUAGES_FAIL: {
			return {
				...state,
				spokenLanguages: {
					...state.spokenLanguages,
					callState: { errorMessage: action.errorMessage }
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_PLAYS: {
			return {
				...state,
				plays: {
					...state.plays,
					callState: LoadingState.LOADING
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_PLAYS_SUCCESS: {
			const tags = action.payload;
			const entities = {
				...state.plays.entities,
				...tags
			};

			return {
				...state,
				plays: {
					entities,
					callState: LoadingState.LOADED
				}
			};
		}

		case fromEscortTags.CLUB_API_GET_ESCORT_TAG_PLAYS_FAIL: {
			return {
				...state,
				plays: {
					...state.plays,
					callState: { errorMessage: action.errorMessage }
				}
			};
		}

		default:
			return state;
	}
}

export const getEthnicitiesLoading 			= (state: EscortTagsState) => state.ethnicities.callState === LoadingState.LOADING;
export const getEthnicitiesLoaded 			= (state: EscortTagsState) => state.ethnicities.callState === LoadingState.LOADED;
export const getEthnicitiesError 			= (state: EscortTagsState) => getError(state.ethnicities.callState);
export const getEthnicitiesEntities 		= (state: EscortTagsState) => state.ethnicities.entities;
export const getHairColoursLoading 			= (state: EscortTagsState) => state.hairColours.callState === LoadingState.LOADING;
export const getHairColoursLoaded 			= (state: EscortTagsState) => state.hairColours.callState === LoadingState.LOADED;
export const getHairColoursError 			= (state: EscortTagsState) => getError(state.hairColours.callState);
export const getHairColoursEntities 		= (state: EscortTagsState) => state.hairColours.entities;
export const getEyeColoursLoading 			= (state: EscortTagsState) => state.eyeColours.callState === LoadingState.LOADING;
export const getEyeColoursLoaded 			= (state: EscortTagsState) => state.eyeColours.callState === LoadingState.LOADED;
export const getEyeColoursError 			= (state: EscortTagsState) => getError(state.eyeColours.callState);
export const getEyeColoursEntities 			= (state: EscortTagsState) => state.eyeColours.entities;
export const getGendersLoading 				= (state: EscortTagsState) => state.genders.callState === LoadingState.LOADING;
export const getGendersLoaded 				= (state: EscortTagsState) => state.genders.callState === LoadingState.LOADED;
export const getGendersError 				= (state: EscortTagsState) => getError(state.genders.callState);
export const getGendersEntities 			= (state: EscortTagsState) => state.genders.entities;
export const getSexualPreferencesLoading 	= (state: EscortTagsState) => state.sexualPreferences.callState === LoadingState.LOADING;
export const getSexualPreferencesLoaded 	= (state: EscortTagsState) => state.sexualPreferences.callState === LoadingState.LOADED;
export const getSexualPreferencesError 		= (state: EscortTagsState) => getError(state.sexualPreferences.callState);
export const getSexualPreferencesEntities 	= (state: EscortTagsState) => state.sexualPreferences.entities;
export const getBodyTypesLoading 			= (state: EscortTagsState) => state.bodyTypes.callState === LoadingState.LOADING;
export const getBodyTypesLoaded 			= (state: EscortTagsState) => state.bodyTypes.callState === LoadingState.LOADED;
export const getBodyTypesError 				= (state: EscortTagsState) => getError(state.bodyTypes.callState);
export const getBodyTypesEntities 			= (state: EscortTagsState) => state.bodyTypes.entities;
export const getSpokenLanguagesLoading 		= (state: EscortTagsState) => state.spokenLanguages.callState === LoadingState.LOADING;
export const getSpokenLanguagesLoaded 		= (state: EscortTagsState) => state.spokenLanguages.callState === LoadingState.LOADED;
export const getSpokenLanguagesError 		= (state: EscortTagsState) => getError(state.spokenLanguages.callState);
export const getSpokenLanguagesEntities 	= (state: EscortTagsState) => state.spokenLanguages.entities;
export const getPlaysLoading 				= (state: EscortTagsState) => state.plays.callState === LoadingState.LOADING;
export const getPlaysLoaded 				= (state: EscortTagsState) => state.plays.callState === LoadingState.LOADED;
export const getPlaysError 					= (state: EscortTagsState) => getError(state.plays.callState);
export const getPlaysEntities 				= (state: EscortTagsState) => state.plays.entities;