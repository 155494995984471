import { HomeEffects } from './home.effect';
import { GlobalEffects } from './global.effect';
import { SocketEffects } from './socket.effect';
import { UserEscortListingProfileEffects } from './user-escort-listing-profile.effect';
import { ListingSearchEffects } from './listing-search.effect';
import { UserNotificationEffects } from './user-notification.effect';

export const effects: any[] = [
	HomeEffects,
	ListingSearchEffects,
	GlobalEffects,
	SocketEffects,
	UserEscortListingProfileEffects,
	UserNotificationEffects
];

export * from './home.effect';
export * from './listing-search.effect';
export * from './global.effect';
export * from './socket.effect';
export * from './user-escort-listing-profile.effect';
export * from './user-notification.effect';
