import { Action } from '@ngrx/store';
import { Error } from 'src/app/shared/services';


export const ESCORT_PROFILE_PAGE_FETCH_ESCORT           = '[Escort Profile Page] Fetch Escort';
export const CLUB_API_GET_ESCORT                        = '[Club API] Get Escort';
export const CLUB_API_GET_ESCORT_FAIL                   = '[Club API] Get Escort Fail';
export const CLUB_API_GET_ESCORT_SUCCESS                = '[Club API] Get Escort Success';

export class EscortProfilePageFetchEscort implements Action {
	readonly type = ESCORT_PROFILE_PAGE_FETCH_ESCORT;
	constructor() {}
}

export class ClubApiGetEscort implements Action {
	readonly type = CLUB_API_GET_ESCORT;
	constructor(public payload: string) {}
}

export class ClubApiGetEscortFail implements Action {
	readonly type = CLUB_API_GET_ESCORT_FAIL;
	constructor(public errorMessage: Error) {}
}

export class ClubApiGetEscortSuccess implements Action {
	readonly type = CLUB_API_GET_ESCORT_SUCCESS;
	constructor(public payload: any) {}
}

// action types
export type EscortsAction =
	| EscortProfilePageFetchEscort
	| ClubApiGetEscort
	| ClubApiGetEscortFail
	| ClubApiGetEscortSuccess;
