import { createSelector } from '@ngrx/store';
import { EscortTags } from '../../models';

import * as fromFeature from '../reducers';
import * as fromEscortTags from '../reducers/escort-tags.reducer';

export const getEscortTagsState = createSelector(
	fromFeature.getFeaturesState,
	(state: fromFeature.FeaturesState) => state.escortTags
);

// Ethnicities
export const getEthnicitiesEntities = createSelector(
	getEscortTagsState,
	fromEscortTags.getEthnicitiesEntities
);

export const getEthnicitiesLoaded = createSelector(
	getEscortTagsState,
	fromEscortTags.getEthnicitiesLoaded
);

export const getEthnicitiesLoading = createSelector(
	getEscortTagsState,
	fromEscortTags.getEthnicitiesLoading
);

export const getEthnicitiesError = createSelector(
	getEscortTagsState,
	fromEscortTags.getEthnicitiesError
);

export const getEthnicitiesList = createSelector(
	getEthnicitiesEntities,
	(entities) => Object.keys(entities).map((key) => entities[key])
);

export const getEthnicitiesView = createSelector(
	getEthnicitiesList,
	(tags): EscortTags[] =>
		tags.map(
			(tags) =>
				tags && {
					...tags
				}
		)
);

// Hair Colours
export const getHairColoursEntities = createSelector(
	getEscortTagsState,
	fromEscortTags.getHairColoursEntities
);

export const getHairColoursLoaded = createSelector(
	getEscortTagsState,
	fromEscortTags.getHairColoursLoaded
);

export const getHairColoursLoading = createSelector(
	getEscortTagsState,
	fromEscortTags.getHairColoursLoading
);

export const getHairColoursError = createSelector(
	getEscortTagsState,
	fromEscortTags.getHairColoursError
);

export const getHairColoursList = createSelector(
	getHairColoursEntities,
	(entities) => Object.keys(entities).map((key) => entities[key])
);

export const getHairColoursView = createSelector(
	getHairColoursList,
	(tags): EscortTags[] =>
		tags.map(
			(tags) =>
				tags && {
					...tags
				}
		)
);

// Eye Colours
export const getEyeColoursEntities = createSelector(
	getEscortTagsState,
	fromEscortTags.getEyeColoursEntities
);

export const getEyeColoursLoaded = createSelector(
	getEscortTagsState,
	fromEscortTags.getEyeColoursLoaded
);

export const getEyeColoursLoading = createSelector(
	getEscortTagsState,
	fromEscortTags.getEyeColoursLoading
);

export const getEyeColoursError = createSelector(
	getEscortTagsState,
	fromEscortTags.getEyeColoursError
);

export const getEyeColoursList = createSelector(
	getEyeColoursEntities,
	(entities) => Object.keys(entities).map((key) => entities[key])
);

export const getEyeColoursView = createSelector(
	getEyeColoursList,
	(tags): EscortTags[] =>
		tags.map(
			(tags) =>
				tags && {
					...tags
				}
		)
);

// Genders
export const getGendersEntities = createSelector(
	getEscortTagsState,
	fromEscortTags.getGendersEntities
);

export const getGendersLoaded = createSelector(
	getEscortTagsState,
	fromEscortTags.getGendersLoaded
);

export const getGendersLoading = createSelector(
	getEscortTagsState,
	fromEscortTags.getGendersLoading
);

export const getGendersError = createSelector(
	getEscortTagsState,
	fromEscortTags.getGendersError
);

export const getGendersList = createSelector(
	getGendersEntities,
	(entities) => Object.keys(entities).map((key) => entities[key])
);

export const getGendersView = createSelector(
	getGendersList,
	(tags): EscortTags[] =>
		tags.map(
			(tags) =>
				tags && {
					...tags
				}
		)
);

// Sexual Preferences
export const getSexualPreferencesEntities = createSelector(
	getEscortTagsState,
	fromEscortTags.getSexualPreferencesEntities
);

export const getSexualPreferencesLoaded = createSelector(
	getEscortTagsState,
	fromEscortTags.getSexualPreferencesLoaded
);

export const getSexualPreferencesLoading = createSelector(
	getEscortTagsState,
	fromEscortTags.getSexualPreferencesLoading
);

export const getSexualPreferencesError = createSelector(
	getEscortTagsState,
	fromEscortTags.getSexualPreferencesError
);

export const getSexualPreferencesList = createSelector(
	getSexualPreferencesEntities,
	(entities) => Object.keys(entities).map((key) => entities[key])
);

export const getSexualPreferencesView = createSelector(
	getSexualPreferencesList,
	(tags): EscortTags[] =>
		tags.map(
			(tags) =>
				tags && {
					...tags
				}
		)
);

// Body Types
export const getBodyTypesEntities = createSelector(
	getEscortTagsState,
	fromEscortTags.getBodyTypesEntities
);

export const getBodyTypesLoaded = createSelector(
	getEscortTagsState,
	fromEscortTags.getBodyTypesLoaded
);

export const getBodyTypesLoading = createSelector(
	getEscortTagsState,
	fromEscortTags.getBodyTypesLoading
);

export const getBodyTypesError = createSelector(
	getEscortTagsState,
	fromEscortTags.getBodyTypesError
);

export const getBodyTypesList = createSelector(
	getBodyTypesEntities,
	(entities) => Object.keys(entities).map((key) => entities[key])
);

export const getBodyTypesView = createSelector(
	getBodyTypesList,
	(tags): EscortTags[] =>
		tags.map(
			(tags) =>
				tags && {
					...tags
				}
		)
);

// Spoken Languages
export const getSpokenLanguagesEntities = createSelector(
	getEscortTagsState,
	fromEscortTags.getSpokenLanguagesEntities
);

export const getSpokenLanguagesLoaded = createSelector(
	getEscortTagsState,
	fromEscortTags.getSpokenLanguagesLoaded
);

export const getSpokenLanguagesLoading = createSelector(
	getEscortTagsState,
	fromEscortTags.getSpokenLanguagesLoading
);

export const getSpokenLanguagesError = createSelector(
	getEscortTagsState,
	fromEscortTags.getSpokenLanguagesError
);

export const getSpokenLanguagesList = createSelector(
	getSpokenLanguagesEntities,
	(entities) => Object.keys(entities).map((key) => entities[key])
);

export const getSpokenLanguagesView = createSelector(
	getSpokenLanguagesList,
	(tags): EscortTags[] =>
		tags.map(
			(tags) =>
				tags && {
					...tags
				}
		)
);


// Plays
export const getPlaysEntities = createSelector(
	getEscortTagsState,
	fromEscortTags.getPlaysEntities
);

export const getPlaysLoaded = createSelector(
	getEscortTagsState,
	fromEscortTags.getPlaysLoaded
);

export const getPlaysLoading = createSelector(
	getEscortTagsState,
	fromEscortTags.getPlaysLoading
);

export const getPlaysError = createSelector(
	getEscortTagsState,
	fromEscortTags.getPlaysError
);

export const getPlaysList = createSelector(
	getPlaysEntities,
	(entities) => Object.keys(entities).map((key) => entities[key])
);

export const getPlaysView = createSelector(
	getPlaysList,
	(tags): EscortTags[] =>
		tags.map(
			(tags) =>
				tags && {
					...tags
				}
		)
);
