import { LoadingState, ErrorState, CallState, getError } from './call-state';

import * as fromListingSearch from '../actions/listing-search.action';
import * as fromFeatureListings from '../../../features/store/actions/listings.action';
import { SearchListingRequest } from 'src/library/features/services';
import { query } from '@angular/animations';

export interface ListingSearchState {
	listingPage: number;
	listingOrder: string[];
	viewState: CallState;
	URI: string;
	query: SearchListingRequest;
	total: number;
}

export const initialState: ListingSearchState = {
	listingPage: 1,
	listingOrder: [],
	viewState: LoadingState.INIT,
	URI: null,
	query: {
		location: {
			radius: 5
		},
		tags: null,
		age: null
	},
	total: null
};

export function reducer(
	state = initialState,
	action:
		| fromListingSearch.ListingSearchAction
		| fromFeatureListings.ListingsAction
): ListingSearchState {
	switch (action.type) {
		case fromListingSearch.LISTING_SEARCH_PAGE_RESET_LISTING_PAGE_SUCCESS: {
			const pageNumber = action.payload;
			return {
				...state,
				listingOrder: [],
				listingPage: pageNumber,
				URI: null
			};
		}

		case fromListingSearch.LISTING_SEARCH_PAGE_INCREMENT_LISTING_PAGE_SUCCESS: {
			const URI = action.payload.URI;
			const pageNumber = action.payload.page;
			return {
				...state,
				listingPage: pageNumber,
				URI
			};
		}

		case fromFeatureListings.CLUB_API_POST_SEARCH_LISTINGS: {
			return {
				...state,
				viewState: LoadingState.LOADING
			};
		}

		case fromFeatureListings.CLUB_API_POST_SEARCH_LISTINGS_SUCCESS: {
			const newListings = action.payload.result;
			const oldlistings = state.listingOrder;
			const listingArray = [];
			listingArray.push(...oldlistings, ...newListings);
			// remove duplicates
			const listingOrder = Array.from(new Set(listingArray));

			return {
				...state,
				listingOrder,
				viewState: LoadingState.LOADED
			};
		}

		case fromFeatureListings.CLUB_API_POST_SEARCH_LISTINGS_FAIL: {
			return {
				...state,
				viewState: { errorMessage: action.errorMessage }
			};
		}

		case fromListingSearch.LISTING_SEARCH_PAGE_GET_TOTAL_LISTINGS: {
			const total = action.payload;
			return {
				...state,
				total
			};
		}

		case fromListingSearch.LISTING_SEARCH_PAGE_SEARCH_PARAMS_CHANGED: {
			const query = action.payload.req;
			return {
				...state,
				query
			};
		}

		case fromListingSearch.LISTING_SEARCH_PAGE_UPDATE_SEARCH_PARAMS: {
			const params = action.payload;
			const query = {
				...state.query,
				...params
			};
			return {
				...state,
				query
			};
		}

		case fromListingSearch.LISTING_SEARCH_PAGE_CLEAR_SEARCH_PARAMS: {
			return {
				...state,
				URI: null,
				query: initialState.query
			};
		}

		default:
			return state;
	}
}

export const getListingSearchLoading 		= (state: ListingSearchState) => state.viewState === LoadingState.LOADING;
export const getListingSearchLoaded 		= (state: ListingSearchState) => state.viewState === LoadingState.LOADED;
export const getListingSearchError 			= (state: ListingSearchState) => getError(state.viewState);
export const getListingSearchIsReset		= (state: ListingSearchState) => state.query == initialState.query;
export const getListingSearchListingPage 	= (state: ListingSearchState) => state.listingPage;
export const getListingSearchListingOrder 	= (state: ListingSearchState) => state.listingOrder;
export const getListingSearchListingTotal 	= (state: ListingSearchState) => state.total;
