import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { ClubAPIAuthResponse } from './api-response.service';
import {
	LogService,
	ErrorService,
	CustomHttpService
} from 'src/app/shared/services';

export interface UserEmailValidationContext {
	username: string;
	uuid?: string;
	hash?: string;
	signature?: string;
	expires?: string;
}

interface APIRequestModel {
	username?: string;
	uuid?: string;
	signature?: string;
	expires?: string;
	hash?: string;
}

@Injectable({
	providedIn: 'root'
})
export class UserVerificationService {
	// API environment variables
	private ENV = `${environment.apiUrl}/${environment.apiVersion}`;
	// API Request information
	private API_END_POINT = 'user/verify'; // * Modify to change API path

	private URL = `${this.ENV}/${this.API_END_POINT}`;
	private SERVICE_NAME = `API (${this.API_END_POINT})`;

	constructor(
		private http: CustomHttpService,
		private logService: LogService,
		private errorService: ErrorService
	) {}

	private log(message: string) {
		this.logService.add(`${this.SERVICE_NAME}: ${message}`, 'success');
	}

	/**
	 * User email verification
	 */
	verifyUser(context: UserEmailValidationContext): Observable<any> {
		const url = `${this.URL}?expires=${context.expires}&hash=${context.hash}&signature=${context.signature}&uuid=${context.uuid}`;
		const req = this.requestConverter(context);
		console.log(url, req);
		return this.http.post<HttpResponse<ClubAPIAuthResponse>>(url, req).pipe(
			tap((_) => this.log(`User Verified Successfully.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'verifyUser'
				)
			)
		);
	}

	/**
	 * Convert Context to API request
	 */
	private requestConverter(
		context: UserEmailValidationContext
	): APIRequestModel {
		const req: APIRequestModel = {
			username: context.username
		};
		return req;
	}
}
