import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LanguageTypeValue } from 'src/library/features/models';

enum CookieNames {
	USER_HAS_ALREADY_VISITED_SITE = 'isAlreadyVisited',
	USER_HAS_LOGGED_IN = 'isLoggedIn',
	GLOBAL_LANGUAGE = 'globalLanguage'
}

@Injectable({
	providedIn: 'root'
})
export class CookieWrapperService {
	constructor(private cookieService: CookieService) {}


	public getGlobalLanguage(): LanguageTypeValue {
		return this.get(CookieNames.GLOBAL_LANGUAGE) as LanguageTypeValue;
	}
	public setGlobalLanguage(value: LanguageTypeValue): void {
		this.set(CookieNames.GLOBAL_LANGUAGE, value, 365, '/');
	}

	public getIsLoggedIn(): boolean {
		return this.check(CookieNames.USER_HAS_LOGGED_IN);
	}
	public setIsLoggedIn(value: boolean): void {
		this.set(CookieNames.USER_HAS_LOGGED_IN, value ? '1' : '0', 365, '/');
	}
	public removeIsLoggedIn(): void {
		this.delete(CookieNames.USER_HAS_LOGGED_IN);
	}

	

	/**
	 * Return `true` if {@link Document} is accessible, otherwise return `false`
	 *
	 * @param name Cookie name
	 * @returns boolean - whether cookie with specified name exists
	 */
	private check(name: CookieNames): boolean {
		return this.cookieService.check(name as string);
	}
	/**
	 * Get cookies by name
	 *
	 * @param name Cookie name
	 * @returns property value
	 */
	private get(name: CookieNames): string {
		return this.cookieService.get(name as string);
	}
	private getAll() {
		return this.cookieService.getAll();
	}
	/**
	 * Set cookie based on provided information
	 *
	 * @param name     Cookie name
	 * @param value    Cookie value
	 * @param expires  Number of days until the cookies expires or an actual `Date`
	 * @param path     Cookie path
	 * @param domain   Cookie domain
	 * @param secure   Secure flag
	 * @param sameSite OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `Lax`
	 */
	private set(
		name: CookieNames,
		value: string,
		expires?: number | Date,
		path?: string,
		domain?: string,
		secure?: boolean,
		sameSite?: 'Lax' | 'None' | 'Strict'
	): void {
		return this.cookieService.set(
			name as string,
			value,
			expires,
			path,
			domain,
			secure,
			sameSite
		);
	}
	/**
	 * Delete cookie by name
	 *
	 * @param name   Cookie name
	 * @param path   Cookie path
	 * @param domain Cookie domain
	 * @param secure Cookie secure flag
	 * @param sameSite Cookie sameSite flag - https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
	 */
	private delete(
		name: CookieNames,
		path?: string,
		domain?: string,
		secure?: boolean,
		sameSite?: 'Lax' | 'None' | 'Strict'
	): void {
		return this.cookieService.delete(
			name as string,
			path,
			domain,
			secure,
			sameSite
		);
	}
	/**
	 * Delete all cookies
	 *
	 * @param path   Cookie path
	 * @param domain Cookie domain
	 * @param secure Is the Cookie secure
	 * @param sameSite Is the cookie same site
	 */
	private deleteAll(
		path?: string,
		domain?: string,
		secure?: boolean,
		sameSite?: 'Lax' | 'None' | 'Strict'
	): void {
		return this.cookieService.deleteAll(
			path as string,
			domain,
			secure,
			sameSite
		);
	}
}
