import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromMedia from '../reducers/media.reducer';

import { Media } from '../../models';


export const getMediaState = createSelector(
    fromFeature.getFeaturesState,
    (state: fromFeature.FeaturesState) => state.media
);

export const getMediaEntities = createSelector(
    getMediaState,
    fromMedia.getMediaEntities
);

export const getMediaList = createSelector(
    getMediaEntities,
    entities => Object.keys(entities).map(key => entities[key])
);

export const getMediaByUUID = (UUID: string) => createSelector(
    getMediaList,
    (media): Media => media[UUID]
);
