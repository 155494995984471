import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CustomHttpService {
	constructor(private http: HttpClient) {}

	get<T>(
		url: string,
		params?: HttpParams | { [param: string]: string | string[]}
	): Observable<HttpResponse<T>> {
		return this.http.get<T>(url, {
			params,
			observe: 'response'
		});
	}

	post<T>(
		url: string,
		body: any,
		params?: HttpParams | { [param: string]: string | string[]}
	): Observable<HttpResponse<T>> {
		return this.http.post<T>(url, body, {
			...params,
			observe: 'response'
		});
	}

  put<T>(
		url: string,
		body: any,
		params?: HttpParams | { [param: string]: string | string[]}
	): Observable<HttpResponse<T>> {
		return this.http.put<T>(url, body, {
			...params,
			observe: 'response'
		});
	}

	delete<T>(
		url: string,
		params?: HttpParams | { [param: string]: string | string[]}
	): Observable<HttpResponse<T>> {
		return this.http.delete<T>(url, {
			...params,
			observe: 'response'
		});
	}
}
