import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import {
	CustomHttpService,
	ErrorService,
	LogService
} from 'src/app/shared/services';
import { ClubAPIResponseService } from './api-response.service';
import { environment } from 'src/environments/environment';
import { Suburb } from '../models';

@Injectable({
	providedIn: 'root'
})
export class SuburbsService {
	// API environment variables
	private ENV = `${environment.apiUrl}/${environment.apiVersion}`;
	// API Request information
	private API_END_POINT = 'suburbs'; // * Modify to change API path

	private URL = `${this.ENV}/${this.API_END_POINT}`;
	private SERVICE_NAME = `API (${this.API_END_POINT})`;

	constructor(
		private http: CustomHttpService,
		private logService: LogService,
		private errorService: ErrorService
	) {}

	private log(message: string) {
		this.logService.add(`${this.SERVICE_NAME}: ${message}`, 'success');
	}

	/**
	 * Get Suburbs
	 * @param term - suburb search term
	 * @returns Observable<Suburb[]>
	 */
	searchSuburbs(term: string): Observable<Suburb[]> {
		const url = `${this.URL}`;
		const params = new HttpParams().set('search', term);
		return this.http.get(url, params).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<Suburb[]>(
					res,
					'Suburb',
					true
				)
			),
			tap((_) => this.log(`searchSuburb ${term} success.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'searchSuburbs'
				)
			)
		);
	}
}
