import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { LogService } from './log.service';

import { TranslocoService } from '@ngneat/transloco';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
export interface Error {
	code: string;
	message: string;
	ok: boolean; // Whether to let the app continue or block further actions.
}
@Injectable({
	providedIn: 'root'
})
export class ErrorService implements OnDestroy {
	subscription$: Subscription | null = null;

	public static errorCodes: { [key: string]: Error } = null;
	public static errorMessages: { [key: string]: string } = null;

	constructor(
		private logService: LogService,
		private translocoService: TranslocoService
	) {
		// Fetch the error translations
		this.subscription$ = this.translocoService
			.selectTranslateObject('error')
			.pipe(
				tap((errorObj) => {
					// Once the errors are fetched, to load the error objects
					this.setErrorCodes();
					this.setTranslationErrors(errorObj);
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.subscription$ && this.subscription$.unsubscribe();
	}

	private log(title: string, message: string) {
		this.logService.add(message, 'error', title);
	}

	/**
	 *  Handles Error Event
	 * @param err HttpErrorResponse or custom error
	 * @param service service or Service which generated the error
	 * @param action the function or action which generated the error
	 * @returns
	 */
	handleAPIHttpError(
		err: HttpErrorResponse,
		service: string,
		action: string
	): Observable<Error> {
		console.log(err);
		const error = this.setError(err, service, action);
		this.log(
			`${service}: ${err.status} on ${err.url}`,
			`Error ${error.code} - ${error.message}`
		);
		if (environment.production === false) {
			console.warn(
				`Error Message: ${err.error.message}`,
				'\n',
				err.error.errors
			);
		}
		return throwError(error);
	}

	private setErrorCodes(): void {
		ErrorService.errorCodes = {
			A10999: {
				code: 'A10999',
				message: 'UNPROCESSABLE_ENTITY_PROCEED_OK',
				ok: true
			},
			A10500: {
				code: 'A10500',
				message: 'REPORT_ERROR',
				ok: false
			},
			A10445: {
				code: 'A10245',
				message: 'USERNAME_EXISTS',
				ok: false
			},
			A10422: {
				code: 'A10222',
				message: 'USERNAME_FORMAT_INCORRECT',
				ok: false
			},
			A10400: {
				code: 'A10400',
				message: 'USERNAME_VALIDATION_ERROR',
				ok: false
			},
			A10345: {
				code: 'A10345',
				message: 'LOGIN_DETAILS_INCORRECT',
				ok: false
			},
			A10300: {
				code: 'A10300',
				message: 'LOGIN_ERROR',
				ok: false
			},
			A10245: {
				code: 'A10245',
				message: 'REGISTER_EMAIL_EXISTS',
				ok: false
			},
			A10222: {
				code: 'A10222',
				message: 'REGISTER_DETAILS_INCORRECT',
				ok: false
			},
			A10200: {
				code: 'A10200',
				message: 'REGISTER_ERROR',
				ok: false
			},
			A10122: {
				code: 'A10122',
				message: 'GENERIC_UNPROCESSABLE_ENTITY',
				ok: false
			},
			A10104: {
				code: 'A10104',
				message: 'GENERIC_NOT_FOUND',
				ok: false
			},
			A10100: {
				code: 'A10100',
				message: 'GENERIC_ERROR',
				ok: false
			},
			A10000: {
				code: 'A10000',
				message: 'GENERIC_ERROR',
				ok: false
			},
			B10122: {
				code: 'B10122',
				message: 'GENERIC_UNPROCESSABLE_ENTITY',
				ok: false
			},
			B10101: {
				code: 'B10101',
				message: 'GENERIC_UNAUTHORISED',
				ok: false
			},
			B10100: {
				code: 'B10100',
				message: 'GENERIC_ERROR',
				ok: false
			},
			C10400: {
				code: 'C10400',
				message: 'ESCORT_VERIFICATION_ERROR',
				ok: false
			},
			C10300: {
				code: 'C10200',
				message: 'ESCORT_UPDATE_ERROR',
				ok: false
			},
			C10200: {
				code: 'C10200',
				message: 'ESCORT_CREATE_ERROR',
				ok: false
			},
			C10145: {
				code: 'C10145',
				message: 'ESCORT_HANDLE_EXISTS',
				ok: false
			},
			C10122: {
				code: 'C10122',
				message: 'ESCORT_HANDLE_FORMAT_INCORRECT',
				ok: false
			},
			C10100: {
				code: 'C10100',
				message: 'ESCORT_HANDLE_VALIDATION_ERROR',
				ok: false
			},
			D10105: {
				code: 'D10105',
				message: 'INSUFFICIENT_FUNDS',
				ok: false
			},
			D10104: {
				code: 'D10104',
				message: 'GENERIC_ERROR',
				ok: false
			},
			D10103: {
				code: 'D10103',
				message: 'GENERIC_UNAUTHORISED',
				ok: false
			},
			D10102: {
				code: 'D10102',
				message: 'LISTING_UPDATE_ERROR',
				ok: false
			},
			D10101: {
				code: 'D10101',
				message: 'LISTING_CREATE_ERROR',
				ok: false
			},
			D10100: {
				code: 'D10100',
				message: 'GENERIC_ERROR',
				ok: false
			},
			E10101: {
				code: 'E10101',
				message: 'GENERIC_UNAUTHORISED',
				ok: false
			},
			E10100: {
				code: 'E10100',
				message: 'GENERIC_ERROR',
				ok: false
			}
		};
	}

	private setTranslationErrors(translations: any): void {
		ErrorService.errorMessages = {
			REPORT_ERROR: translations?.reportError,
			LISTING_CREATE_ERROR: translations?.listingCreateError,
			INSUFFICIENT_FUNDS: translations?.insufficientFunds,
			LISTING_UPDATE_ERROR: translations?.listingUpdateError,
			ESCORT_VERIFICATION_ERROR: translations?.escortVerificationError,
			ESCORT_UPDATE_ERROR: translations?.escortUpdateError,
			ESCORT_CREATE_ERROR: translations?.escortCreateError,
			ESCORT_HANDLE_EXISTS: translations?.escortHandleExists,
			ESCORT_HANDLE_FORMAT_INCORRECT:
				translations?.escortHandleFormatIncorrect,
			ESCORT_HANDLE_VALIDATION_ERROR:
				translations?.escortHandleValidationError,
			UNPROCESSABLE_ENTITY_PROCEED_OK:
				translations?.unprocessableEntityProceedOk,
			LOGIN_DETAILS_INCORRECT: translations?.loginDetailsIncorrect,
			LOGIN_ERROR: translations?.loginError,
			REGISTER_EMAIL_EXISTS: translations?.registerEmailExists,
			REGISTER_DETAILS_INCORRECT: translations?.registerDetailsIncorrect,
			REGISTER_ERROR: translations?.registerError,
			USERNAME_EXISTS: translations?.usernameExist,
			USERNAME_FORMAT_INCORRECT: translations?.usernameFormatIncorrect,
			USERNAME_VALIDATION_ERROR: translations?.usernameValidationError,
			GENERIC_ERROR: translations?.genericError,
			GENERIC_UNPROCESSABLE_ENTITY:
				translations?.genericUnprocessableEntity,
			GENERIC_NOT_FOUND: translations?.genericNotFound,
			GENERIC_UNAUTHORISED: translations?.genericUnauthorised
		};
	}

	private setError(
		err: HttpErrorResponse,
		service: string,
		action: string
	): Error {
		switch (service) {
			case 'API AUTH': {
				switch (action) {
					// /login
					case 'login': {
						switch (err.status) {
							case 422: {
								return this.getError('A10345');
							}
							case 404: {
								return this.getError('A10104');
							}
							case 400:
							default: {
								return this.getError('A10300');
							}
						}
					}
					// /logout
					case 'logout': {
						switch (err.status) {
							case 400:
							default: {
								return this.getError('A10100');
							}
						}
						break;
					}
				}
				break;
			}

			case 'API (register)': {
				switch (action) {
					// /register
					case 'register': {
						switch (err.status) {
							case 422: {
								// If there is a problem with email
								if (
									err.error &&
									err.error.errors &&
									err.error.errors.email &&
									err.error.errors.email.length !== 0
								) {
									return this.getError('A10245');
								} else {
									return this.getError('A10222');
								}
							}
							case 404: {
								return this.getError('A10104');
							}
							case 400:
							default: {
								return this.getError('A10200');
							}
						}
					}
				}
				break;
			}

			case 'API (forgot-password)': {
				switch (action) {
					// /forgot-password
					case 'requestReset': {
						switch (err.status) {
							case 422: {
								return this.getError('A10999');
							}
							case 404: {
								return this.getError('A10104');
							}
							case 400:
							default: {
								return this.getError('A10100');
							}
						}
					}
				}
				break;
			}

			case 'API (reset-password)': {
				switch (action) {
					// /reset-password
					case 'resetPassword': {
						switch (err.status) {
							case 422: {
								return this.getError('A10122');
							}
							case 404: {
								return this.getError('A10104');
							}
							case 400:
							default: {
								return this.getError('A10100');
							}
						}
					}
				}
				break;
			}

			case 'API (email/verification-notification)': {
				switch (action) {
					// /email/verification-notification
					case 'requestVerification': {
						switch (err.status) {
							case 404: {
								return this.getError('A10104');
							}
							case 400:
							default: {
								return this.getError('A10100');
							}
						}
					}
				}
				break;
			}

			case 'API (user/verification/username)': {
				switch (action) {
					// /user/verification/username
					case 'checkValidUsername': {
						switch (err.status) {
							case 422: {
								// If there is a problem with username
								if (
									err.error &&
									err.error.errors &&
									err.error.errors.username &&
									err.error.errors.username.length !== 0
								) {
									return this.getError('A10445');
								} else {
									return this.getError('A10422');
								}
							}
							case 404: {
								return this.getError('A10104');
							}
							case 400:
							default: {
								return this.getError('A10100');
							}
						}
					}
				}
				break;
			}

			case 'API (escort/verification/handle)': {
				switch (action) {
					// /escort/verification/handle
					case 'checkValidEscortHandle': {
						switch (err.status) {
							case 422: {
								// If there is a problem with handle
								if (
									err.error &&
									err.error.errors &&
									err.error.errors.handle &&
									err.error.errors.handle.length !== 0
								) {
									return this.getError('C10145');
								} else {
									return this.getError('C10122');
								}
							}
							case 404: {
								return this.getError('A10104');
							}
							case 400:
							default: {
								return this.getError('C10100');
							}
						}
					}
				}
				break;
			}

			case 'API (user/verify)': {
				switch (action) {
					// /user/verify
					case 'verifyUser': {
						switch (err.status) {
							case 422: {
								// If there is a problem with username
								if (
									err.error &&
									err.error.errors &&
									err.error.errors.username &&
									err.error.errors.username.length !== 0
								) {
									return this.getError('A10445');
								} else {
									return this.getError('A10422');
								}
							}
							case 404: {
								return this.getError('A10104');
							}
							case 400:
							default: {
								return this.getError('A10400');
							}
						}
					}
				}
				break;
			}

			case 'API Auth (user)': {
				switch (action) {
					// GET /user
					case 'getUser': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
					// PUT /user
					case 'updateUser': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
				}
				break;
			}

			case 'API Auth (user/notifications)': {
				switch (action) {
					// GET /user/notifications
					case 'getNotifications': {
						switch (err.status) {
							case 403: {
								return this.getError('E10101');
							}
							case 400:
							default: {
								return this.getError('E10100');
							}
						}
						break;
					}

					// GET /user/notification/{UUID}
					case 'getNotification': {
						switch (err.status) {
							case 403: {
								return this.getError('E10101');
							}
							case 400:
							default: {
								return this.getError('E10100');
							}
						}
						break;
					}

					// PUT /user/notification/acknowledge
					case 'acknowledgedNotifications': {
						switch (err.status) {
							case 403: {
								return this.getError('E10101');
							}
							case 400:
							default: {
								return this.getError('E10100');
							}
						}
						break;
					}

					// PUT /user/notification/read
					case 'readNotifications': {
						switch (err.status) {
							case 403: {
								return this.getError('E10101');
							}
							case 400:
							default: {
								return this.getError('E10100');
							}
						}
						break;
					}

					// PUT /user/notification/{UUID}/read
					case 'readNotification': {
						switch (err.status) {
							case 403: {
								return this.getError('E10101');
							}
							case 400:
							default: {
								return this.getError('E10100');
							}
						}
						break;
					}

					// DELETE /user/notification/{UUID}
					case 'deleteNotification': {
						switch (err.status) {
							case 403: {
								return this.getError('E10101');
							}
							case 400:
							default: {
								return this.getError('E10100');
							}
						}
						break;
					}

					// GET /user/notifications/counts
					case 'getNotificationsCounts': {
						switch (err.status) {
							case 403: {
								return this.getError('E10101');
							}
							case 400:
							default: {
								return this.getError('E10100');
							}
						}
						break;
					}
				}
			}

			case 'API Auth (user/escort)': {
				switch (action) {
					// POST /user/escort
					case 'createEscort': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('C10200');
							}
						}
						break;
					}

					// PUT /user/escort
					case 'updateEscort': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('C10300');
							}
						}
						break;
					}

					// /user/escort/verification
					case 'verifyEscort': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('C10400');
							}
						}
						break;
					}
				}
				break;
			}

			case 'API Auth (user/listings)': {
				switch (action) {
					// GET /user/listings
					case 'getListings': {
						switch (err.status) {
							case 403: {
								return this.getError('D10103');
							}
							case 400:
							default: {
								return this.getError('D10100');
							}
						}
						break;
					}

					// GET /user/listing/UUID
					case 'getListing': {
						switch (err.status) {
							case 403: {
								return this.getError('D10103');
							}
							case 400:
							default: {
								return this.getError('D10100');
							}
						}
						break;
					}

					// POST /user/listing/UUID/graph
					case 'getListingStats': {
						switch (err.status) {
							case 403: {
								return this.getError('D10103');
							}
							case 400:
							default: {
								return this.getError('D10100');
							}
						}
						break;
					}

					// POST /user/listings
					case 'createListing': {
						switch (err.status) {
							case 422: {
								if (err.error.errors.wallet[0] === 'Wallet has insufficient funds.') {
									return this.getError('D10105');
								}
								return this.getError('D10103');
							}
							case 403: {
								return this.getError('D10103');
							}
							case 400:
							default: {
								return this.getError('D10101');
							}
						}
						break;
					}

					// PUT /user/listings
					case 'updateListing': {
						switch (err.status) {
							case 403: {
								return this.getError('D10103');
							}
							case 400:
							default: {
								return this.getError('D10102');
							}
						}
						break;
					}

					// DELETE /user/listings
					case 'deleteListing': {
						switch (err.status) {
							case 403: {
								return this.getError('D10103');
							}
							case 400:
							default: {
								return this.getError('D10104');
							}
						}
						break;
					}
				}
				break;
			}

			case 'API (listings)': {
				switch (action) {
					// /getLatestListings
					case 'getLatestListings': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}

					// /getListing
					case 'getListing': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}

					// /listing/search
					case 'postSearchListings': {
						switch (err.status) {
							case 422: {
								return this.getError('B10102');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
				}
				break;
			}

			case 'API (escorts)': {
				switch (action) {
					// /getEscort
					case 'getEscort': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
				}
				break;
			}

			case 'API (tags)': {
				switch (action) {
					// /getEthnicities
					case 'getEthnicities': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
					// /getHairColours
					case 'getHairColours': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
					// /getEyeColours
					case 'getEyeColours': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
					// /getGenders
					case 'getGenders': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
					// /getSexualPreferences
					case 'getSexualPreferences': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
					// /getBodyTypes
					case 'getBodyTypes': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
					// /getSpokenLanguages
					case 'getSpokenLanguages': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
					// /getPlays
					case 'getPlays': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
					// /getMasturbationPlays
					case 'getMasturbationPlays': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
					// /getOralPlays
					case 'getOralPlays': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
					// /getEjaculationPlays
					case 'getEjaculationPlays': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
					// /getServicePlays
					case 'getServicePlays': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
					// /getExperiencePlays
					case 'getExperiencePlays': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
					// /getOtherPlays
					case 'getOtherPlays': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
				}
				break;
			}

			case 'API (suburbs)': {
				switch (action) {
					// /searchSuburbs
					case 'searchSuburbs': {
						switch (err.status) {
							case 401: {
								return this.getError('B10101');
							}
							case 400:
							default: {
								return this.getError('B10100');
							}
						}
						break;
					}
				}
				break;
			}

			case 'API Auth (report)': {
				switch (action) {
					// /postReport
					case 'postReport': {
						switch (err.status) {
							case 400:
							default: {
								return this.getError('A10500');
							}
						}
						break;
					}
				}
				break;
			}

			default: {
				return this.getError('A00000');
			}
		}
	}

	/**
	 *  Get Error Message from Error key
	 * @param key Error Code key
	 * @returns Error message
	 */
	public static getErrorMessage(key: string): string {
		let errorMessage: string;
		for (const errorMessageKey in ErrorService.errorMessages) {
			if (errorMessageKey === key) {
				errorMessage = ErrorService.errorMessages[key];
			}
		}
		return errorMessage;
	}

	/**
	 *  Get Error Object from Error Code
	 * @param key Error Code key
	 * @returns Error Object
	 */
	getError(key: string): Error {
		let error: Error;
		for (const errorCodeKey in ErrorService.errorCodes) {
			if (errorCodeKey === key) {
				error = ErrorService.errorCodes[key];
			}
		}
		return error;
	}
}
