import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
	ClubEscort,
	ClubEscortTagsMember,
	ClubEscortTagType,
	ClubListing,
	ClubListingPrice,
	ClubListingStat,
	ClubMedia,
	ClubNotification,
	ClubReport,
	ClubSuburb,
	ClubUser,
	ClubWallet,
	Escort,
	EscortGallery,
	EscortTagsMember,
	EscortTagType,
	Listing,
	ListingPrice,
	ListingStat,
	Media,
	Notification,
	Report,
	Suburb,
	User,
	Wallet
} from '../models';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { parseISO } from 'date-fns';

export interface ClubAPIResponse<T> extends ClubAPIPaginateResponse {
	data?: T;
}
export interface ClubAPIPaginateResponse {
	links?: {
		first: string;
		last: string;
		prev: string;
		next: string;
	};
	meta?: {
		current_page: number;
		from: number;
		path: string;
		per_page: number;
		to: number;
		total: number;
	};
}

export interface PaginatedResponse<T> {
	data: T;
	meta: {
		first: string;
		last: string;
		prev: string;
		next: string;
		current_page: number;
		total?: number;
	};
}

@Injectable({
	providedIn: 'root'
})
export class ClubAPIResponseService {
	/**
	 * Get API response and return as interface type, return <T> as type to be converted into
	 *
	 * @param response Http Response
	 * @param isArray Whether the response is an Array
	 * @param type Type to be converted into as string
	 */
	static validateResponse<T>(
		response: HttpResponse<ClubAPIResponse<T>>,
		responseType: string,
		isArray: boolean,
		isPaginated?: boolean
	): T | T[] | PaginatedResponse<T> | any {
		// Check if any response is returned
		if (response) {
			// If other response codes

			// Otherwise if response is successful, to map response body to DTO
			if (
				response.status === 200 ||
				response.status === 201 ||
				response.status === 202
			) {
				// Check if response is an array
				if (isArray) {
					const res = response.body.data;
					if (!isPaginated) {
						return this.APItoModel<T[]>(res, responseType, isArray);
					} else {
						const meta = {
							first: response.body.links.first,
							last: response.body.links.last,
							prev: response.body.links.prev,
							next: response.body.links.next,
							current_page: response.body.meta.current_page,
							total: response.body.meta.total
						};
						const data = this.APItoModel<T>(
							res,
							responseType,
							isArray
						);

						return { data, meta } as PaginatedResponse<T>;
					}
				} else {
					const res = response.body.data;
					return this.APItoModel<T>(res, responseType, isArray);
				}
			}
		} else {
			throw this.newError('N/A', 400, 'No Response Error');
		}
	}

	static ValidateSocketResponse<T>(
		response: ClubAPIResponse<T>,
		responseType: string,
		isArray: boolean
	): T | T[] | any {
		// Check if any response is returned
		if (response) {
			// Check if response is an array
			if (isArray) {
				const res = response;
				return this.APItoModel<T[]>(res, responseType, isArray);
			} else {
				const res = response;
				return this.APItoModel<T>(res, responseType, isArray);
			}
		} else {
			throw this.newError('N/A', 400, 'No Response Error');
		}
	}

	private static newError(url: string, status = 400, statusText: string) {
		const errorResponse = new HttpErrorResponse({
			url,
			status,
			statusText
		});
		return errorResponse;
	}

	static APItoModel<T>(
		response: any,
		responseType: string,
		isArray?: boolean
	) {
		// console.log(response, responseType);
		if (isArray) {
			const arr = response.map((item) => {
				return this.APItoModelConverter(item, responseType);
			});
			return arr as T[];
		} else {
			const instance = this.APItoModelConverter(response, responseType);
			return instance as unknown as T;
		}
	}

	static ModeltoAPI<T>(
		response: any,
		responseType: string,
		isArray?: boolean
	) {
		// console.log(response, responseType);
		if (isArray) {
			const arr = response.map((item) => {
				return this.ModelToAPIConverter(item, responseType);
			});
			return arr as T[];
		} else {
			const instance = this.ModelToAPIConverter(response, responseType);
			return instance as unknown as T;
		}
	}

	static APItoModelConverter(res: any, responseType: string) {
		// Used to get the ISO Date of string, or returns null undefined
		function getISODate(date: string): Date {
			return date ? parseISO(date) : null;
		}
		// Used to get a boolean flag of a value;
		function getIsTrue(value: any): boolean {
			return !!value;
		}
		// Used to get the wallet values
		function getWallet(wallets: ClubWallet[]): Wallet {
			if (wallets && wallets.length !== 0) {
				const diamond = wallets.filter(
					(item) => item.slug === 'diamond-wallet'
				);
				const shard = wallets.filter(
					(item) => item.slug === 'shard-wallet'
				);
				return {
					diamond: parseInt(diamond[0].balance),
					shard: parseInt(shard[0].balance)
				};
			} else {
				return undefined;
			}
		}

		switch (responseType) {
			case 'User': {
				const APIresponse: ClubUser = res;
				// Check for user avatar exists, if not to set default avatar
				let userAvatar: Media;
				if (!!APIresponse.media && APIresponse.media.length !== 0) {
					const mediaArray = this.APItoModel<Media>(
						APIresponse.media,
						'Media',
						true
					) as Media[];
					for (let i = 0; i < mediaArray.length; i++) {
						if (mediaArray[i].collectionName === 'profile') {
							userAvatar = mediaArray[i];
						}
					}
				} else {
					const defaultAvatar: Media = {
						UUID: 'ffffffff-eeee-cccc-dddd-aaaaaaaaaaaa',
						collectionName: 'avatar',
						url: environment.defaultAvatarURL
					};
					userAvatar = defaultAvatar;
				}

				const model: User = {
					UUID: APIresponse.uuid,
					username: APIresponse.username,
					mobile: APIresponse.mobile,
					email: APIresponse.email,
					emailVerifiedAt: getISODate(APIresponse.email_verified_at),
					isEmailVerified: getIsTrue(APIresponse.email_verified_at),
					mobileVerifiedAt: getISODate(
						APIresponse.mobile_verified_at
					),
					isMobileVerified: getIsTrue(APIresponse.mobile_verified_at),
					dateOfBirth: getISODate(APIresponse.dob),
					addressStreet: APIresponse.street,
					addressSuburb: APIresponse.suburb,
					addressPostcode: APIresponse.postcode,
					addressState: APIresponse.state,
					isActive: APIresponse.is_active,
					// lastActive: APIresponse.last_active,
					createdAt: getISODate(APIresponse.created_at),
					// updatedAt: APIresponse.updated_at,
					avatar: userAvatar,
					escort: (APIresponse.escort
						? this.APItoModel<Escort>(APIresponse.escort, 'Escort')
						: undefined) as Escort,
					listings: (APIresponse.listings
						? this.APItoModel<Listing>(
								APIresponse.listings,
								'Listing',
								true
						  )
						: undefined) as Listing[],
					wallet: getWallet(APIresponse.wallets)
				};
				return this.removeUndefined(model) as User;
			}

			case 'Media': {
				const APIresponse: ClubMedia = res;
				const model: Media = {
					UUID: APIresponse.uuid,
					modelType: APIresponse.model_type,
					collectionName: APIresponse.collection_name,
					url: APIresponse.url
				};
				return model;
			}

			case 'Suburb': {
				const APIresponse: ClubSuburb = res;
				const model: Suburb = {
					ID: APIresponse.id,
					postcode: APIresponse.postcode,
					name: APIresponse.name,
					state: APIresponse.state,
					latitude: APIresponse.latitude,
					longitude: APIresponse.longitude
				};
				return model;
			}

			case 'Listing': {
				const APIresponse: ClubListing = res;

				// Get Listing Media
				function getListingMedia(
					media: ClubListing['media']
				): Listing['media'] {
					if (media && media.length !== 0) {
						const mediaArray = media.map((item) =>
							ClubAPIResponseService.APItoModelConverter(
								item,
								'Media'
							)
						) as Media[];
						// Get the ones with the collectionName being images
						return mediaArray.filter(
							(item) => item.collectionName === 'images'
						);
					} else {
						// If listing image doesn't exist for whatever reason, set a default image so it doesn't error
						const defaultListingMedia: Media[] = [
							{
								UUID: 'ffffffff-eeee-cccc-dddd-aaaaaaaaaaaa',
								collectionName: 'avatar',
								url: environment.defaultAvatarURL
							}
						];
						return defaultListingMedia;
					}
				}

				// Get Listing Type
				// ! Defaults to personal
				function getListingType(
					type: ClubListing['type']
				): Listing['type'] {
					return 'personals';
				}

				// Calculate Listing Health. Returns as a percentage
				// ! Set to two weeks
				function getListingHealth(weight: number): Listing['health'] {
					const currentTime = Date.now() / 1000;
					const minHealthDuration = 604800 * 2; // 1 week = 60 * 60 * 24 * 7 = 604800
					const minHealthWeight =
						(currentTime -
							minHealthDuration -
							environment.unixBaseTime) /
						45000;
					const maxHealthWeight =
						(currentTime - environment.unixBaseTime) / 45000;
					const healthPercentage =
						(weight - minHealthWeight) /
						(maxHealthWeight - minHealthWeight);
					return healthPercentage > 0.01
						? healthPercentage > 1
							? 1
							: healthPercentage
						: 0.01;
				}

				// Get Listing Payment Type
				function getListingPaymentType(
					type: ClubListing['payment']
				): Listing['paymentMethod'] {
					switch (type) {
						case 'shard': {
							return 'shard';
						}
						case 'diamond': {
							return 'diamond';
						}
					}
				}

				const model: Listing = {
					UUID: APIresponse.uuid,
					type: getListingType(APIresponse.type),
					title: APIresponse.title,
					description: APIresponse.description,
					location: APIresponse.location,
					latitude: Number(APIresponse.latitude),
					longitude: Number(APIresponse.longitude),
					weight: APIresponse.weight,
					views: APIresponse.view_count,
					isActive: APIresponse.is_active,
					verifiedAt: getISODate(APIresponse.verified_at),
					createdAt: getISODate(APIresponse.created_at),
					updatedAt: getISODate(APIresponse.updated_at),
					media: getListingMedia(APIresponse.media),
					user: (APIresponse.user
						? this.APItoModel<User>(APIresponse.user, 'User')
						: undefined) as User,
					promotions: APIresponse.promotions,
					health: getListingHealth(APIresponse.weight),
					prices: (APIresponse.pricings
						? this.APItoModel<ListingPrice>(
								APIresponse.pricings,
								'ListingPrice',
								true
						  )
						: undefined) as ListingPrice[],
					priceFrom: (APIresponse.lowest_pricing
						? this.APItoModel<ListingPrice>(
								APIresponse.lowest_pricing,
								'ListingPrice'
						  )
						: undefined) as ListingPrice,
					paymentMethod: getListingPaymentType(APIresponse.payment)
				};
				return this.removeUndefined(model) as Listing;
			}

			case 'ListingPrice': {
				const APIresponse: ClubListingPrice = res;

				// Get Listing Price Type
				function getListingPriceType(
					type: ClubListingPrice['type']
				): ListingPrice['type'] {
					switch (type) {
						case 'incall': {
							return 'incall';
						}
						case 'outcall': {
							return 'outcall';
						}
					}
				}

				// Get Listing Duration Type
				function getListingDurationType(
					duration: ClubListingPrice['duration']
				): ListingPrice['duration'] {
					switch (duration) {
						case '1Hour': {
							return 'oneHour';
						}
						case '2Hour': {
							return 'twoHours';
						}
						case '3Hour': {
							return 'threeHours';
						}
						case 'overnight': {
							return 'overnight';
						}
					}
				}

				const model: ListingPrice = {
					UUID: APIresponse.uuid,
					type: getListingPriceType(APIresponse.type),
					duration: getListingDurationType(APIresponse.duration),
					price: parseInt(APIresponse.price)
				};
				return model;
			}

			case 'Escort': {
				const APIresponse: ClubEscort = res;

				// Get Escort Galleries
				function getEscortGalleries(): Escort['galleries'] {
					let escortGalleries: EscortGallery[] = [
						{
							name: 'Public',
							media: [],
							isLocked: false
						}
					];
					return escortGalleries;
				}

				// Get Escort Avatar
				function getEscortAvatar(
					media: ClubEscort['media']
				): Escort['avatar'] {
					if (media && media.profile && media.profile.length !== 0) {
						const mediaArray = (media.profile as ClubMedia[]).map(
							(item) =>
								ClubAPIResponseService.APItoModelConverter(
									item,
									'Media'
								)
						) as Media[];
						// Return first value as avatar
						return mediaArray[0];
					} else {
						// If listing image doesn't exist for whatever reason, set a default image so it doesn't error
						const defaultListingMedia: Media = {
							UUID: '5c2a6918-eb09-3fd8-ae38-9527afa8bcea',
							collectionName: 'avatar',
							url: environment.defaultAvatarURL
						};
						return defaultListingMedia;
					}
				}

				// Get Escort Banner
				function getEscortBanner(
					media: ClubEscort['media']
				): Escort['banner'] {
					if (media && media.banner && media.banner.length !== 0) {
						const mediaArray = (media.banner as ClubMedia[]).map(
							(item) =>
								ClubAPIResponseService.APItoModelConverter(
									item,
									'Media'
								)
						) as Media[];
						// Return first value as avatar
						return mediaArray[0];
					} else {
						return undefined;
					}
				}

				// Get Escort Tags (array)
				function getEscortTags(
					tags: ClubEscort['tags'][ClubEscortTagsMember],
					type?: EscortTagsMember
				): EscortTagType[] {
					if (tags) {
						return (tags as ClubEscortTagType[]).map((obj) => {
							return {
								name: obj.name,
								slug: obj.slug,
								type: type ? type : null
							};
						});
					} else return undefined;
				}

				// Get Escort Tag (singular)
				function getEscortTag(
					tags: ClubEscort['tags'][ClubEscortTagsMember]
				): EscortTagType {
					if (tags) {
						const tag = tags[0];
						return {
							name: tag.name,
							slug: tag.slug
						};
					} else return undefined;
				}

				// Get Escort Play Tags
				function getEscortPlayTags(
					tags: ClubEscort['tags']
				): Escort['plays'] {
					const playTags: Escort['plays'] = [];
					playTags.push(
						...getEscortTags(
							tags.masturbation_plays,
							'masturbationPlays'
						)
					);
					playTags.push(
						...getEscortTags(tags.oral_plays, 'oralPlays')
					);
					playTags.push(
						...getEscortTags(tags.anal_plays, 'analPlays')
					);
					playTags.push(
						...getEscortTags(
							tags.ejaculation_plays,
							'ejaculationPlays'
						)
					);
					playTags.push(
						...getEscortTags(tags.service_plays, 'servicePlays')
					);
					playTags.push(
						...getEscortTags(
							tags.experience_plays,
							'experiencePlays'
						)
					);
					playTags.push(
						...getEscortTags(tags.other_plays, 'otherPlays')
					);
					return playTags.length !== 0 ? playTags : undefined;
				}

				const model: Escort = {
					UUID: APIresponse.uuid,
					profileName: APIresponse.profile_name,
					handle: APIresponse.handle,
					age: APIresponse.age,
					location: APIresponse.location,
					latitude: Number(APIresponse.latitude),
					longitude: Number(APIresponse.longitude),
					description: APIresponse.description,
					email: APIresponse.email,
					phone: APIresponse.phone,
					verifiedAt: getISODate(APIresponse.verified_at),
					verifiedStatus: APIresponse.verified_status,
					viewCount: APIresponse.view_count,
					galleries: getEscortGalleries(),
					banner: getEscortBanner(APIresponse.media),
					avatar: getEscortAvatar(APIresponse.media),
					availabilities: APIresponse.availabilities,
					ethnicities: getEscortTags(APIresponse.tags.ethnicities),
					hairColour: getEscortTag(APIresponse.tags.hair_colours),
					eyeColour: getEscortTag(APIresponse.tags.eye_colours),
					gender: getEscortTag(APIresponse.tags.sexes),
					sexualPreference: getEscortTags(
						APIresponse.tags.sex_preferences
					),
					bodyType: getEscortTag(APIresponse.tags.body_types),
					spokenLanguages: getEscortTags(
						APIresponse.tags.spoken_languages
					),
					plays: getEscortPlayTags(APIresponse.tags)
				};
				return this.removeUndefined(model) as Escort;
			}

			case 'EscortTagType': {
				const APIresponse: ClubEscortTagType = res;

				// Get Escort Play Types
				function getPlayType(
					type: ClubEscortTagType['type']
				): EscortTagType['type'] {
					switch (type) {
						case 'anal_plays': {
							return 'analPlays';
						}
						case 'ejaculation_plays': {
							return 'ejaculationPlays';
						}
						case 'experience_plays': {
							return 'experiencePlays';
						}
						case 'masturbation_plays': {
							return 'masturbationPlays';
						}
						case 'oral_plays': {
							return 'oralPlays';
						}
						case 'other_plays': {
							return 'otherPlays';
						}
						case 'service_plays': {
							return 'servicePlays';
						}
						default: {
							return undefined;
						}
					}
				}

				const model: EscortTagType = {
					name: APIresponse.name,
					slug: APIresponse.slug,
					type: getPlayType(APIresponse.type)
				};
				return model;
			}

			case 'ListingStat': {
				const APIresponse: ClubListingStat = res;
				const model: ListingStat = {
					date: getISODate(APIresponse.date),
					weight: APIresponse.weight,
					baseWeight: APIresponse.base_weight,
					baselineWeight: APIresponse.baseline_weight,
					views: APIresponse.views_day,
					cumulativeViews: APIresponse.views
				};
				return model;
			}

			case 'Notification': {
				// Get Notification Types
				function getNotificationType(
					type: ClubNotification['type']
				): Notification['type'] {
					switch (type) {
						case 'EscortVerificationApproved': {
							return 'EscortVerificationApproved';
						}

						default: {
							return undefined;
						}
					}
				}

				// Get Notification Media
				function getNotificationMedia(
					media: ClubNotification['media']
				): Notification['media'] {
					if (media) {
						return ClubAPIResponseService.APItoModelConverter(
							media,
							'Media'
						) as Media;
					} else {
						return undefined;
					}
				}

				// Get Notification Read Status
				function getReadStatus(
					date: ClubNotification['read_at']
				): Notification['isRead'] {
					return !!date;
				}

				// Get Notification Acknowledged Status
				function getAcknowledgedStatus(
					date: ClubNotification['acknowledged_at']
				): Notification['isAcknowledged'] {
					return !!date;
				}

				const APIresponse: ClubNotification = res;
				const model: Notification = {
					UUID: APIresponse.id,
					type: getNotificationType(APIresponse.type),
					title: APIresponse.title,
					description: APIresponse.description,
					link: APIresponse.link,
					media: getNotificationMedia(APIresponse.media),
					createdAt: getISODate(APIresponse.created_at),
					isRead: getReadStatus(APIresponse.read_at),
					isAcknowledged: getAcknowledgedStatus(
						APIresponse.acknowledged_at
					)
				};
				return model;
			}
		}
	}

	/**
	 *  Convert from Models to ClubAPI
	 *  Set variable types for better consistency
	 * @param responseType
	 * @param res
	 */
	static ModelToAPIConverter(res: any, responseType: string) {
		switch (responseType) {
			case 'User': {
				const model: User = res;
				const APIresponse: ClubUser = {
					uuid: model.UUID,
					username: model.username,
					mobile: model.mobile,
					email: model.email,
					dob: model.dateOfBirth
						? model.dateOfBirth.toDateString()
						: undefined,
					street: model.addressStreet,
					suburb: model.addressSuburb,
					postcode: model.addressPostcode,
					state: model.addressState,
					is_active: model.isActive,
					media: model.avatar ? (model.avatar as string) : undefined
				};
				return this.removeUndefined(APIresponse) as ClubUser;
			}

			case 'Media': {
				const model: Media = res;
				const APIresponse: ClubMedia = {
					uuid: model.UUID,
					model_type: model.modelType,
					collection_name: model.collectionName,
					url: res.url
				};
				return this.removeUndefined(APIresponse) as ClubMedia;
			}

			case 'Escort': {
				const model: Escort = res;

				// Get any new Escort Media changes
				function getEscortMedia(
					avatar: Escort['avatar'],
					banner: Escort['banner']
				): ClubEscort['media'] {
					if (avatar || banner) {
						return {
							profile: avatar ? (avatar as string) : undefined,
							banner: banner ? (banner as string) : undefined
						};
					} else return undefined;
				}

				const APIresponse: ClubEscort = {
					profile_name: model.profileName,
					handle: model.handle,
					age: model.age,
					location: model.location,
					latitude: model.latitude.toString(),
					longitude: model.longitude.toString(),
					description: model.description,
					email: model.email,
					phone: model.phone,
					media: getEscortMedia(model.avatar, model.banner),
					availabilities: model.availabilities,
					tags: {
						ethnicities: model.tags.ethnicities,
						hair_colours: model.tags.hairColour,
						eye_colours: model.tags.eyeColour,
						sexes: model.tags.gender,
						sex_preferences: model.tags.sexualPreference,
						body_types: model.tags.bodyType,
						spoken_languages: model.tags.spokenLanguages,
						masturbation_plays: model.tags.masturbationPlays,
						oral_plays: model.tags.oralPlays,
						anal_plays: model.tags.analPlays,
						ejaculation_plays: model.tags.ejaculationPlays,
						service_plays: model.tags.servicePlays,
						experience_plays: model.tags.experiencePlays,
						other_plays: model.tags.otherPlays
					}
				};
				return this.removeUndefined(APIresponse) as ClubEscort;
			}

			case 'Listing': {
				const model: Listing = res;

				// Get Listing Type
				function getListingType(): ClubListing['type'] {
					return 'personals';
				}

				// Get Listing Payment Type
				function getListingPaymentType(
					type: Listing['paymentMethod']
				): ClubListing['payment'] {
					switch (type) {
						case 'shard': {
							return 'shard';
						}
						case 'shard': {
							return 'shard';
						}
					}
				}

				const APIresponse: ClubListing = {
					uuid: model.UUID,
					type: getListingType(),
					title: model.title,
					description: model.description,
					location: model.location,
					latitude: model.latitude.toString(),
					longitude: model.longitude.toString(),
					media: model.media,
					promotions: model.promotions,
					pricings: model.prices
						? (this.ModeltoAPI<ClubListingPrice>(
								model.prices,
								'ListingPrice',
								true
						  ) as ClubListingPrice[])
						: undefined,
					payment: getListingPaymentType(model.paymentMethod)
				};
				return this.removeUndefined(APIresponse) as ClubListing;
			}

			case 'ListingPrice': {
				const model: ListingPrice = res;

				// Get Listing Price Type
				function getListingPriceType(
					type: ListingPrice['type']
				): ClubListingPrice['type'] {
					switch (type) {
						case 'incall': {
							return 'incall';
						}
						case 'outcall': {
							return 'outcall';
						}
					}
				}

				// Get Listing Duration Type
				function getListingDurationType(
					duration: ListingPrice['duration']
				): ClubListingPrice['duration'] {
					switch (duration) {
						case 'oneHour': {
							return '1Hour';
						}
						case 'twoHours': {
							return '2Hour';
						}
						case 'threeHours': {
							return '3Hour';
						}
						case 'overnight': {
							return 'overnight';
						}
					}
				}

				const APIresponse: ClubListingPrice = {
					type: getListingPriceType(model.type),
					duration: getListingDurationType(model.duration),
					price: model.price.toString()
				};
				return APIresponse;
			}

			case 'Report': {
				const model: Report = res;

				// Get Reportable Type
				function getReportableType(
					type: Report['modelType']
				): ClubReport['reportable_type'] {
					switch (type) {
						case 'user': {
							return 'user';
						}
						case 'listing': {
							return 'listing';
						}
						case 'escort': {
							return 'escort';
						}
					}
				}

				const APIresponse: ClubReport = {
					reportable_id: model.UUID,
					reportable_type: getReportableType(model.modelType),
					title: model.title,
					description: model.description
				};
				return this.removeUndefined(APIresponse) as ClubReport;
			}
		}
	}

	private static removeUndefined(obj: object): object {
		Object.keys(obj).forEach((key) => {
			if (obj[key] === undefined) {
				delete obj[key];
			}
		});
		return obj;
	}
}
