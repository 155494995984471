import { Action } from '@ngrx/store';
import { Error } from 'src/app/shared/services';


export const ESCORT_ETHNICITY_SELECTOR_COMPONENT_GET_ETHNICITIES					= '[Escort Ethnicity Selector Component] Get Ethnicities';
export const CLUB_API_GET_ESCORT_TAG_ETHNICITIES           							= '[Club API] Get Escort Tag Ethnicities';
export const CLUB_API_GET_ESCORT_TAG_ETHNICITIES_FAIL               				= '[Club API] Get Escort Tag Ethnicities Fail';
export const CLUB_API_GET_ESCORT_TAG_ETHNICITIES_SUCCESS            				= '[Club API] Get Escort Tag Ethnicities Success';

export const ESCORT_HAIR_COLOUR_SELECTOR_COMPONENT_GET_HAIR_COLOURS					= '[Escort Hair Colour Selector Component] Get Hair Colours';
export const CLUB_API_GET_ESCORT_TAG_HAIR_COLOURS           						= '[Club API] Get Escort Tag Hair Colours';
export const CLUB_API_GET_ESCORT_TAG_HAIR_COLOURS_FAIL              				= '[Club API] Get Escort Tag Hair Colours Fail';
export const CLUB_API_GET_ESCORT_TAG_HAIR_COLOURS_SUCCESS           				= '[Club API] Get Escort Tag Hair Colours Success';

export const ESCORT_EYE_COLOUR_SELECTOR_COMPONENT_GET_EYE_COLOURS					= '[Escort Eye Colour Selector Component] Get Eye Colours';
export const CLUB_API_GET_ESCORT_TAG_EYE_COLOURS           							= '[Club API] Get Escort Tag Eye Colours';
export const CLUB_API_GET_ESCORT_TAG_EYE_COLOURS_FAIL              					= '[Club API] Get Escort Tag Eye Colours Fail';
export const CLUB_API_GET_ESCORT_TAG_EYE_COLOURS_SUCCESS           					= '[Club API] Get Escort Tag Eye Colours Success';

export const ESCORT_GENDER_SELECTOR_COMPONENT_GET_GENDERS							= '[Escort Gender Selector Component] Get Genders';
export const CLUB_API_GET_ESCORT_TAG_GENDERS           								= '[Club API] Get Escort Tag Genders';
export const CLUB_API_GET_ESCORT_TAG_GENDERS_FAIL              						= '[Club API] Get Escort Tag Genders Fail';
export const CLUB_API_GET_ESCORT_TAG_GENDERS_SUCCESS           						= '[Club API] Get Escort Tag Genders Success';

export const ESCORT_SEXUAL_PREFERENCE_SELECTOR_COMPONENT_GET_SEXUAL_PREFERENCES		= '[Escort Sexual Preference Selector Component] Get Sexual Preferences';
export const CLUB_API_GET_ESCORT_TAG_SEXUAL_PREFERENCES           					= '[Club API] Get Escort Tag Sexual Preferences';
export const CLUB_API_GET_ESCORT_TAG_SEXUAL_PREFERENCES_FAIL              			= '[Club API] Get Escort Tag Sexual Preferences Fail';
export const CLUB_API_GET_ESCORT_TAG_SEXUAL_PREFERENCES_SUCCESS           			= '[Club API] Get Escort Tag Sexual Preferences Success';

export const ESCORT_BODY_TYPE_SELECTOR_COMPONENT_GET_BODY_TYPES						= '[Escort Body Type Selector Component] Get Body Types';
export const CLUB_API_GET_ESCORT_TAG_BODY_TYPES           							= '[Club API] Get Escort Tag Body Types';
export const CLUB_API_GET_ESCORT_TAG_BODY_TYPES_FAIL              					= '[Club API] Get Escort Tag Body Types Fail';
export const CLUB_API_GET_ESCORT_TAG_BODY_TYPES_SUCCESS           					= '[Club API] Get Escort Tag Body Types Success';

export const ESCORT_SPOKEN_LANGUAGE_SELECTOR_COMPONENT_GET_SPOKEN_LANGUAGES			= '[Escort Spoken Language Selector Component] Get Spoken Languages';
export const CLUB_API_GET_ESCORT_TAG_SPOKEN_LANGUAGES           					= '[Club API] Get Escort Tag Spoken Languages';
export const CLUB_API_GET_ESCORT_TAG_SPOKEN_LANGUAGES_FAIL              			= '[Club API] Get Escort Tag Spoken Languages Fail';
export const CLUB_API_GET_ESCORT_TAG_SPOKEN_LANGUAGES_SUCCESS           			= '[Club API] Get Escort Tag Spoken Languages Success';

export const ESCORT_PLAY_SELECTOR_COMPONENT_GET_PLAYS								= '[Escort Play Selector Component] Get Plays';
export const CLUB_API_GET_ESCORT_TAG_PLAYS           								= '[Club API] Get Escort Tag Plays';
export const CLUB_API_GET_ESCORT_TAG_PLAYS_FAIL										= '[Club API] Get Escort Tag Plays Fail';
export const CLUB_API_GET_ESCORT_TAG_PLAYS_SUCCESS           						= '[Club API] Get Escort Tag Plays Success';

export class EscortEthnicitySelectorComponentGetEthnicities implements Action {
	readonly type = ESCORT_ETHNICITY_SELECTOR_COMPONENT_GET_ETHNICITIES;
	constructor() {}
}

export class ClubApiGetEscortTagEthnicities implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_ETHNICITIES;
	constructor() {}
}

export class ClubApiGetEscortTagEthnicitiesFail implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_ETHNICITIES_FAIL;
	constructor(public errorMessage: Error) {}
}

export class ClubApiGetEscortTagEthnicitiesSuccess implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_ETHNICITIES_SUCCESS;
	constructor(public payload: any) {}
}

export class EscortHairColourSelectorComponentGetHairColours implements Action {
	readonly type = ESCORT_HAIR_COLOUR_SELECTOR_COMPONENT_GET_HAIR_COLOURS;
	constructor() {}
}

export class ClubApiGetEscortTagHairColours implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_HAIR_COLOURS;
	constructor() {}
}

export class ClubApiGetEscortTagHairColoursFail implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_HAIR_COLOURS_FAIL;
	constructor(public errorMessage: Error) {}
}

export class ClubApiGetEscortTagHairColoursSuccess implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_HAIR_COLOURS_SUCCESS;
	constructor(public payload: any) {}
}

export class EscortEyeColourSelectorComponentGetEyeColours implements Action {
	readonly type = ESCORT_EYE_COLOUR_SELECTOR_COMPONENT_GET_EYE_COLOURS;
	constructor() {}
}

export class ClubApiGetEscortTagEyeColours implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_EYE_COLOURS;
	constructor() {}
}

export class ClubApiGetEscortTagEyeColoursFail implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_EYE_COLOURS_FAIL;
	constructor(public errorMessage: Error) {}
}

export class ClubApiGetEscortTagEyeColoursSuccess implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_EYE_COLOURS_SUCCESS;
	constructor(public payload: any) {}
}

export class EscortGenderSelectorComponentGetGenders implements Action {
	readonly type = ESCORT_GENDER_SELECTOR_COMPONENT_GET_GENDERS;
	constructor() {}
}

export class ClubApiGetEscortTagGenders implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_GENDERS;
	constructor() {}
}

export class ClubApiGetEscortTagGendersFail implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_GENDERS_FAIL;
	constructor(public errorMessage: Error) {}
}

export class ClubApiGetEscortTagGendersSuccess implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_GENDERS_SUCCESS;
	constructor(public payload: any) {}
}

export class EscortSexualPreferenceSelectorComponentGetSexualPreferences implements Action {
	readonly type = ESCORT_SEXUAL_PREFERENCE_SELECTOR_COMPONENT_GET_SEXUAL_PREFERENCES;
	constructor() {}
}

export class ClubApiGetEscortTagSexualPreferences implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_SEXUAL_PREFERENCES;
	constructor() {}
}

export class ClubApiGetEscortTagSexualPreferencesFail implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_SEXUAL_PREFERENCES_FAIL;
	constructor(public errorMessage: Error) {}
}

export class ClubApiGetEscortTagSexualPreferencesSuccess implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_SEXUAL_PREFERENCES_SUCCESS;
	constructor(public payload: any) {}
}

export class EscortBodyTypeSelectorComponentGetBodyTypes implements Action {
	readonly type = ESCORT_BODY_TYPE_SELECTOR_COMPONENT_GET_BODY_TYPES;
	constructor() {}
}

export class ClubApiGetEscortTagBodyTypes implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_BODY_TYPES;
	constructor() {}
}

export class ClubApiGetEscortTagBodyTypesFail implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_BODY_TYPES_FAIL;
	constructor(public errorMessage: Error) {}
}

export class ClubApiGetEscortTagBodyTypesSuccess implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_BODY_TYPES_SUCCESS;
	constructor(public payload: any) {}
}

export class EscortSpokenLanguageSelectorComponentGetSpokenLanguages implements Action {
	readonly type = ESCORT_SPOKEN_LANGUAGE_SELECTOR_COMPONENT_GET_SPOKEN_LANGUAGES;
	constructor() {}
}

export class ClubApiGetEscortTagSpokenLanguages implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_SPOKEN_LANGUAGES;
	constructor() {}
}

export class ClubApiGetEscortTagSpokenLanguagesFail implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_SPOKEN_LANGUAGES_FAIL;
	constructor(public errorMessage: Error) {}
}

export class ClubApiGetEscortTagSpokenLanguagesSuccess implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_SPOKEN_LANGUAGES_SUCCESS;
	constructor(public payload: any) {}
}

export class EscortPlaySelectorComponentGetPlays implements Action {
	readonly type = ESCORT_PLAY_SELECTOR_COMPONENT_GET_PLAYS;
	constructor() {}
}

export class ClubApiGetEscortTagPlays implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_PLAYS;
	constructor() {}
}

export class ClubApiGetEscortTagPlaysFail implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_PLAYS_FAIL;
	constructor(public errorMessage: Error) {}
}

export class ClubApiGetEscortTagPlaysSuccess implements Action {
	readonly type = CLUB_API_GET_ESCORT_TAG_PLAYS_SUCCESS;
	constructor(public payload: any) {}
}

// action types
export type EscortTagsAction =
	| EscortEthnicitySelectorComponentGetEthnicities
	| ClubApiGetEscortTagEthnicities
	| ClubApiGetEscortTagEthnicitiesFail
	| ClubApiGetEscortTagEthnicitiesSuccess
	| EscortHairColourSelectorComponentGetHairColours
	| ClubApiGetEscortTagHairColours
	| ClubApiGetEscortTagHairColoursFail
	| ClubApiGetEscortTagHairColoursSuccess
	| EscortEyeColourSelectorComponentGetEyeColours
	| ClubApiGetEscortTagEyeColours
	| ClubApiGetEscortTagEyeColoursFail
	| ClubApiGetEscortTagEyeColoursSuccess
	| EscortGenderSelectorComponentGetGenders
	| ClubApiGetEscortTagGenders
	| ClubApiGetEscortTagGendersFail
	| ClubApiGetEscortTagGendersSuccess
	| EscortSexualPreferenceSelectorComponentGetSexualPreferences
	| ClubApiGetEscortTagSexualPreferences
	| ClubApiGetEscortTagSexualPreferencesFail
	| ClubApiGetEscortTagSexualPreferencesSuccess
	| EscortBodyTypeSelectorComponentGetBodyTypes
	| ClubApiGetEscortTagBodyTypes
	| ClubApiGetEscortTagBodyTypesFail
	| ClubApiGetEscortTagBodyTypesSuccess
	| EscortSpokenLanguageSelectorComponentGetSpokenLanguages
	| ClubApiGetEscortTagSpokenLanguages
	| ClubApiGetEscortTagSpokenLanguagesFail
	| ClubApiGetEscortTagSpokenLanguagesSuccess
	| EscortPlaySelectorComponentGetPlays
	| ClubApiGetEscortTagPlays
	| ClubApiGetEscortTagPlaysFail
	| ClubApiGetEscortTagPlaysSuccess
	;
