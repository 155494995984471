import { createSelector } from '@ngrx/store';

import * as fromUI from '../reducers';
import * as fromUserEscortListingProfile from '../reducers/user-escort-listing-profile.reducer';

export const getUserEscortListingProfileUIState = createSelector(
	fromUI.getUIState,
	(state: fromUI.UIState) => state.userEscortListingProfile
);

export const getListingProfileStats = createSelector(
	getUserEscortListingProfileUIState,
	fromUserEscortListingProfile.getListingProfileStats
);

export const getListingProfileStatsDuration = createSelector(
	getUserEscortListingProfileUIState,
	fromUserEscortListingProfile.getListingProfileStatsDuration
);

export const getListingProfileViewChart = createSelector(
	getUserEscortListingProfileUIState,
	(state) => {
		const data = [
			{
				name: `${state.statsDuration} views`,
				series: state.stats.map((stats) => {
					return {
						...stats,
						extra: {
							filter: 'asdf'
						}
					};
				})
			}
		];
		return data;
	}
);

export const getListingViewChartLoading = createSelector(
	getUserEscortListingProfileUIState,
	fromUserEscortListingProfile.getListingViewChartLoading
);

export const getListingViewChartLoaded = createSelector(
	getUserEscortListingProfileUIState,
	fromUserEscortListingProfile.getListingViewChartLoaded
);

export const getListingViewChartError = createSelector(
	getUserEscortListingProfileUIState,
	fromUserEscortListingProfile.getListingViewChartError
);

export const getListingViewTotal = createSelector(
	getUserEscortListingProfileUIState,
	(state) => {
		const data = {
			rateOfChange:
				state.viewTotal &&
				state.viewTotal.rateOfChange &&
				Math.round(state.viewTotal.rateOfChange * 10000) / 100,
			data: [
				{
					name: '',
					value: state.viewTotal && state.viewTotal.count
				}
			]
		};
		return data;
	}
);

export const getListingViewCardLoading = createSelector(
	getUserEscortListingProfileUIState,
	fromUserEscortListingProfile.getListingViewCardLoading
);

export const getListingViewCardLoaded = createSelector(
	getUserEscortListingProfileUIState,
	fromUserEscortListingProfile.getListingViewCardLoaded
);

export const getListingViewCardError = createSelector(
	getUserEscortListingProfileUIState,
	fromUserEscortListingProfile.getListingViewCardError
);

export const getListingHealth = createSelector(
	getUserEscortListingProfileUIState,
	(state) => {
		const data = [
			{
				name: '',
				value: state.health * 100
			}
		];
		return data;
	}
);
