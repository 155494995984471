import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { catchError, map, tap } from 'rxjs/operators';
import {
	LogService,
	ErrorService,
	CustomHttpService
} from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { ClubAPIResponse, ClubAPIResponseService } from '..';
import { Escort } from '../../models';
import { ClubAPIAuthResponse } from 'src/app/core/services';

export interface EscortVerificationContext {
	media: string;
}

interface APIRequestModel {
	media: string;
}

@Injectable({
	providedIn: 'root'
})
export class AuthEscortService {
	// API environment variables
	private ENV = `${environment.apiUrl}/${environment.apiVersion}`;
	// API Request information
	private API_END_POINT = 'user/escort'; // * Modify to change API path

	private URL = `${this.ENV}/${this.API_END_POINT}`;
	private SERVICE_NAME = `API Auth (${this.API_END_POINT})`;

	constructor(
		private http: CustomHttpService,
		private logService: LogService,
		private errorService: ErrorService
	) {}

	private log(message: string) {
		this.logService.add(`${this.SERVICE_NAME}: ${message}`, 'success');
	}

	/**
	 * Escort registration
	 */
	createEscort(escort: Escort): Observable<Escort> {
		const req = ClubAPIResponseService.ModelToAPIConverter(
			escort,
			'Escort'
		);
		return this.http.post<ClubAPIResponse<Escort>>(this.URL, req).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<Escort>(
					res,
					'Escort',
					false
				)
			),
			tap((_) => this.log(`Create Escort Successfully.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'createEscort'
				)
			)
		);
	}

	/**
	 * Update User Escort details
	 */
	updateEscort(escort: Escort): Observable<Escort> {
		console.log(escort);
		const req = ClubAPIResponseService.ModelToAPIConverter(
			escort,
			'Escort'
		);
		return this.http.put<ClubAPIResponse<Escort>>(this.URL, req).pipe(
			map((res) =>
				ClubAPIResponseService.validateResponse<Escort>(
					res,
					'Escort',
					false
				)
			),
			tap((_) => this.log(`Update Escort Successfully.`)),
			catchError((res: HttpErrorResponse) =>
				this.errorService.handleAPIHttpError(
					res,
					this.SERVICE_NAME,
					'updateEscort'
				)
			)
		);
	}

	/**
	 * Escort verification
	 */
	verifyEscort(context: EscortVerificationContext): Observable<any> {
		const req = this.requestConverter(context);
		const url = `${this.URL}/verification`;
		return this.http
			.post<HttpResponse<ClubAPIAuthResponse>>(url, req)
			.pipe(
				tap((_) => this.log(`Verify Escort Request Sent Successfully.`)),
				catchError((res: HttpErrorResponse) =>
					this.errorService.handleAPIHttpError(
						res,
						this.SERVICE_NAME,
						'verifyEscort'
					)
				)
			);
	}

	/**
	 * Convert Context to API request
	 */
	private requestConverter(
		context: EscortVerificationContext
	): APIRequestModel {
		const req: APIRequestModel = {
			media: context.media
		};
		return req;
	}
}
